export const processItems = async (
  selectedItems,
  setSelectedItems,
  lineItems,
  handleSaveField,
  notify,
  action,
  additionalParams = {}
) => {
  let flatItems = Object.values(lineItems).flat();
  const hasSelectedItems = Object.values(selectedItems).some(
    (item) => item.selected && item.quantity > 0
  );

  if (!hasSelectedItems) {
    notify('Error!', 'No items selected or all quantities are 0.', 'error');
    return;
  }

  const processedItems = new Set();
  let updatedFlatItems = [...flatItems];
  let updatedSelectedItems = { ...selectedItems }; // Create a copy of selectedItems

  Object.keys(selectedItems).forEach((key) => {
    let selectedItem = selectedItems[key];
    if (selectedItem && selectedItem.selected && !processedItems.has(key)) {
      switch (action) {
        case 'hold': {
          const { holdReason, optionalMessage } = additionalParams;
          updatedFlatItems = handleHold(
            updatedFlatItems,
            selectedItem,
            key,
            holdReason,
            optionalMessage
          );
          break;
        }
        case 'release': {
          updatedFlatItems = handleRelease(updatedFlatItems, key);
          break;
        }
        case 'ship': {
          updatedFlatItems = handleShip(updatedFlatItems, key);
          break;
        }
        case 'split': {
          updatedFlatItems = handleSplit(updatedFlatItems, selectedItem, key);
          break;
        }
        case 'changeStatus': {
          updatedFlatItems = handleChangeStatus(
            updatedFlatItems,
            key,
            additionalParams.newStatus
          );
          break;
        }
        case 'merge': {
          const handleMergeResponse = handleMerge(
            updatedFlatItems,
            selectedItem,
            key,
            additionalParams.mergeItemId
          );

          const mergingItem = handleMergeResponse.mergeItem
            ? handleMergeResponse.mergeItem
            : null;
          selectedItem = {
            ...selectedItem,
            mergingItem: mergingItem,
            mergingId: additionalParams.mergeItemId,
          };

          updatedSelectedItems[key] = selectedItem; // Update the selected item in the copy

          updatedFlatItems = handleMergeResponse.items;
          break;
        }
        case 'update': {
          updatedFlatItems = handleUpdate(updatedFlatItems, selectedItem, key);
          break;
        }
        default: {
          console.warn('Unknown action:', action);
        }
      }
      processedItems.add(key);
    }
  });

  try {
    console.log('[INFO] - [ upodatedSelectedItems ]', updatedSelectedItems);
    await handleSaveField(
      'line_items',
      updatedFlatItems,
      updatedSelectedItems,
      action
    );
    notify(
      `Successfully processed items!`,
      `Items have been ${action === 'changeStatus' ? 'updated' : action}.`,
      'success'
    );
    setSelectedItems(updatedSelectedItems); // Update the state with the modified selectedItems
    setSelectedItems({}); // Clear selections after update if needed
  } catch (error) {
    console.error(`Error processing items (${action}):`, error);
    notify(`Error processing items!`, `Unable to ${action} items.`, 'error');
  }
};

const handleHold = (
  flatItems,
  selectedItem,
  key,
  holdReason,
  optionalMessage
) => {
  const item = flatItems.find((i) => i._id === key);
  const newQuantity = selectedItem.quantity;

  if (!item) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  if (newQuantity === item.fulfillable_quantity) {
    return flatItems.map((i) => {
      if (i._id === item._id) {
        return {
          ...i,
          fulfillable_quantity: 0,
          internal_status: 'On Hold',
          hold_data: {
            hold_reason: {
              reason: holdReason.name,
              message: optionalMessage || null,
            },
            quantity: newQuantity,
            fulfillment_order: '',
          },
          fulfillment_order: {
            prev: {
              id: item.fulfillment_order.id,
              item_id: item.fulfillment_order.item_id,
            },
            id: null,
            item_id: null,
          },
        };
      }
      return i;
    });
  } else {
    const onHoldItem = {
      ...item,
      fulfillable_quantity: 0,
      internal_status: 'On Hold',
      hold_data: {
        hold_reason: {
          reason: holdReason.name,
          message: optionalMessage || '',
        },
        quantity: newQuantity,
        fulfillment_order: '',
      },
      originalId: item._id,
      fulfillment_order: {
        prev: {
          id: item.fulfillment_order.id,
          item_id: item.fulfillment_order.item_id,
        },
        id: null,
        item_id: null,
      },
    };
    delete onHoldItem._id;

    const updatedOriginalItem = {
      ...item,
      fulfillable_quantity: item.fulfillable_quantity - newQuantity,
    };

    return flatItems
      .filter((i) => i._id !== item._id)
      .concat(updatedOriginalItem, onHoldItem);
  }
};

const handleRelease = (flatItems, key) => {
  const item = flatItems.find((i) => i._id === key);

  if (!item) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  let releaseQuantity = 0;
  let hasDuplicate = flatItems.filter((i) => i.id === item.id).length > 1;

  let updatedFlatItems = flatItems.filter((i) => {
    if (i._id === item._id && i.internal_status === 'On Hold') {
      releaseQuantity = i.hold_data
        ? i.hold_data.quantity
        : i.fulfillable_quantity;
      return !hasDuplicate;
    }
    return true;
  });

  return updatedFlatItems.map((i) => {
    if (i.id === item.id && i._id !== item._id) {
      return {
        ...i,
        fulfillable_quantity: i.fulfillable_quantity + releaseQuantity,
      };
    }
    if (i._id === item._id) {
      delete i.hold_data;
      return {
        ...i,
        internal_status: 'Received',
        fulfillable_quantity: i.fulfillable_quantity + releaseQuantity,
      };
    }
    return i;
  });
};

const handleShip = (flatItems, key) => {
  const item = flatItems.find((i) => i._id === key);

  if (!item) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  const shippedItem = {
    ...item,
    internal_status: 'Shipped',
  };

  return flatItems.map((i) => (i._id === item._id ? shippedItem : i));
};

const handleSplit = (flatItems, selectedItem, key) => {
  const item = flatItems.find((i) => i._id === key);

  if (!item) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  const splitQuantity = selectedItem.quantity;
  const newSplitItem = {
    ...item,
    fulfillable_quantity: splitQuantity,
  };

  delete newSplitItem._id;

  const updatedOriginalItem = {
    ...item,
    fulfillable_quantity: item.fulfillable_quantity - splitQuantity,
  };

  let newItems = flatItems.filter((i) => i._id !== item._id);

  if (updatedOriginalItem.fulfillable_quantity > 0) {
    newItems = newItems.concat(updatedOriginalItem);
  }

  newItems = newItems.concat(newSplitItem);

  console.log('[INFO] - [ PROCESSED ITEMS ]', newItems);
  return newItems;
};

// Original merge code before GPT 07/09/2024
// const handleMerge = (flatItems, selectedItem, key, mergeItemId) => {
//   const item = flatItems.find((i) => i._id === key);
//   const mergeItem = flatItems.find((i) => i._id === mergeItemId);

//   if (!item || !mergeItem) {
//     throw new Error('Item or Merge item not found.');
//   }

//   const updatedMergeItem = {
//     ...mergeItem,
//     fulfillable_quantity:
//       mergeItem.fulfillable_quantity + selectedItem.quantity,
//   };

//   console.log('MERGING TO ITEM: ', mergeItem);

//   return flatItems
//     .filter((i) => i._id !== item._id && i._id !== mergeItem._id)
//     .concat(updatedMergeItem);
// };

const handleMerge = (flatItems, selectedItem, key, mergeItemId) => {
  if (!flatItems) {
    console.error('flatItems is undefined');
    return [];
  }

  console.log('[INFO] - [ FLAT ITEMS ]', JSON.stringify(flatItems, null, 2));
  console.log(
    '[INFO] - [ selectedItem ]',
    JSON.stringify(selectedItem, null, 2)
  );
  console.log('[INFO] - [ key ]', JSON.stringify(key, null, 2));
  console.log('[INFO] - [ mergeItemId ]', JSON.stringify(mergeItemId, null, 2));

  const item = flatItems.find((i) => i._id === key);

  if (!item) {
    console.error('Item not found.');
    return flatItems;
  }

  const itemsWithFulfillmentOrderId = flatItems.filter(
    (i) => i.fulfillment_order.id === mergeItemId
  );

  const mergeItem = itemsWithFulfillmentOrderId.find((i) => i.id === item.id);

  if (!mergeItem) {
    // If mergeItem is not found, just return the original flatItems array
    console.log('Merge item not found. Returning original flatItems.');
    return flatItems;
  }

  const updatedMergeItem = {
    ...mergeItem,
    fulfillable_quantity:
      mergeItem.fulfillable_quantity + selectedItem.quantity,
  };

  let finalItems;
  if (selectedItem.quantity === item.fulfillable_quantity) {
    finalItems = flatItems
      .filter((i) => i._id !== item._id && i._id !== mergeItem._id)
      .concat(updatedMergeItem);
  } else {
    const updatedSplitItem = {
      ...item,
      fulfillable_quantity: item.fulfillable_quantity - selectedItem.quantity,
    };
    finalItems = flatItems
      .filter((i) => i._id !== item._id && i._id !== mergeItem._id)
      .concat(updatedMergeItem, updatedSplitItem);
  }

  console.log('[INFO] - [ FINALITEMS ]', JSON.stringify(finalItems, null, 2));
  return { items: finalItems, mergeItem: mergeItem._id };
};

const handleChangeStatus = (flatItems, key, newStatus) => {
  const item = flatItems.find((i) => i._id === key);

  if (!item) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  const updatedItem = {
    ...item,
    internal_status: newStatus.name,
  };

  return flatItems.map((i) => (i._id === item._id ? updatedItem : i));
};

const handleUpdate = (flatItems, selectedItem, key) => {
  if (!selectedItem?.edits) {
    return flatItems;
  }

  const index = flatItems.findIndex((flatItem) => flatItem._id === key);
  if (index === -1) {
    console.error('Item not found in flatItems.');
    return flatItems;
  }

  const editedItem = { ...flatItems[index] };

  for (const [key, value] of Object.entries(selectedItem.edits)) {
    if (Array.isArray(value)) {
      editedItem[key] = [...(editedItem[key] || []), ...value];
    } else {
      editedItem[key] = value;
    }
  }

  const updatedItems = [...flatItems];
  updatedItems[index] = editedItem;

  return updatedItems;
};

// export const processItems = async (selectedItems, setSelectedItems, lineItems, handleSaveField, notify, action, additionalParams = {}) => {
//   const [itemsSelected, setItemsSelected] = useState(selectedItems);

//   let flatItems = Object.values(lineItems).flat();
//   const hasSelectedItems = Object.values(selectedItems).some(item => item.selected && item.quantity > 0);

//   if (!hasSelectedItems) {
//     notify("Error!", "No items selected or all quantities are 0.", "error");
//     return;
//   }

//   useEffect(() => {
//     setItemsSelected(selectedItems);
//   }, selectedItems)

//   const updates = [];
//   Object.keys(selectedItems).forEach(key => {
//     const selectedItem = selectedItems[key];
//     if (selectedItem && selectedItem.selected) {
//       switch(action) {
//         case "hold":
//           const { holdReason, optionalMessage } = additionalParams;
//           flatItems = handleHold(flatItems, selectedItem, key, holdReason, optionalMessage);
//           break;
//         case "release":
//           flatItems = handleRelease(flatItems, key);
//           break;
//         case "ship":
//           flatItems = handleShip(flatItems, key);
//           break;
//         case "split":
//           flatItems = handleSplit(flatItems, selectedItem, key);
//           break;
//         case "changeStatus":
//           flatItems = handleChangeStatus(flatItems, key, additionalParams.newStatus);
//           break;
//         case "merge":
//           flatItems = handleMerge(flatItems, selectedItem, key, additionalParams.mergeItemId);
//           break;
//         case "update":
//           flatItems = handleUpdate(flatItems, selectedItem, key);
//           break;
//         default:
//           console.warn("Unknown action:", action);
//       }

//       updates.push(handleSaveField('line_items', flatItems, selectedItems));
//     }
//   });

//   try {
//     await Promise.all(updates);
//     notify(`Successfully processed items!`, `Items have been ${action === 'changeStatus' ? 'updated' : action}.`, "success");
//     setSelectedItems({}); // Clear selections after update
//   } catch (error) {
//     console.error(`Error processing items (${action}):`, error);
//     notify(`Error processing items!`, `Unable to ${action} items.`, "error");
//   }
// };
