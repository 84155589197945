import IntegrationItem from './IntegrationItem';
import Card from '../../../../shared/components/UIElements/Card';

const IntegrationList = ({ integrations }) => {
  const groupedIntegrations = integrations.reduce((acc, integration) => {
    const { integrationType } = integration;
    if (!acc[integrationType]) {
      acc[integrationType] = [];
    }
    acc[integrationType].push(integration);
    return acc;
  }, {});

  // After grouping, sort each group by active status
  Object.keys(groupedIntegrations).forEach((type) => {
    groupedIntegrations[type].sort((a, b) => b.active - a.active); // Active integrations first
  });

  // Sort the categories with 'storefront' first
  const sortedCategories = Object.keys(groupedIntegrations).sort((a, b) => {
    if (a === 'storefront') return -1;
    if (b === 'storefront') return 1;
    return a.localeCompare(b);
  });

  return (
    <div className="flex flex-col gap-4 w-full">
      {sortedCategories.map((category, idx) => (
        <Card key={idx} className="w-full">
          <h2 className="text-xl font-bold mb-4">
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </h2>
          <div className="grid grid-cols-2 gap-2 sm:mx-0 sm:rounded-2xl md:grid-cols-3">
            {groupedIntegrations[category].map((integration, idx) => (
              <IntegrationItem key={idx} integration={integration} />
            ))}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default IntegrationList;
