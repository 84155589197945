import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const PaymentCardTaxes = ({ taxes, taxLines }) => {
  const [taxDetailsVisible, setTaxDetailsVisible] = useState(false);

  return (
    <>
      {taxes !== 0 && (
        <div
          className={`px-2 py-2 grid grid-cols-6 sm:gap-4 sm:px-0 transition-all`}
        >
          <dt className="text-sm text-gray-900">Taxes</dt>
          <dd
            className={`text-sm leading-6 text-gray-700 mt-0 ${taxDetailsVisible ? 'col-span-5' : 'col-span-4'}`}
          >
            <div
              className="flex items-center hover:cursor-pointer max-w-fit"
              onClick={() => setTaxDetailsVisible(!taxDetailsVisible)}
            >
              Tax details
              {!taxDetailsVisible ? (
                <ChevronDownIcon className="w-4 h-auto ml-1 hover:underline" />
              ) : (
                <ChevronUpIcon className="w-4 h-auto ml-1 hover:underline" />
              )}
            </div>
            <div className={`${taxDetailsVisible ? 'block' : 'hidden'}`}>
              <dl className="w-full">
                {taxLines.map((tax, index) => (
                  <div
                    className="px-2 py-2 grid grid-cols-3 sm:gap-4 sm:px-0"
                    key={index}
                  >
                    <dd className="text-sm text-gray-700 mt-0 col-span-2">
                      {tax.title}
                    </dd>
                    <dd className="text-sm text-gray-700 mt-0 flex justify-end">
                      ${parseFloat(tax.price).toFixed(2)}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </dd>
          <dd
            className={`text-sm leading-6 text-gray-700 mt-0 flex justify-end ${!taxDetailsVisible ? 'block' : 'hidden'}`}
          >
            ${taxes.toFixed(2)}
          </dd>
        </div>
      )}
    </>
  );
};

export default PaymentCardTaxes;
