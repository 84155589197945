import { PlusCircleIcon } from '@heroicons/react/20/solid';

const AddItem = () => {
  return (
    <div
      className={`flex flex-col text-[.8125rem] border-t border-dashed border-gray-900/20`}
    >
      <div className="flex p-4 gap-x-1 text-[.8125rem] text-indigo-500 items-center cursor-pointer underline-offset-1 hover:underline">
        <PlusCircleIcon className="w-auto h-5" />
        <p>Add another item</p>
      </div>
    </div>
  );
};

export default AddItem;
