import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import DialogContainer from './DialogContainer';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState({
    isVisible: false,
    key: 0, // Add a key to track dialog invocations
    title: '',
    actions: null,
    content: null,
  });

  const dialogRef = useRef(dialog);
  dialogRef.current = dialog; // Always keep the ref current with the latest dialog state

  const prompt = useCallback((title, content, actions) => {
    // Update the key along with the other dialog properties to ensure a state change
    setDialog((prevDialog) => ({
      isVisible: true,
      key: prevDialog.key + 1, // Increment key to force re-render
      title,
      actions,
      content,
    }));
  }, []);

  return (
    <DialogContext.Provider value={{ prompt }}>
      {children}
      {dialog.isVisible && (
        <DialogContainer
          key={dialog.key} // Use key here to force re-render
          title={dialog.title}
          onClose={() => {
            setDialog((prevDialog) => ({
              ...prevDialog,
              isVisible: false,
            }));

            // Use dialogRef to access the latest actions
            if (dialogRef.current.actions && dialogRef.current.actions.close) {
              dialogRef.current.actions.close();
            }
          }}
          actions={dialog.actions}
        >
          {dialog.content}
        </DialogContainer>
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
