import CopyIcon from '../../../../../../shared/components/UIElements/CopyIcon';
import EditIcon from '../../../../../../shared/components/UIElements/EditIcon';

import useEditableField from '../../../../../../shared/hooks/dialog-form-hook'; // Import the hook correctly
import EditContact from '../Dialogs/EditContact'; // Ensure the EditContact component is correctly imported

const CustomerContact = ({ contactEmail, handleSaveField }) => {
  const { editField, handleEditField, currentValue } = useEditableField(
    EditContact,
    { fieldName: 'email', value: contactEmail, dialogTitle: 'Contact' },
    handleSaveField
  );

  return (
    <div className="mt-4 w-full text-[.8125rem]">
      <div className="flex items-center space-between">
        <div className="flex-1 text-[.8125rem] font-semibold text-gray-900 mr-2">
          Contact Information
        </div>
        <div>
          <EditIcon onClick={handleEditField} active={editField} />
        </div>
      </div>
      <div className="flex items-center space-between mt-2">
        <div className="flex-1 mr-2">
          <p className="break-all">{currentValue}</p>
        </div>
        <div className="relative top-0">
          <CopyIcon data={currentValue} />
        </div>
      </div>
    </div>
  );
};

export default CustomerContact;
