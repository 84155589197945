import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogHeader from './DialogHeader';

const DialogContainer = ({ title, children, onClose, actions }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false); // Close the dialog
    if (onClose) {
      onClose(); // Call additional provided onClose logic
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg text-[.8125rem]">
                <DialogHeader title={title} handleClose={handleClose} />
                <div className="px-4 pb-4 sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center sm:mt-0 sm:text-left flex-1">
                      <DialogContent>{children}</DialogContent>
                    </div>
                  </div>
                </div>
                {actions && (
                  <div className="border-t border-gray-200">
                    <div className="pb-4 px-4">
                      <DialogActions setOpen={handleClose} actions={actions} />
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DialogContainer;
