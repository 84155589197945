import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiRequest } from '../BaseApi'; // Adjust the path to your ApiRequest utility

const ShopifyCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shop = params.get('shop');
    const code = params.get('code');
    const hmac = params.get('hmac');
    const host = params.get('host');
    const timestamp = params.get('timestamp');

    if (shop && code) {
      const handleOAuthCallback = async () => {
        try {
          const response = await ApiRequest({
            method: 'get',
            endpoint: '/shopify/callback', // API to hit your backend
            params: { shop, code, hmac, host, timestamp },
            requireAuth: false, // No auth required for the callback
          });

          if (response.success) {
            navigate(`/dashboard?shop=${shop}`); // Navigate to dashboard after successful OAuth
          } else {
            console.error('Shopify OAuth callback failed');
            navigate('/404'); // Handle error case
          }
        } catch (error) {
          console.error('Error during Shopify OAuth callback:', error);
          navigate('/404'); // Handle error case
        }
      };

      handleOAuthCallback();
    } else {
      navigate('/404'); // Handle cases where query parameters are missing
    }
  }, [navigate]);

  return (
    <div className="flex justify-center items-center h-screen">
      <p>Finalizing Shopify installation...</p>
    </div>
  );
};

export default ShopifyCallback;
