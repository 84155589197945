import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

const Flyout = ({ content, actions, children }) => {
  return (
    <Popover className="relative">
      <div className="flex">
        <Popover.Button className="inline-flex items-center gap-x-1 text-[.8125rem] font-semibold leading-5 text-gray-900">
          <span>{content}</span>
          {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
        </Popover.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1" // Adjust for your specific needs
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1" // Adjust for your specific needs
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-[-150%] transform -translate-x-1/2 translate-y-[-100%]">
          <div className="w-screen max-w-40 flex-auto overflow-hidden rounded-lg bg-white text-[.8125rem] leading-5 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-2">
              {actions.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex gap-x-6 rounded-lg p-2 text-left hover:bg-gray-100"
                >
                  {item.icon && (
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div>
                    {item.name && (
                      <span
                        onClick={item.action}
                        className="font-semibold text-gray-900 cursor-pointer"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </span>
                    )}
                    {item.description && (
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Flyout;
