import { useEffect, useState } from 'react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Combobox } from '@headlessui/react';

import Input from '../../../../../../../shared/components/UIElements/Inputs/Input';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const defaultOptions = [
  {
    name: 'Default name',
    value: 'Default value',
  },
];

const VariantOption = ({
  options,
  idx,
  name,
  values,
  onOptionNameChange,
  onOptionValueChange,
  onOptionValueDelete,
  onOptionDelete,
}) => {
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(name);
  // Part of VariantOption component
  const [inputValue, setInputValue] = useState(''); // For new option value input
  // const [isCommitted, setIsCommitted] = useState(false); // To track if the new value is committed

  useEffect(() => {
    setSelectedOption(name);
  }, [name]);

  const handleNewValueChange = (e) => {
    setInputValue(e.target.value); // Update input field in real-time
  };

  const commitNewValue = () => {
    if (inputValue.trim() !== '') {
      const valueIndex = values.length; // Index for new value
      onOptionValueChange(idx, valueIndex, inputValue);
      setInputValue(''); // Clear the input after committing
    }
  };

  // For committing the value when user presses Enter or blurs the input field
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action of the enter key
      e.stopPropagation(); // Stop the event from propagating to other elements
      commitNewValue();
    }
  };
  const handleBlur = () => {
    commitNewValue();
  };

  const filteredOptions =
    query === ''
      ? options
      : options.filter((optionItem) => {
          return optionItem.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="flex flex-1">
      <div className="w-full">
        <div className="flex gap-6 items-center">
          <div className="w-4 pt-8">
            <EllipsisVerticalIcon className="w-4 h-4" />
          </div>
          <div className="w-full">
            <Combobox
              as="div"
              value={selectedOption}
              onChange={(option) => {
                setSelectedOption(option.name); // Ensure this matches the expected display value format
                onOptionNameChange(idx, option.name || ''); // Update the parent state
              }}
            >
              <Combobox.Label className="block text-[.8125rem] font-normal leading-5 text-gray-900">
                Option name
              </Combobox.Label>
              <div className="relative mt-2">
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  onChange={(event) => {
                    setQuery(event.target.value);
                    onOptionNameChange(idx, event.target.value);
                  }}
                  displayValue={() => selectedOption}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {filteredOptions.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[.8125rem]">
                    {filteredOptions.map((opt, idx) => (
                      <Combobox.Option
                        key={idx}
                        value={opt}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate',
                                selected && 'font-semibold'
                              )}
                            >
                              {opt.name}
                            </span>

                            {selected && (
                              <span
                                className={classNames(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  active ? 'text-white' : 'text-indigo-600'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </div>
          <div className="w-4 pt-8">
            <TrashIcon
              className="w-5 h-5 cursor-pointer text-gray-400 hover:text-gray-900"
              onClick={() => onOptionDelete(idx)}
            />
          </div>
        </div>
        <div>
          <div className="mt-4 flex gap-6 items-center">
            <div className="w-4"></div>
            <div className="w-full">
              <label className="block text-[.8125rem] font-normal leading-5 text-gray-900">
                Option values
              </label>
            </div>
            <div className="w-4"></div>
          </div>
          {values?.map((value, index) => (
            <div key={index} className="flex gap-6 items-center">
              <div className="w-4"></div>
              <div className="w-full">
                <div key={index} className="mt-2">
                  {' '}
                  {/* Use a more specific key if possible */}
                  <Input
                    type="text"
                    name={`option${idx}-value${index}`}
                    id={`option${idx}-value${index}`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                    placeholder="Enter a value"
                    value={value.toString()} // Ensure the value is a string
                    onChange={(e) => {
                      // Call parent handler for option value change
                      onOptionValueChange(idx, index, e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-4 pt-4">
                <TrashIcon
                  className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-900"
                  onClick={() => onOptionValueDelete(idx, index)}
                />
              </div>
            </div>
          ))}
          <div className="flex gap-6 items-center">
            <div className="w-4"></div>
            <div className="w-full">
              <div className="mt-2">
                <Input
                  type="text"
                  name={`option${idx}-new-value`}
                  id={`option${idx}-new-value`}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  placeholder={
                    values.length > 0 ? 'Add another option' : 'Add an option'
                  }
                  value={inputValue}
                  onChange={handleNewValueChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="w-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantOption;
