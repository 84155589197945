const RadioButton = ({
  id,
  name,
  checked,
  onChange,
  value,
  label,
  labelsr = false,
}) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        checked={checked}
        onChange={handleChange}
        value={value}
        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
      <label
        htmlFor={id}
        className={`ml-3 block text-[.8125rem] font-medium leading-5 text-gray-900 ${labelsr && 'sr-only'}`}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
