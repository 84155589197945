import Card from '../../../../../shared/components/UIElements/Card';
import VariantEditImage from '../VariantImage';

const VariantProductImage = ({
  fields,
  product,
  setProduct,
  notify,
  onImageChange,
}) => {
  return (
    <Card>
      <div className="space-y-6 px-4 py-6 sm:p-6">
        <div>
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Product image
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Choose a product photo.
          </p>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3">
            <div className="mt-2 flex items-center">
              <VariantEditImage
                fields={fields}
                product={product}
                setProduct={setProduct}
                notify={notify}
                onImageChange={onImageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default VariantProductImage;
