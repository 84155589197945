// React Components
import React, { useCallback } from 'react';

// Shared Components
import ImageUpload from '../../../../../../shared/components/UIElements/ImageUpload';
import Card from '../../../../../../shared/components/UIElements/Card';

// Page Components
import ProductImageUploader from './ProductImageUploader';

// API Components
import { onCloudDeleteImage } from '../../../../../../utils/Api/Content/Delete';

const ProductImages = ({ fields, handleChange, notify }) => {
  // Handle image upload
  const handleImageUploadSuccess = useCallback(
    (uploadedImageData) => {
      const images = fields.images || [];
      let updated = false;

      console.log('uploadedImageData', uploadedImageData);

      // Check if uploadedImageData is an array and handle each image individually
      const newImages = Array.isArray(uploadedImageData)
        ? uploadedImageData
        : [uploadedImageData];

      const updatedImages = [...images];

      newImages.forEach((data) => {
        const existingImageIndex = updatedImages.findIndex(
          (image) => image._id === data._id
        );

        if (existingImageIndex !== -1) {
          updatedImages[existingImageIndex] = {
            ...updatedImages[existingImageIndex],
            cloudId: data.cloudId,
            cloudUrl: data.cloudUrl,
          };
          updated = true;
        } else if (data.isNew) {
          updatedImages.push({
            ...data,
            tempId: data._id,
            alt: '',
            src: '',
            id: '',
            position: updatedImages.length + 1,
          });
        }
      });

      console.log('Updated Images Array: ', updatedImages);

      handleChange({
        target: {
          name: 'images',
          value: updatedImages,
        },
      });
    },
    [fields.images, handleChange]
  );

  // Handle image delete
  const onDeleteImage = async (_id, cloudId) => {
    const response = await onCloudDeleteImage(cloudId);
    if (response.success) {
      const updatedImages = fields.images.filter(
        (image) => image.cloudId !== cloudId
      );

      handleChange({
        target: {
          name: 'images',
          value: updatedImages,
        },
      });

      notify('Success!', response.data.message, 'success');
    } else {
      console.error(`Failed to delete image ${cloudId} from Cloudinary.`);
      notify('Error!', response.data.message, 'error');
    }
  };

  return (
    <Card className="mt-4">
      <div>
        <label
          htmlFor="cover-photo"
          className="block text-base font-bold leading-5 text-gray-900"
        >
          Media
        </label>
        <div className="mt-2">
          {fields?.images?.length > 0 ? (
            <ProductImageUploader
              images={fields.images}
              onUploadSuccess={handleImageUploadSuccess}
              deleteImage={onDeleteImage}
              onUpdateImageOrder={(newOrder) => {
                handleChange({
                  target: {
                    name: 'images',
                    value: newOrder,
                  },
                });
              }}
            />
          ) : (
            <ImageUpload
              onUploadSuccess={handleImageUploadSuccess}
              images={fields?.images}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProductImages;
