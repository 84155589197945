import { Link, useLocation } from 'react-router-dom';

const secondaryNavigation = [
  { name: 'Settings', href: '/settings', current: true },
  { name: 'Account', href: '/settings/account', current: false },
  { name: 'Notifications', href: '#', current: false },
  { name: 'App Activity', href: '#', current: false },
  { name: 'Integrations', href: '/settings/integrations', current: false },
];

const SettingsNavigation = () => {
  const location = useLocation();

  // Function to determine if the item is the current one based on the current path
  const isCurrent = (itemHref) => {
    if (itemHref === '/settings') {
      return location.pathname === itemHref; // Only match exactly '/settings'
    }
    return location.pathname.startsWith(itemHref);
  };

  return (
    <header className="border-l border-b border-white/5 bg-gray-900">
      {/* Secondary navigation */}
      <nav className="flex overflow-x-auto py-4">
        <ul className="flex min-w-full flex-none gap-x-6 px-4 text-[.8125rem] font-semibold leading-5 text-gray-400 sm:px-6 lg:px-8">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                className={`hover:text-white ${isCurrent(item.href) ? 'text-white' : ''}`}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default SettingsNavigation;
