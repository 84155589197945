import { Fragment, useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

import Button from '../../../../shared/components/UIElements/Button';

import { usePopoutNotification } from '../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { updateIntegration } from '../../../../utils/Api/Integrations/Update';
import { FormatDateIntl } from '../../../../shared/utils/FormatDateTime';

import IntegrationOptions from './IntegrationOptions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const IntegrationDetails = ({ integration, setItem }) => {
  const [enabled, setEnabled] = useState(false);
  const { notify } = usePopoutNotification();

  const [showToken, setShowToken] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const toggleSecretVisibility = () => setShowSecret((prev) => !prev);
  const toggleTokenVisibility = () => setShowToken((prev) => !prev);

  useEffect(() => {
    if (showToken) {
      const timeout = setTimeout(() => {
        setShowToken(false);
      }, 30000);
      return () => clearTimeout(timeout);
    }
    if (showSecret) {
      const timeout = setTimeout(() => {
        setShowSecret(false);
      }, 30000);
      return () => clearTimeout(timeout);
    }
    setEnabled(integration.active);
  }, [showToken, integration.active]);

  const toggleActiveStatus = async () => {
    try {
      const updatedIntegration = await updateIntegration(integration._id, {
        ...integration,
        active: !enabled,
      });
      if (updatedIntegration.success) {
        setItem(updatedIntegration.data.data); // Assume response structure holds updated data
        setEnabled(updatedIntegration.data.data.active); // Reflect actual state from server
        notify(
          'Success!',
          'Integration status changed successfully.',
          'success'
        );
      } else {
        throw new Error('Server did not update the status');
      }
    } catch (error) {
      console.error('Failed to change integration status: ', error);
      setEnabled(integration.active); // Revert to original state if the update fails
      notify(
        'Error!',
        error.response?.data?.error || 'Failed to update status',
        'error'
      );
    }
  };

  const handleDelete = async () => {
    try {
      const updatedIntegration = await updateIntegration(integration._id, {
        ...integration,
        url: '',
        key: '',
        secret: '',
        token: '',
        active: false,
      });
      if (updatedIntegration.success) {
        notify('Success!', 'Integration deleted successfully.', 'success');
        setItem(updatedIntegration.data.data);
        setEnabled(false); // Update local state
      }
    } catch (error) {
      console.error('Failed to delete integration: ', error);
      notify('Error!', error.response?.data?.error || 'Unknown error', 'error');
    }
  };

  return (
    <Fragment>
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Integration Information
        </h3>
        <p className="mt-1 max-w-2xl text-[.8125rem] leading-5 text-gray-500">
          API credentials.
        </p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {integration?.documentation && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                Documentation
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-indigo-500 sm:col-span-2 sm:mt-0 cursor-pointer hover:underline underline-offset-1">
                <Link to={integration?.documentation} target="_blank">
                  <div className="flex flex-row items-center gap-x-2">
                    <span>{integration?.name} documentation</span>
                    <ArrowTopRightOnSquareIcon className="w-auto h-4" />
                  </div>
                </Link>
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-[.8125rem] font-medium text-gray-900">
              Last update
            </dt>
            <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0">
              {FormatDateIntl(integration?.updated_at)}
            </dd>
          </div>
          {integration?.url && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                Store Url
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-indigo-500 sm:col-span-2 sm:mt-0 cursor-pointer hover:underline underline-offset-1">
                <Link to={integration?.url} target="_blank">
                  <div className="flex flex-row items-center gap-x-2">
                    <span>{integration?.url}</span>
                    <ArrowTopRightOnSquareIcon className="w-auto h-4" />
                  </div>
                </Link>
              </dd>
            </div>
          )}
          {integration?.apiVersion && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                API Version
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0">
                {integration?.apiVersion}
              </dd>
            </div>
          )}
          {integration?.key && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                API Key
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0">
                {integration?.key}
              </dd>
            </div>
          )}
          {integration?.secret && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                API Secret
                <button
                  type="button"
                  onClick={toggleSecretVisibility}
                  className="ml-2 relative top-0.5"
                  aria-label={showSecret ? 'Hide Token' : 'Show Token'}
                >
                  {showSecret ? (
                    <EyeSlashIcon className="h-4 w-auto text-gray-500" />
                  ) : (
                    <EyeIcon className="h-4 w-auto text-gray-500" />
                  )}
                </button>
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0">
                {showSecret
                  ? integration?.secret
                  : `${integration?.secret.substring(0, 5)}...`}
              </dd>
            </div>
          )}
          {integration?.token && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-[.8125rem] font-medium text-gray-900">
                API Token
                <button
                  type="button"
                  onClick={toggleTokenVisibility}
                  className="ml-2 relative top-0.5"
                  aria-label={showToken ? 'Hide Token' : 'Show Token'}
                >
                  {showToken ? (
                    <EyeSlashIcon className="h-4 w-auto text-gray-500" />
                  ) : (
                    <EyeIcon className="h-4 w-auto text-gray-500" />
                  )}
                </button>
              </dt>
              <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                {showToken
                  ? integration?.token
                  : `${integration?.token.substring(0, 5)}...`}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-[.8125rem] font-medium text-gray-900">
              Status
            </dt>
            <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={enabled}
                  onChange={toggleActiveStatus}
                  className={classNames(
                    enabled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Activate integration</span>
                  <span
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  >
                    <span
                      className={classNames(
                        enabled
                          ? 'opacity-0 duration-100 ease-out'
                          : 'opacity-100 duration-200 ease-in',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                      aria-hidden="true"
                    >
                      <svg
                        className="h-3 w-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className={classNames(
                        enabled
                          ? 'opacity-100 duration-200 ease-in'
                          : 'opacity-0 duration-100 ease-out',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                      aria-hidden="true"
                    >
                      <svg
                        className="h-3 w-3 text-indigo-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                      >
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </Switch>
                <Switch.Label as="span" className="ml-3 text-[.8125rem]">
                  <span className="font-medium text-gray-900">
                    {enabled ? 'Active' : 'Inactive'}
                  </span>
                </Switch.Label>
              </Switch.Group>
            </dd>
          </div>

          {(integration.category === 'shipping' ||
            integration.category === 'storefront') && (
            <IntegrationOptions data={integration} />
          )}

          <div className="px-4 py-6 flex justify-end">
            <Button
              className="bg-red-500 hover:!bg-red-500/90"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </dl>
      </div>
    </Fragment>
  );
};

export default IntegrationDetails;
