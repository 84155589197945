import ImageThumbnail from '../../../../../../../../shared/components/UIElements/ImageThumbnail';
import RadioButton from '../../../../../../../../shared/components/UIElements/RadioButton';

const MergeItem = ({
  items,
  onMergeItemSelected,
  fulfillmentOrderId,
  mergeItemId,
}) => {
  const totalFulfillableQuantity = items.reduce(
    (total, item) => total + (item.fulfillable_quantity || 0),
    0
  );

  const handleSelectionChange = () => {
    onMergeItemSelected(fulfillmentOrderId);
  };

  const handleItemClick = (e) => {
    if (e.target.type !== 'radio') {
      handleSelectionChange();
    }
  };

  // Determine the items to show and the remaining fulfillable quantity
  const itemsToShow = items.slice(0, 2);
  const remainingQuantity = totalFulfillableQuantity - itemsToShow.length;

  return (
    <li
      className="flex justify-between gap-x-6 py-2 px-4 my-2 rounded-xl bg-gray-500/5 ring-1 ring-gray-900/20 hover:bg-gray-900/10 cursor-pointer"
      onClick={handleItemClick}
    >
      <div className="flex min-w-0 gap-x-4">
        <div className="flex items-center gap-x-4">
          <RadioButton
            label={`${fulfillmentOrderId} x${totalFulfillableQuantity}`}
            labelsr="true"
            id={fulfillmentOrderId}
            value={fulfillmentOrderId}
            name="merging-item"
            onChange={handleSelectionChange}
            checked={mergeItemId === fulfillmentOrderId}
          />
          <div className="flex flex-col">
            <h6 className="text-[.8125rem] leading-5 text-gray-900">
              Fulfillment order
            </h6>
            <p className="text-[.8125rem] font-semibold leading-5 text-gray-900">
              {fulfillmentOrderId}{' '}
              <span className="font-normal">({totalFulfillableQuantity})</span>
            </p>
          </div>
        </div>
        {/* Reserved for FulfillmentOrderStatus */}
        {/* <div className="min-w-0 flex-auto">
          <p className="text-[.8125rem] font-semibold leading-5 text-gray-900">
            {items[0]?.title}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            {items[0]?.sku}
          </p>
        </div> */}
      </div>
      <div className="shrink-0 flex flex-row items-center gap-x-4">
        <div className="mt-1 flex items-center gap-x-1">
          {itemsToShow.map((item, idx) => (
            <ImageThumbnail
              key={idx}
              image={item?.image_url}
              alt={item?.title}
              className="w-8 h-8 object-cover !rounded-md"
            />
          ))}
        </div>
        {remainingQuantity > 0 ? (
          <div className="text-xs leading-5 text-gray-500 font-extrabold">
            + {remainingQuantity} {remainingQuantity === 1 ? 'item' : 'items'}
          </div>
        ) : (
          <div className="text-xs leading-5 text-gray-500 font-extrabold">
            {totalFulfillableQuantity} item
          </div>
        )}
      </div>
    </li>
  );
};

export default MergeItem;
