import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { FormatDateTime } from '../../../../shared/utils/FormatDateTime';
import Badge from '../../../../shared/components/UIElements/Badge';
import CopyIcon from '../../../../shared/components/UIElements/CopyIcon';

const getShipmentStatusClasses = (status) => {
  switch (status) {
    case 'delivered':
      return {
        border: 'border-gray-500',
        badge: 'bg-gray-50 text-gray-700 ring-gray-600/20',
        dot: 'fill-gray-700',
      };
    case 'in_transit':
      return {
        border: 'border-indigo-500',
        badge: 'bg-indigo-50 text-indigo-700 ring-indigo-600/20',
        dot: 'fill-indigo-700',
      };
    case 'confirmed':
      return {
        border: 'border-green-500',
        badge: 'bg-green-50 text-green-700 ring-green-600/20',
        dot: 'fill-green-700',
      };
    case 'attempted_delivery':
      return {
        border: 'border-yellow-500',
        badge: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        dot: 'fill-yellow-700',
      };
    case 'out_for_delivery':
      return {
        border: 'border-green-500',
        badge: 'bg-green-50 text-green-700 ring-green-600/20',
        dot: 'fill-green-700',
      };
    case 'success':
      return {
        border: 'border-yellow-500',
        badge: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        dot: 'fill-yellow-700',
      };
    default:
      return {
        border: 'border-red-500',
        badge: 'bg-red-50 text-red-700 ring-red-600/20',
        dot: 'fill-red-700',
      };
  }
};

const ShipmentMenu = ({ fulfillments }) => {
  const handleMenuClick = (e) => {
    e.preventDefault();
    return;
  };

  const sortedFulfillments = fulfillments.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const mostRecentFulfillment = sortedFulfillments[0];
  const shipmentStatusClasses = getShipmentStatusClasses(
    mostRecentFulfillment?.shipment_status
      ? mostRecentFulfillment?.shipment_status
      : mostRecentFulfillment?.status
  );

  const getShipmentClass = (fulfillment) => {
    return getShipmentStatusClasses(
      fulfillment?.shipment_status
        ? fulfillment?.shipment_status
        : fulfillment?.status
    );
  };

  return (
    <Fragment>
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <div className="flex items-center">
            <span
              className={`capitalize inline-flex items-center rounded-md px-2 py-1 mr-1 text-xs font-medium ring-1 ring-inset ${shipmentStatusClasses.badge}`}
            >
              <svg
                className={`h-1.5 w-1.5 mr-1.5 ${shipmentStatusClasses.dot}`}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>

              {sortedFulfillments.slice(0, 1).map((fulfillment, idx) => (
                <div key={idx}>
                  {fulfillment.shipment_status
                    ? fulfillment.shipment_status
                    : fulfillment.status === 'cancelled'
                      ? 'Cancelled'
                      : 'Label created'}
                </div>
              ))}
            </span>
            {fulfillments?.length > 1 && (
              <span>+{fulfillments?.length - 1}</span>
            )}
            <span className="opacity-0 group-hover:opacity-100 transition-opacity text-black ">
              <ChevronDownIcon className="h-4 w-4 ml-.5" />
            </span>
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="
              absolute 
              right-0 
              z-10 
              mt-0.5 
              w-full
              sm:w-96
              origin-top-right 
              rounded-lg 
              bg-white 
              shadow-lg 
              ring-1 
              ring-gray-300 
              focus:outline-none
              max-h-56
              overflow-y-auto"
          >
            {fulfillments?.map((fulfillment, idx) => (
              <Menu.Item key={idx} onClick={handleMenuClick}>
                <div
                  className={`block px-3 py-3 text-[.8125rem] leading-5 border-l-4 text-gray-900 
                  ${idx === 0 ? 'rounded-tl-lg' : ''} ${idx === fulfillments.length - 1 ? 'rounded-bl-lg' : ''} 
                  ${idx !== fulfillments.length - 1 ? 'border-b border-b-1 border-b-gray-900/20' : ''} 
                  ${getShipmentClass(fulfillment).border}`}
                >
                  <div className="flex items-center">
                    <div className="flex-1 font-semibold">
                      {fulfillment?.name}
                    </div>
                    <Badge
                      className={`capitalize inline-flex items-center rounded-md px-2 py-1 mr-1 text-xs font-medium ring-1 ring-inset ${getShipmentClass(fulfillment).badge}`}
                    >
                      <svg
                        className={`h-1.5 w-1.5 ${getShipmentClass(fulfillment).dot}`}
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {fulfillment.shipment_status
                        ? fulfillment.shipment_status
                        : fulfillment.status === 'cancelled'
                          ? 'Cancelled'
                          : 'Label created'}
                    </Badge>
                  </div>
                  <div className="mt-1">
                    {FormatDateTime(fulfillment?.created_at)}
                  </div>
                  <div className="flex mt-1 items-center">
                    <div className="flex-1">
                      {fulfillment?.tracking_urls ? (
                        <Link
                          to={fulfillment?.tracking_urls[0]}
                          className="text-indigo-500"
                          traget="_blank"
                        >
                          {fulfillment?.tracking_numbers[0]}
                        </Link>
                      ) : (
                        <Fragment>{fulfillment?.tracking_numbers[0]}</Fragment>
                      )}
                    </div>
                    <div>
                      <CopyIcon data={fulfillment?.tracking_numbers[0]} />
                    </div>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  );
};

export default ShipmentMenu;
