import React, { Fragment, useEffect, useState } from 'react';

import { getVariantImages } from '../../../../../../utils/Api/Products/Get';
import { usePopoutNotification } from '../../../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';

import LineItem from '../LineItem';
import Card from '../../../../../../shared/components/UIElements/Card';
import ContentLoader from '../../../../../../shared/components/UIElements/Loaders/ContentLoader';
import ItemCardHeader from '../LineItem/ItemCardHeader';
import FulfillmentInformation from './FulfillmentInformation';

const ItemFulfillments = ({
  fulfillments = [],
  allItems,
  handleSaveField,
  orderId,
}) => {
  const [itemsLoading, setItemsLoading] = useState(true);
  const [variantArray, setVariantArray] = useState({});
  const { notify } = usePopoutNotification();

  useEffect(() => {
    setItemsLoading(true);
    const fetchData = async () => {
      try {
        const itemData = await getVariantImages();
        setVariantArray(itemData.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        notify('Internal Error', "Items couldn't be loaded", 'error');
        setItemsLoading(true);
      }
      setItemsLoading(false);
    };
    fetchData();
  }, []);

  const findVariantImage = (sku) => {
    if (!sku) {
      console.error('SKU is null or undefined');
      return null;
    }

    if (!variantArray.variants || !variantArray.images) {
      console.error('Variant array data is not available');
      return null;
    }

    const variant = variantArray.variants.find(
      (variant) => variant.sku === sku
    );
    if (!variant) {
      console.error(`Variant with SKU ${sku} not found`);
      return null;
    }

    const image = variantArray.images.find(
      (img) => img._id === variant.imageId
    );
    if (!image) {
      console.error(`Image for variant with SKU ${sku} not found`);
      return null;
    }

    return image.src || image.cloudUrl || null;
  };

  return (
    <Fragment>
      {itemsLoading ? (
        <Card className="mb-4">
          {fulfillments.map((item, idx) => (
            <div
              key={idx}
              className="mt-2 grid grid-cols-1 w-full rounded-lg ring-1 ring-gray-900/20 p-4"
            >
              <ContentLoader />
            </div>
          ))}
        </Card>
      ) : (
        <div>
          {fulfillments.map(
            (fulfillment, idx) =>
              fulfillment.status !== 'cancelled' && (
                <Fragment key={idx}>
                  <Card className="mb-4 text-[.8125rem]">
                    <ItemCardHeader
                      status="Shipped"
                      className="ring-gray-700/20 bg-gray-100 text-gray-800 fill-gray-500"
                      statusText={fulfillment?.name}
                    />
                    <div className="mt-2 grid grid-cols-1 w-full rounded-lg ring-1 ring-gray-900/20">
                      <FulfillmentInformation fulfillment={fulfillment} />
                      {fulfillment.line_items.map((item, idx) => (
                        <div
                          key={idx}
                          className={
                            idx !== fulfillment.line_items.length - 1
                              ? 'border-b-[1px] border-gray-900/20'
                              : ''
                          }
                        >
                          <LineItem
                            lineItem={item}
                            findVariantImage={findVariantImage}
                            variantArray={variantArray}
                            selectedItems={{}}
                          />
                        </div>
                      ))}
                    </div>
                  </Card>
                </Fragment>
              )
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ItemFulfillments;
