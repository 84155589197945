import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getOrder } from '../../../utils/Api/Orders/Get';
import { deleteOrder } from '../../../utils/Api/Orders/Delete';

import { updateOrderField } from '../../../utils/Api/Orders/Update';

import { FormProvider } from '../../../shared/context/form-context';
import { FormatDateIntl } from '../../../shared/utils/FormatDateTime';

import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import PageFooter from '../../../shared/components/UIElements/PageFooter';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';

import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useAlert } from '../../../shared/components/UIElements/Alerts/AlertProvider';

import OrderForm from '../components/OrderForm/OrderForm';
import { useActivityLog } from '../../../shared/context/logging-context';

const EditOrder = () => {
  let { id } = useParams();
  const [item, setItem] = useState(null);
  const [riskData, setRiskData] = useState(null);
  const navigate = useNavigate();
  const { notify } = usePopoutNotification();
  const { prompt } = useAlert();
  const { updateLog } = useActivityLog();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemData = await getOrder(id);
        setItem(itemData.data.order);
        setRiskData(itemData.data.risk);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle error here
      }
    };
    fetchData();
  }, [id]);

  const orderFormRef = useRef();

  // Delete order
  const deleteOrd = async (id) => {
    try {
      const response = await deleteOrder(id);
      notify('Successfully deleted!', response.data.message, 'success');

      updateLog('Order', 'deleted', 'order', id);

      navigate('/orders/');
    } catch (error) {
      notify('Error!', error.response.data.message, 'error');
    }
  };

  const handleDeleteOrder = () => {
    prompt(
      'Confirm Delete',
      'Are you sure you want to delete this variant? This action cannot be undone.',
      () => deleteOrd(id),
      'Delete'
    );
  };

  // Update note
  const handleSaveField = async (
    fieldName,
    newField,
    selectedItems,
    actionData = null
  ) => {
    try {
      const response = await updateOrderField(
        id,
        fieldName,
        newField,
        selectedItems,
        actionData
      );

      if (response.data.order) {
        // Dynamically update the field in the state
        setItem((prevItem) => ({
          ...prevItem,
          [fieldName]: response.data.order[fieldName],
        }));
        notify('Order updated successfully!', response.data.message, 'success');
        updateLog('Order', 'updated', fieldName.replace(/_/g, ' '), id);
      }
    } catch (error) {
      notify('Failed to update order', error.response.data.message, 'error');
    }
  };

  const hasNoRisksFound =
    riskData && riskData?.riskLevel === 'NONE' ? null : 'Risks found';

  return !item ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : (
    <FormProvider initialFields={item || {}}>
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <PageHeader
            pageTitle={item.name}
            subTitle={FormatDateIntl(item.created_at)}
            labels={[`${item.internal_status}`, hasNoRisksFound]}
            backButton={true}
          />
          <div className="w-full grow lg:flex">
            <OrderForm
              ref={orderFormRef}
              handleSaveField={handleSaveField}
              orderId={id}
              riskData={riskData}
            />
          </div>
          <PageFooter>
            <button
              type="button"
              className="inline-flex items-center rounded-md px-3 py-2 text-[.8125rem] shadow-sm font-semibold text-white bg-red-600 hover:!bg-red-400"
              onClick={handleDeleteOrder}
            >
              Delete
            </button>
          </PageFooter>
        </div>
      </Container>
    </FormProvider>
  );
};

export default EditOrder;
