const PaymentCardShipping = ({ shippingLines }) => {
  return (
    <>
      {/* Loop through the shipping_lines array */}
      {shippingLines.length > 0 &&
        shippingLines.map((shipping, index) => (
          <div
            className="px-2 py-2 grid grid-cols-6 sm:gap-4 sm:px-0"
            key={index}
          >
            <dt className="text-sm text-gray-900">Shipping</dt>
            <dd className="text-sm leading-6 text-gray-700 mt-0 col-span-4">
              {shipping.title}
            </dd>
            <dd className="text-sm leading-6 text-gray-700 mt-0 flex justify-end">
              ${parseFloat(shipping.price).toFixed(2)}
            </dd>
          </div>
        ))}
      {/* Shipping lines loop end */}
    </>
  );
};

export default PaymentCardShipping;
