const DialogActions = ({ setOpen, actions }) => {
  const handleSecondaryClick = () => {
    setOpen(false);
  };

  const handlePrimaryClick = () => {
    actions.primary.action();
    setOpen(false);
  };

  return (
    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-[.8125rem] font-semibold shadow-sm sm:ml-3 sm:w-auto ${actions?.primary?.classes}`}
        onClick={handlePrimaryClick}
      >
        {actions?.primary?.title}
      </button>
      <button
        type="button"
        className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-[.8125rem] font-semibold shadow-sm sm:mt-0 sm:w-auto ${actions?.secondary?.classes}`}
        onClick={handleSecondaryClick}
      >
        {actions?.secondary?.title}
      </button>
    </div>
  );
};

export default DialogActions;
