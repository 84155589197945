import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import Button from '../../../shared/components/UIElements/Button';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
// import { getBatches } from "../../utils/api";
// import ProductList from "../components/ProductList";
import TablePagination from '../../../shared/components/UIElements/Pagination/TablePagination';
import Placeholder from '../../../shared/components/UIElements/EmptyPlaceholder/Placeholder';

const Products = () => {
  const [items, setItems] = useState(null);
  const [limit, setLimit] = useState(20); // If you want to allow changing the number of items per page
  const [totalPages, setTotalPages] = useState(0); // Add state for total pages
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Add currentPage state
  const [loading, setLoading] = useState(false);

  // const [committed, setCommitted] = useState([]);

  useEffect(() => {
    setItems([]);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getProducts(currentPage, limit);
  //       setItems(data.items);
  //       setTotalItems(data.totalItems);
  //       const calculatedTotalPages = Math.ceil(data.totalItems / limit);
  //       setTotalPages(calculatedTotalPages);
  //     } catch (error) {
  //       console.error("Failed to fetch orders:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [currentPage, limit]);

  const handlePageChange = (newPage) => {
    setLoading(true); // Optionally set loading here or rely on useEffect
    setCurrentPage(newPage);
  };

  return !items ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : items.length === 0 ? (
    <Fragment>
      <Container>
        <PageHeader pageTitle="Batches" />
      </Container>
      <Container>
        <Placeholder
          title="No products found"
          message="Get started by creating a product."
          buttonText="Create Product"
          to="/products/create"
        />
      </Container>
    </Fragment>
  ) : (
    <Container>
      <PageHeader pageTitle="Inventory">
        <Link to="/products/variant/create">
          <Button>Create Variant</Button>
        </Link>
      </PageHeader>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:overflow-x-visible overflow-x-auto"></div>
        </div>
        <div>
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            limit={limit}
            onPageChange={handlePageChange}
            loading={loading}
          />
        </div>
      </div>
    </Container>
  );
};

export default Products;
