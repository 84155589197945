import React, { Fragment } from 'react';

const UserIcon = ({ user, ...props }) => {
  // Function to generate initials
  const getUserInitials = (firstName, lastName) => {
    const initials = `${firstName[0]}${lastName[0]}`;
    return initials.toUpperCase();
  };

  // Decide what to display as the image

  return (
    <Fragment>
      {user && user.image ? (
        <img
          src={user.image}
          alt={`${user.userName} ${user.userLastName}`}
          className={`h-11 w-11 flex-none rounded-full bg-gray-900/5 ring-1 ring-gray-300 ${props?.className}`}
        />
      ) : (
        <div
          className={`h-11 w-11 flex-none rounded-full bg-gray-900/5 text-gray-500/70 flex items-center justify-center text-[.8125rem] font-semibold ${props?.className}`}
        >
          {user ? getUserInitials(user.userName, user.userLastName) : 'U'}
        </div>
      )}
    </Fragment>
  );
};

export default UserIcon;
