import React, { Fragment, useEffect, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Listbox, Transition } from '@headlessui/react';

import Card from '../../../../../shared/components/UIElements/Card';

const dropData = [
  { name: 'active', active: true },
  { name: 'inactive', active: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProductStatus = ({ productStatus, onChange }) => {
  const defaultSelection =
    dropData.find((item) => item.name === productStatus) || dropData[1];
  const [selected, setSelected] = useState(defaultSelection);

  useEffect(() => {
    setSelected(defaultSelection);
  }, [productStatus]);

  const handleOnChange = (newValue) => {
    setSelected(newValue);
    const syntheticEvent = {
      target: {
        name: 'status',
        value: newValue.name,
      },
    };
    onChange(syntheticEvent);
  };

  return (
    <Card className="overflow-visible">
      <Listbox
        name="status"
        value={selected}
        onChange={(event) => handleOnChange(event)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-[.8125rem] font-medium leading-5 text-gray-900">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Status
              </h2>
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5">
                <span className="flex items-center">
                  <span
                    aria-label={selected.active ? 'active' : 'inactive'}
                    className={classNames(
                      selected.active ? 'bg-green-400' : 'bg-gray-200',
                      'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                    )}
                  />
                  <span className="ml-3 block truncate">
                    {selected.name.charAt(0).toUpperCase() +
                      selected.name.slice(1)}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[.8125rem]">
                  {dropData.map((dropItem, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={dropItem}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                dropItem.active
                                  ? 'bg-green-400'
                                  : 'bg-gray-200',
                                'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate'
                              )}
                            >
                              {dropItem.name.charAt(0).toUpperCase() +
                                dropItem.name.slice(1)}
                              <span className="sr-only">
                                {' '}
                                is {dropItem.active ? 'active' : 'inactive'}
                              </span>
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </Card>
  );
};

export default ProductStatus;
