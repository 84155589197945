import React, { useState, useEffect } from 'react';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  isToday,
  parseISO,
} from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Function to adjust the date to the local time zone
const adjustToLocalTimezone = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

const PreOrderForm = ({ fields, handleChange, setShowPreOrderCalendar }) => {
  // Initialize currentMonth based on pre_order_date, or fallback to the current date
  const initialPreOrderDate = fields?.internal_data?.pre_order_date
    ? adjustToLocalTimezone(parseISO(fields.internal_data.pre_order_date))
    : new Date();

  const [currentMonth, setCurrentMonth] = useState(
    startOfMonth(initialPreOrderDate)
  );
  const [selectedDate, setSelectedDate] = useState(
    fields?.internal_data?.pre_order_date
      ? format(
          adjustToLocalTimezone(parseISO(fields.internal_data.pre_order_date)),
          'yyyy-MM-dd'
        )
      : null
  );

  useEffect(() => {
    // Ensure currentMonth is set to the month of the pre_order_date if it exists
    if (fields?.internal_data?.pre_order_date) {
      setCurrentMonth(
        startOfMonth(
          adjustToLocalTimezone(parseISO(fields.internal_data.pre_order_date))
        )
      );
    }
  }, [fields?.internal_data?.pre_order_date]);

  // Generate the days for the current month with leading and trailing days
  const startDate = startOfWeek(startOfMonth(currentMonth));
  const endDate = endOfWeek(endOfMonth(currentMonth));

  const days = eachDayOfInterval({ start: startDate, end: endDate }).map(
    (date) => ({
      date: format(date, 'yyyy-MM-dd'), // Ensure date is in 'yyyy-MM-dd' format
      isToday: isToday(date),
      isCurrentMonth: date.getMonth() === currentMonth.getMonth(),
    })
  );

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  const savePreOrderDate = (e) => {
    e.preventDefault();
    if (selectedDate) {
      const preOrderEvent = {
        target: {
          name: `internal_data.pre_order_date`, // Correctly target the nested structure
          value: selectedDate,
        },
      };
      handleChange(preOrderEvent);
      handleCloseCalendar();
    }
  };

  const handleCloseCalendar = () => {
    setShowPreOrderCalendar(false);
  };

  return (
    <div>
      <div className="flex flex-row w-full items-center">
        <h2 className="text-base font-semibold leading-6 text-gray-900 flex-1">
          Pre-Order Date
        </h2>
        <span
          onClick={handleCloseCalendar}
          className=" text-gray-400 hover:text-gray-500 cursor-pointer"
        >
          <XCircleIcon className="w-6 h-auto" />
        </span>
      </div>
      <p className="mt-1 text-[.8125rem] text-gray-500">
        Select a pre-order date for this variant.
      </p>
      <div className="flex flex-col">
        <div className="mt-4 text-center">
          <div className="flex items-center text-gray-900 bg-gray-100 rounded-md py-1 px-2">
            <button
              type="button"
              onClick={handlePrevMonth}
              className="-m-1.5 flex flex-none items-center justify-center p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-300 hover:rounded-tl-md hover:rounded-bl-md"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(currentMonth, 'MMMM yyyy')}
            </div>
            <button
              type="button"
              onClick={handleNextMonth}
              className="-m-1.5 flex flex-none items-center justify-center p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-300 hover:rounded-tr-md hover:rounded-br-md"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500 bg-gray-100 rounded-md py-1 px-2">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days?.map((day, dayIdx) => (
              <button
                key={day.date}
                type="button"
                onClick={() => handleDateSelection(day.date)}
                className={classNames(
                  'py-1.5 hover:bg-gray-100 focus:z-10',
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  day.isToday && 'font-semibold text-indigo-600',
                  selectedDate === day.date && 'bg-indigo-600 text-white',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === days.length - 7 && 'rounded-bl-lg',
                  dayIdx === days.length - 1 && 'rounded-br-lg'
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    selectedDate === day.date &&
                      day.isToday &&
                      'bg-indigo-600 text-white',
                    selectedDate === day.date &&
                      !day.isToday &&
                      'bg-gray-900 text-white'
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
              </button>
            ))}
          </div>
          <button
            type="button"
            onClick={(e) => savePreOrderDate(e)}
            className="mt-8 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add date
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreOrderForm;
