import React, { useEffect, useState } from 'react';

import DashboardOrderTableItem from './DashboardOrderTableItem';
import BasicTable from '../../../../shared/components/UIElements/Tables/BasicTable';
import { getOrders } from '../../../../utils/Api/Orders/Get';
import Card from '../../../../shared/components/UIElements/Card';
import TableLoader from '../../../../shared/components/UIElements/Loaders/TableLoader';
import TablePagination from '../../../../shared/components/UIElements/Pagination/TablePagination';

const tableHeaders = () => (
  <tr>
    <th
      scope="col"
      className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900"
    >
      Order #
    </th>
    <th
      scope="col"
      className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
    >
      Status
    </th>
    <th
      scope="col"
      className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 lg:table-cell"
    >
      Priority
    </th>
    <th
      scope="col"
      className="hidden  px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
    >
      Items
    </th>
    <th
      scope="col"
      className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
    >
      Created
    </th>
  </tr>
);

const DashboardOrderTable = () => {
  const [items, setItems] = useState(null);
  const [limit, setLimit] = useState(5); // Corrected: Use the value from the state directly
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isDatabaseEmpty, setIsDatabaseEmpty] = useState(false);

  const fetchData = async (page = 1) => {
    setLoading(true); // Add this to set loading state during fetch
    try {
      const data = await getOrders(page, limit, debouncedSearchQuery);
      setItems(data.data.items);
      setTotalItems(data.data.totalItems);
      const calculatedTotalPages = Math.ceil(data.data.totalItems / limit);
      setTotalPages(calculatedTotalPages);

      if (page === 1 && searchQuery === '' && data.data.items.length === 0) {
        setIsDatabaseEmpty(true);
      } else if (data.data.items.length > 0) {
        setIsDatabaseEmpty(false);
      }
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit, debouncedSearchQuery]);

  const handlePageChange = (newPage) => {
    if (newPage > totalPages || newPage < 1) return;
    setCurrentPage(newPage);
  };

  return (
    <Card className="ring-1 ring-gray-900/10 overflow-x-auto w-full md:w-1/2">
      <h3 className="text-base font-semibold leading-5 text-gray-900 mb-4 ml-2">
        Recent orders
      </h3>
      {!items ? (
        <TableLoader />
      ) : (
        <div className="overflow-x-auto">
          <div className="p-1">
            <BasicTable
              items={items}
              tableHeaders={tableHeaders}
              RowComponent={DashboardOrderTableItem}
            />
            <div className="mt-2">
              <TablePagination
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={totalItems}
                limit={limit}
                onPageChange={handlePageChange}
                loading={loading}
                className="border-t-0 !py-0 !pt-3"
              />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default DashboardOrderTable;
