import React, { Fragment } from 'react';
import Button from '../../../../../../shared/components/UIElements/Button';
import BtnLoadingSpinner from '../../../../../../shared/components/UIElements/BtnLoadingSpinner';

const ItemCardFooter = ({
  status,
  itemsEditing,
  setItemsEditing,
  onCancel,
  selectedOption,
  onSelectOption,
  handleHoldItems,
  handleReleaseHoldItems,
  handleSplitItems,
  handleMergeItems,
  handleItemStatus,
  handleUpdateItems,
  isLoading,
}) => {
  const handleMenuSelection = (selectedFooterOption) => {
    onSelectOption(selectedFooterOption); // Pass the selected option to the parent
  };

  const handleOnClick = (e, buttonName) => {
    e.preventDefault();
    handleMenuSelection(buttonName);
    setItemsEditing(!itemsEditing);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setItemsEditing(false);
    onCancel();
  };

  const actions = {
    Received: [
      {
        name: 'Ship items',
        className:
          '!bg-white !text-gray-900 ring-1 ring-gray-900/20 hover:!bg-gray-900/5',
        action: '',
      },
      {
        name: 'Hold items',
        action: handleHoldItems,
      },
      {
        name: 'Split items',
        action: handleSplitItems,
        className: 'hidden',
      },
      {
        name: 'Merge items',
        action: handleMergeItems,
        className: 'hidden',
      },
      {
        name: 'Change status',
        action: handleItemStatus,
        className: 'hidden',
      },
      {
        name: 'Edit items',
        action: handleUpdateItems,
        className: 'hidden',
      },
    ],
    'On Hold': [
      {
        name: 'Release items',
        action: handleReleaseHoldItems,
      },
    ],
    Batched: [
      {
        name: 'Change status',
        action: handleItemStatus,
        className: 'hidden',
      },
    ],
  };

  return (
    <Fragment>
      {actions[status] && (
        <div className="w-full flex space-between">
          <div className="flex-1" />
          <div className="mt-2">
            {!itemsEditing &&
              actions[status].map((button, idx) => (
                <Button
                  key={idx}
                  type="button"
                  className={`ml-2 ${button.className} text-xs`}
                  onClick={(e) => handleOnClick(e, button.name)}
                >
                  {button.name}
                </Button>
              ))}
            {itemsEditing && (
              <Fragment>
                {actions[status] && (
                  <div className="w-full flex justify-end gap-x-2 items-center">
                    {isLoading && (
                      <BtnLoadingSpinner className="w-5 h-5 text-gray-900" />
                    )}
                    <Button
                      onClick={handleCancel}
                      className="!bg-white !text-gray-900 ring-1 ring-gray-900/20 hover:!bg-gray-900/5 text-xs"
                    >
                      Cancel
                    </Button>
                    {actions[status]
                      .filter((button) => selectedOption === button.name)
                      .map((button, idx) => (
                        <Button
                          key={idx}
                          type="button"
                          className={`text-xs ${isLoading ? 'bg-indigo-400' : ''}`}
                          onClick={() => button.action && button.action()}
                          disabled={isLoading}
                        >
                          {button.name}
                        </Button>
                      ))}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ItemCardFooter;
