import React, { useState, useRef, useEffect } from 'react';
import { useDialog } from '../components/UIElements/Dialog/DialogProvider'; // Import the useDialog hook

const useEditableField = (EditComponent, editProps, handleSaveField) => {
  const [editField, setEditField] = useState(false);
  const fieldNameRef = useRef(editProps.fieldName);
  const fieldValueRef = useRef(editProps.value);
  const { prompt } = useDialog();

  useEffect(() => {
    fieldValueRef.current = editProps.value; // Update the ref whenever the value changes
    fieldNameRef.current = editProps.fieldName;
  }, [editProps.value, editProps.fieldName]);

  const handleSave = () => {
    setEditField(false);
    handleSaveField(fieldNameRef.current, fieldValueRef.current);
  };

  const handleClose = () => {
    setEditField(false);
  };

  const handleEdit = () => {
    setEditField(true);
    prompt(
      `Edit ${editProps.dialogTitle}`, // Dynamically set the dialog title
      <EditComponent
        {...editProps}
        onChange={(newValue) => (fieldValueRef.current = newValue)}
      />, // Spread the props to the EditComponent
      {
        primary: {
          title: 'Save',
          classes: 'bg-indigo-800 text-white hover:bg-indigo-600',
          action: handleSave,
        },
        secondary: {
          title: 'Cancel',
          classes:
            'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
          action: handleClose,
        },
        close: handleClose,
      }
    );
  };

  return {
    editField,
    handleEditField: handleEdit,
    currentValue: fieldValueRef.current,
  };
};

export default useEditableField;
