// React Components
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Shared Components
import { FormProvider } from '../../../shared/context/form-context';
import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useAlert } from '../../../shared/components/UIElements/Alerts/AlertProvider';

// Page Components
import ProductForm from '../components/ProductForm/ProductForm';

// API Components
import { getProduct } from '../../../utils/Api/Products/Get';
import { updateProduct } from '../../../utils/Api/Products/Update';
import { deleteProduct } from '../../../utils/Api/Products/Delete';
import { getOrderedProducts } from '../../../utils/Api/Orders/Get';
import ButtonDropdown from '../../../shared/components/UIElements/ButtonDropdown';

const EditProduct = () => {
  // Globally used variables
  const { notify } = usePopoutNotification(); // Optional
  const { prompt } = useAlert(); // Optional

  const navigate = useNavigate();
  let { id } = useParams();

  const [item, setItem] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const productFormRef = useRef();

  // Custom variables
  const [committed, setCommitted] = useState(0);

  // Grab data effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product data
        const itemData = await getProduct(id);
        setItem(itemData.data);

        // Fetch committed inventory
        const orderedData = await getOrderedProducts();
        setCommitted(orderedData.data.ordered_skus || 0);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [id]);

  // Form delete handler
  const handleDelete = () => {
    prompt(
      'Confirm Delete',
      'Are you sure you want to delete this product? This action cannot be undone.',
      async () => {
        try {
          const response = await deleteProduct(id);
          notify('Successfully deleted!', response.data.message, 'success');
          setIsSubmitting(false);
          navigate('/products/');
        } catch (error) {
          notify('Error!', error.response.data.message, 'error');
        }
      },
      'Delete'
    );
  };

  // Form discard handler
  const handleDiscard = () => {
    navigate(-1); // Navigates back to the previous page
  };

  // Form save handler
  const handleFormSubmit = async (formData) => {
    setIsSubmitting(true);
    try {
      const updatedProduct = await updateProduct(formData, formData._id);
      if (updatedProduct.success) {
        notify('Success!', updatedProduct.data.message, 'success');
        setItem(formData);
      } else {
        notify('Error!', updatedProduct.message, 'error');
      }
    } catch (error) {
      console.error('Failed to update product:', error);
      notify('Error!', error, 'error');
    }
    setIsSubmitting(false);
  };

  return !item ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : (
    <FormProvider initialFields={item || {}} onFormSubmit={handleFormSubmit}>
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <PageHeader pageTitle={`Edit Product`} backButton={true}>
            <ButtonDropdown
              onDelete={handleDelete}
              onDiscard={handleDiscard}
              onSave={() => productFormRef.current.submitForm()}
            />
          </PageHeader>
          <div className="w-full grow lg:flex">
            <ProductForm
              ref={productFormRef}
              onFormSubmit={handleFormSubmit}
              committed={committed}
            />
          </div>
        </div>
      </Container>
    </FormProvider>
  );
};

export default EditProduct;
