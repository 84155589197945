import React, { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useDialog } from '../../Dialog/DialogProvider';
import ExportDialog from '../../ExportDialog/ExportDialog';

const TableActions = ({ actions }) => {
  const { prompt } = useDialog();
  const [selectedOption, setSelectedOption] = useState(null); // State to manage selected option

  const handleExport = (item) => {
    const dialogActions = {
      primary: {
        title: `Export ${actions.button.name}`,
        classes: 'bg-indigo-800 text-white hover:bg-indigo-600',
        action: () => {
          console.log('Triggering export with option:', selectedOption);
          if (selectedOption) {
            item.action(selectedOption); // Trigger export with the selected option
          } else {
            console.error('No export option selected');
          }
        },
      },
      secondary: {
        title: 'Cancel',
        classes:
          'bg-white-600 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        action: 'close',
      },
    };

    prompt(
      `Export ${actions.button.name}`,
      <ExportDialog
        onSelect={(option) => {
          console.log('Export option selected:', option); // Log to confirm selection
          setSelectedOption(option); // Set the selected option in state
        }}
      />,
      dialogActions
    );
  };

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-l-md px-3 py-2 text-[0.8125rem] font-medium text-gray-700 bg-gray-400/25 hover:bg-gray-400/35 focus:z-10"
        onClick={actions.button.action}
      >
        Add {actions.button.label}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <MenuButton className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-700/50 hover:text-gray-700 bg-gray-400/25 hover:bg-gray-400/35 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
        <MenuItems
          transition
          className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
        >
          <div className="py-1">
            {actions.menu.map((item) => (
              <MenuItem
                key={item.name}
                onClick={() => {
                  if (item.name === 'Export') {
                    handleExport(item); // Call handleExport when 'Export' is clicked
                  } else {
                    item.action(); // Execute other actions
                  }
                }}
                className="hover:bg-gray-400/15"
              >
                <span className="block px-4 py-2 text-sm text-gray-700 cursor-pointer">
                  {item.name}
                </span>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};

export default TableActions;
