const TextArea = ({
  label = '',
  labelSr = 'false',
  value,
  rows = 4,
  onChange,
}) => {
  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className="w-full">
      {label !== '' && (
        <label
          htmlFor="comment"
          className={`block text-[.8125rem] font-medium leading-5 text-gray-900 ${labelSr && labelSr === 'true' ? 'sr-only' : ''}`}
        >
          {label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          rows={rows}
          onChange={handleChange}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          defaultValue={value}
        />
      </div>
    </div>
  );
};

export default TextArea;
