import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/solid';
import Badge from '../../../../shared/components/UIElements/Badge';

const ProductItem = ({ item, selectedItems, isSelected, onSelectToggle }) => {
  const [inventoryQuantity, setInventoryQuantity] = useState(0);
  const variantCount = item?.variants?.length;
  const tags = item && item?.tags ? item.tags.split(',') : [];
  const navigate = useNavigate();

  useEffect(() => {
    // Sum the inventoryQuantity of all variants without mutating the original item object
    const totalQuantity =
      item?.variants?.reduce(
        (acc, variant) => acc + variant.inventory_quantity,
        0
      ) || 0;
    setInventoryQuantity(totalQuantity);
  }, [item]);

  const handleTitleClick = (e) => {
    if (selectedItems.length > 0) {
      e.preventDefault(); // Prevent the link from navigating
      e.stopPropagation();
      onSelectToggle(); // Toggle the selection
    } else {
      const variantLink = `/products/${item._id}`;
      navigate(variantLink);
    }
  };

  return (
    <tr
      className={`
      ${isSelected ? ' bg-gray-200/20' : ''}
      hover:bg-gray-200/20
    `}
    >
      <td className="relative px-4 sm:w-12 sm:px-6">
        {isSelected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={item?.id}
          checked={isSelected}
          onChange={onSelectToggle}
        />
      </td>
      <td
        className="whitespace-nowrap py-4 pl-4 pr-3 font-bold text-xs text-gray-900 sm:pl-0 cursor-pointer"
        onClick={handleTitleClick}
      >
        <div className="block sm:flex items-center">
          <div className="h-11 w-11 rounded-full bg-gray-100 flex items-center justify-center border border-gray-200">
            {item?.images?.length > 0 ? (
              <img
                className="h-11 w-11 rounded-full"
                src={item?.images[0]?.src || item?.images[0]?.cloudUrl}
                alt=""
              />
            ) : (
              <PhotoIcon className="h-6 w-auto text-gray-400" />
            )}
          </div>
          <div className="hidden ml-4 sm:block">
            <div className="font-medium text-gray-900">{item?.title}</div>
          </div>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 capitalize ring-inset ${
            item?.status >= 'archived'
              ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
              : item?.status >= 'draft'
                ? 'bg-indigo-50 text-indigo-700 ring-red-600/20'
                : 'bg-green-50 text-green-700 ring-green-600/20'
          }`}
        >
          {item?.status}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:tabel-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            inventoryQuantity >= 0
              ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
              : inventoryQuantity < 0
                ? 'bg-red-50 text-red-700 ring-red-600/20'
                : inventoryQuantity > 0 && inventoryQuantity >= 10
                  ? 'bg-yellow-50 text-yellow-700 ring-yellow-600/20'
                  : 'bg-green-50 text-green-700 ring-green-600/20'
          }`}
        >
          {inventoryQuantity} in stock
        </span>
        <span className="hidden items-center rounded-md px-2 py-1 text-xs font-medium sm:inline-flex">
          for {variantCount} variants
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <div className="flex flex-row gap-x-1">
          {tags.slice(0, 3).map((tag, idx) => (
            <Badge
              key={idx}
              className="ring-gray-600/20 !text-[.7125rem] ring-1 ring-inset"
            >
              {tag}
            </Badge>
          ))}
          {tags.length > 3 && (
            <span className="ring-gray-600/20 !text-[.7125rem] ring-1 ring-inset">
              ...
            </span>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
        <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium">
          {item?.product_type}
        </span>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium mr-3">
        <Link
          to={`/products/${item._id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Edit<span className="sr-only">, {item?.title}</span>
        </Link>
      </td>
    </tr>
  );
};

export default ProductItem;
