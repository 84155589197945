import React, { createContext, useContext, useState, useCallback } from 'react';
import Alert from './Alert';

// Alert Context
const AlertContext = createContext();

// Provider Component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    header: '',
    message: '',
    onConfirm: null,
    btnText: '',
  });

  const prompt = useCallback((header, message, onConfirm, btnText) => {
    setAlert({ header, message, onConfirm, btnText });
  }, []);
  return (
    <AlertContext.Provider value={{ prompt }}>
      {alert.message && (
        <Alert
          alert={alert}
          onConfirm={() => {
            if (alert.onConfirm) alert.onConfirm();
            // Clear the alert message after handling confirm
            setAlert({ header: '', message: '', onConfirm: null, btnText: '' });
          }}
          onCancel={() => {
            // Clear the alert message on cancel
            setAlert({ header: '', message: '', onConfirm: null, btnText: '' });
          }}
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};

// Hook to use notification
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider');
  }
  return context;
};
