import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import Logo from '../../../images/test-logo.png';

import { XMarkIcon } from '@heroicons/react/24/outline';
import MainNavHeader from './MainNavHeader';

const MainMobileNav = ({
  navigation,
  teams,
  footerNav,
  openMenu,
  onMenuClose,
}) => {
  const [open, setOpen] = useState(openMenu);

  useEffect(() => {
    setOpen(openMenu);
  }, [openMenu]);

  const handleClose = () => {
    setOpen(false);
    // Invoke the callback function when the panel is closed
    onMenuClose();
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <div className="w-full px-1 py-2 flex">
                    <img
                      className="h-8 w-auto"
                      src={Logo}
                      alt="Dailey Project"
                    />
                    <MainNavHeader />
                  </div>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            {!item.children ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                  'group flex gap-x-3 rounded-md p-2 text-[.8125rem] leading-5 font-semibold'
                                )}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            ) : (
                              <Disclosure as="div">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        item.current
                                          ? 'bg-gray-800'
                                          : 'hover:text-white hover:bg-gray-800',
                                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-[.8125rem] leading-5 font-semibold text-gray-400'
                                      )}
                                    >
                                      <item.icon
                                        className="h-6 w-6 shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                      <ChevronRightIcon
                                        className={classNames(
                                          open
                                            ? 'rotate-90 text-white'
                                            : 'text-gray-400',
                                          'ml-auto h-5 w-5 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 px-2"
                                    >
                                      {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <Disclosure.Button
                                            as="a"
                                            href={subItem.href}
                                            className={classNames(
                                              subItem.current
                                                ? 'bg-gray-800'
                                                : 'hover:text-white hover:bg-gray-800',
                                              'block rounded-md py-2 pr-2 pl-9 text-[.8125rem] leading-5 text-gray-400'
                                            )}
                                          >
                                            {subItem.name}
                                          </Disclosure.Button>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-5 text-gray-400">
                        Your teams
                      </div>
                      <ul className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <Link
                              to={team.href}
                              className={classNames(
                                team.current
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-[.8125rem] leading-5 font-semibold'
                              )}
                            >
                              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <ul>
                        {footerNav.map((navItem, idx) => (
                          <li key={idx}>
                            <Link
                              to={navItem.href}
                              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-[.8125rem] font-semibold leading-5 text-gray-400 hover:bg-gray-800 hover:text-white"
                            >
                              <navItem.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {navItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MainMobileNav;
