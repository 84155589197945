const PaymentCardSubtotal = ({ lineItems, subtotal }) => {
  return (
    <div className="px-2 py-2 grid grid-cols-6 sm:gap-4 sm:px-0">
      <dt className="text-sm text-gray-900 col-span-1">Subtotal</dt>
      <dd className="text-sm leading-6 text-gray-700 mt-0 col-span-4">
        {lineItems.length} item
        {lineItems.length > 1 && '(s)'}
      </dd>
      <dd className="text-sm leading-6 text-gray-700 mt-0 flex justify-end col-span-1">
        ${subtotal.toFixed(2)}
      </dd>
    </div>
  );
};

export default PaymentCardSubtotal;
