import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import Input from '../../../../../shared/components/UIElements/Inputs/Input';
import Card from '../../../../../shared/components/UIElements/Card';

const Tags = ({ tags, handleSaveField, className }) => {
  const [newTag, setNewTag] = useState('');
  const [tagList, setTagList] = useState([]);

  // Convert the tags string to an array on component mount or when tags prop changes
  useEffect(() => {
    if (typeof tags === 'string') {
      const tagsArray = tags
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '');
      setTagList(tagsArray);
    } else {
      setTagList(tags || []);
    }
  }, [tags]);

  const onAddTag = (newTag) => {
    if (newTag && !tagList.includes(newTag)) {
      const updatedTags = [...tagList, newTag];
      setTagList(updatedTags);
      handleSaveField('tags', updatedTags.join(', '));
    }
  };

  const onRemoveTag = (indexToRemove) => {
    const updatedTags = tagList.filter((_, index) => index !== indexToRemove);
    setTagList(updatedTags);
    handleSaveField('tags', updatedTags.join(', '));
  };

  const handleChangeOnTagInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue.includes(',')) {
      const tagsToAdd = inputValue
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '' && !tagList.includes(tag));
      tagsToAdd.forEach((tag) => onAddTag(tag));
      setNewTag(''); // Reset the input field after adding tags
    } else {
      setNewTag(inputValue);
    }
  };

  const handleKeyPressOnTagInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const tagsToAdd = newTag
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '' && !tagList.includes(tag));
      tagsToAdd.forEach((tag) => onAddTag(tag));
      setNewTag('');
    }
  };

  return (
    <Card className={className}>
      <div className="text-[.8125rem]">
        <div className="flex items-center space-between mb-2">
          <span className="flex-1 font-semibold text-gray-900">Tags</span>
        </div>
        <Input
          label="Tags"
          labelsr="true"
          type="text"
          name="tags"
          id="tags"
          autoComplete="tags"
          className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          placeholder="Returned, Damaged, Reshipped"
          value={newTag}
          onChange={handleChangeOnTagInput}
          onKeyPress={handleKeyPressOnTagInput}
        />
        <div className="mt-2 flex flex-wrap">
          {tagList.map((tag, index) => (
            <span
              key={index}
              className="flex items-center bg-gray-200 rounded-full text-xs font-normal text-gray-700 mr-2 mb-2"
            >
              <span className="pl-3 pr-1 py-1">{tag}</span>
              <span
                onClick={() => onRemoveTag(index)}
                className="p-1 hover:cursor-pointer hover:bg-gray-300 hover:text-gray-900 rounded-full"
              >
                <XMarkIcon className="w-3 h-3" />
              </span>
            </span>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Tags;
