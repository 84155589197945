import { ApiRequest } from '../BaseApi';

export const getSpecificActivity = async ({
  page = 1,
  limit = 20,
  referenceId,
  referenceType,
} = {}) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/activity/log/specific`,
    params: {
      page,
      limit,
      referenceId,
      referenceType,
    },
  });
};
