import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleIcon, ClockIcon } from '@heroicons/react/24/solid';
import { PhotoIcon } from '@heroicons/react/24/solid';

const VariantItem = ({
  item,
  selectedItems,
  isSelected,
  onSelectToggle,
  committed,
  images,
}) => {
  const [itemsCommitted, setItemsCommitted] = useState(0);
  const [onHand, setOnHand] = useState(0);
  const [variantImage, setVariantImage] = useState('');
  const navigate = useNavigate();

  // const findImageByVariant = (item) => {
  //   // Convert variant.imageId to a string to ensure consistent comparison
  //   const variantImageId = String(item.image_id);

  //   // Find an image where either _id matches variantImageId or id matches variantImageId after both are converted to strings
  //   const image = images.find(
  //     (img) => img._id === variantImageId || String(img.id) === variantImageId
  //   );

  //   // Return cloudUrl if available, otherwise src, or an empty string if no image is found
  //   if (image) {
  //     return image.cloudUrl || image.src;
  //   }
  //   return ''; // Return an empty string if no image is found
  // };

  useEffect(() => {
    if (item && committed) {
      const sku = item?.sku;
      const committedQuantity = parseInt(committed[sku]) || 0;
      const onHandItems =
        parseInt(item?.inventory_quantity) - committedQuantity;
      setOnHand(onHandItems);
      setItemsCommitted(committedQuantity);
      setVariantImage(item.image);
    }
  }, [item, committed]);

  const handleTitleClick = (e) => {
    if (selectedItems.length > 0) {
      e.preventDefault(); // Prevent the link from navigating
      e.stopPropagation();
      onSelectToggle(); // Toggle the selection
    } else {
      const variantLink = `/products/${item.internal_data.pid}/variants/${item._id}`;
      navigate(variantLink);
    }
  };

  return (
    <tr
      className={`
      ${isSelected ? ' bg-gray-200/20' : ''}
      hover:bg-gray-200/20
    `}
    >
      <td
        className="relative px-4 sm:w-12 sm:px-6 cursor-pointer"
        onClick={(e) => {
          // This will check if the click is from the checkbox itself or its label
          if (e.target.type !== 'checkbox') {
            onSelectToggle(); // This toggles only when not clicking directly on the checkbox
          }
        }}
      >
        {isSelected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={item?._id}
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation(); // Stop the event from bubbling up to the td
            onSelectToggle(); // Toggle the selection
          }}
        />
      </td>
      <td
        className="whitespace-nowrap py-4 pl-4 pr-3 font-bold text-xs text-gray-900 sm:pl-0 cursor-pointer"
        onClick={handleTitleClick}
      >
        <div className={`flex items-center rounded-lg p-1`}>
          <div
            className={`h-11 w-11 flex-shrink-0 rounded-lg bg-gray-100 ring-1 ring-gray-400/40`}
          >
            {variantImage ? (
              <img
                className="h-11 w-11 rounded-full"
                src={item.image} // Using the findImageByVariant function to get the image URL
                alt={item?.title || 'Variant Image'} // Adding a dynamic alt text based on the variant's title
              />
            ) : (
              <div className="h-11 w-11 rounded-lg p-.5 flex justify-center items-center">
                <PhotoIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <div className="ml-4">
            <div className="text-[0.6125rem] text-gray-400">
              {item?.productTitle}
            </div>
            <div className="font-medium text-gray-900">{item?.title}</div>
            <div
              className={`inline-flex lg:hidden items-center rounded-md py-1 text-xs font-medium
              ${
                onHand < 0
                  ? 'ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/20 px-2' // Red background for negative inventory
                  : onHand === 0
                    ? '' // No background if onHand is exactly 0
                    : onHand > 0 && onHand <= 10
                      ? 'ring-1 ring-inset bg-yellow-50 text-yellow-700 ring-yellow-600/20 px-2' // Yellow background for low inventory (1-10)
                      : '' // Green background for sufficient inventory (>10)
              }`}
            >
              {item?.sku}
            </div>
            <div className="hidden text-xs font-light mt-1 text-gray-500 sm:block">
              {item?.internal_data?.pre_order > 0 &&
                item?.internal_data?.pre_order_date && (
                  <div
                    className={`inline-flex items-center rounded-md px-2 py-1 ring-1 ring-inset me-1 bg-gray-600/10 text-gray-700 ring-gray-600/20 gap-x-2`}
                  >
                    <ClockIcon className="h-4 w-4" />
                    <span>
                      {item?.internal_data?.pre_order_date?.split('T')[0]}
                    </span>
                  </div>
                )}
              {onHand < 0 || (onHand > 0 && onHand <= 10) ? (
                <span
                  className={`inline-flex items-center rounded-md px-2 py-1 ring-1 ring-inset ${
                    onHand < 0
                      ? 'bg-red-600/10 text-red-700 ring-red-600/20'
                      : 'bg-yellow-600/10 text-yellow-700 ring-yellow-600/20'
                  }`}
                >
                  <ExclamationCircleIcon className="h-4 w-4 me-1" />{' '}
                  {onHand < 0 ? 'Oversold' : 'Low'}
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="flex mt-2 flex-row gap-x-2 sm:hidden">
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                  item?.inventory_quantity < 0
                    ? 'bg-red-300 text-red-900 ring-red-600/20'
                    : item?.inventory_quantity === 0
                      ? 'bg-gray-100 text-gray-700 ring-gray-600/20'
                      : item?.inventory_quantity > 0 &&
                          item?.inventory_quantity <= 10
                        ? 'bg-yellow-100 text-yellow-700 ring-yellow-600/20'
                        : 'bg-green-100 text-green-700 ring-green-600/20'
                }`}
              >
                {item?.inventory_quantity}
              </span>
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-indigo-50 text-indigo-700 ring-indigo-600/20`}
              >
                {itemsCommitted}
              </span>
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                  onHand < 0
                    ? 'bg-red-50 text-red-700 ring-red-600/20'
                    : onHand === 0
                      ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
                      : onHand > 0 && onHand <= 10
                        ? 'bg-yellow-100 text-yellow-700 ring-yellow-600/20'
                        : 'bg-green-100 text-green-700 ring-green-600/20'
                }`}
              >
                {onHand}
              </span>
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20`}
              >
                {item?.internal_data?.pre_order}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium
        
        ${
          onHand < 0
            ? 'ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/20' // Red background for negative inventory
            : onHand === 0
              ? '' // No background if onHand is exactly 0
              : onHand > 0 && onHand <= 10
                ? 'ring-1 ring-inset bg-yellow-50 text-yellow-700 ring-yellow-600/20' // Yellow background for low inventory (1-10)
                : '' // Green background for sufficient inventory (>10)
        }`}
        >
          {item?.sku}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            item?.inventory_quantity < 0
              ? 'bg-red-300 text-red-900 ring-red-600/20'
              : item?.inventory_quantity === 0
                ? 'bg-gray-100 text-gray-700 ring-gray-600/20'
                : item?.inventory_quantity > 0 && item?.inventory_quantity <= 10
                  ? 'bg-yellow-100 text-yellow-700 ring-yellow-600/20'
                  : 'bg-green-100 text-green-700 ring-green-600/20'
          }`}
        >
          {item?.inventory_quantity}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20`}
        >
          {itemsCommitted}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            onHand < 0
              ? 'bg-red-50 text-red-700 ring-red-600/20'
              : onHand === 0
                ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
                : onHand > 0 && onHand <= 10
                  ? 'bg-yellow-100 text-yellow-700 ring-yellow-600/20'
                  : 'bg-green-100 text-green-700 ring-green-600/20'
          }`}
        >
          {onHand}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 sm:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-indigo-50 text-indigo-700 ring-indigo-600/20`}
        >
          {item?.internal_data.pre_order}
        </span>
      </td>
    </tr>
  );
};

export default VariantItem;
