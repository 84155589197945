import { Fragment, useState } from 'react';
import { Popover, Listbox, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  XCircleIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/solid';
import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const callsToAction = [
  {
    name: 'Cancel',
    action: 'cancel',
    icon: XCircleIcon,
    classes:
      'rounded-bl-3xl text-gray-400 bg-gray-100 border-t border-gray-200 hover:border-gray-400 hover:bg-gray-400 hover:text-white',
  },
  {
    name: 'Save',
    action: 'save',
    icon: CheckCircleIcon,
    classes:
      'rounded-br-3xl text-indigo-200 bg-indigo-900 border-t border-indigo-200 hover:bg-indigo-600 hover:text-white',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const reasons = [
  { name: 'Correction' },
  { name: 'Count' },
  { name: 'Received' },
  { name: 'Return restock' },
  { name: 'Damaged' },
  { name: 'Donation' },
];

const InventoryFlyOut = ({ quantity, name, handleChange, children }) => {
  const [selected, setSelected] = useState(reasons[0]);
  const [adjustment, setAdjustment] = useState(0);
  const [newQuantity, setNewQuantity] = useState(quantity);
  const [open, setOpen] = useState(false);

  const handleAdjustmentChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setAdjustment(value);
    setNewQuantity(quantity + value);
  };

  const handleSave = () => {
    const eventTarget = {
      target: {
        name: name.includes('internal_data.')
          ? `variants.0.internal_data.${name.split('.')[1]}`
          : `variants.0.${name}`,
        value: newQuantity,
      },
    };

    handleChange(eventTarget);
    setOpen(false);
  };

  const handleCancel = () => {
    setAdjustment(0);
    setNewQuantity(quantity);
    setOpen(false);
  };

  return (
    <Popover className="relative" onClose={() => setOpen(false)}>
      <Popover.Button
        className="inline-flex items-center gap-x-1 text-[.8125rem] font-semibold leading-5 text-gray-400 underline-offset-4 hover:text-gray-900 hover:underline"
        onClick={() => setOpen(true)}
      >
        <div>{children}</div>
        <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        show={open}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-50 mb-5 flex w-screen max-w-max -translate-x-1/2 px-4 bottom-full">
          <div className="w-screen max-w-xs flex-auto overflow-visible rounded-3xl bg-white text-[.8125rem] leading-5 shadow-lg ring-1 ring-gray-900/15">
            <div className="p-4">
              <div className="group relative flex gap-x-6 rounded-lg p-4 text-left">
                <div className="w-1/2">
                  <Input
                    label="Adjust by"
                    type="number"
                    name="adjust"
                    id="adjust"
                    value={adjustment}
                    min="0"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                    onChange={handleAdjustmentChange}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    label="New"
                    disabled
                    type="number"
                    name={name}
                    id={name}
                    value={newQuantity}
                    min="0"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  />
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 text-left">
                <div className="w-full">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-[.8125rem] font-medium leading-5 text-gray-900">
                          Reason
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5">
                            <span className="block truncate">
                              {selected.name}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[.8125rem]">
                              {reasons.map((reason, idx) => (
                                <Listbox.Option
                                  key={idx}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'bg-indigo-600 text-white'
                                        : 'text-gray-900',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={reason}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {reason.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-white rounded-bl-3xl rounded-br-3xl">
              {callsToAction.map((item) => (
                <button
                  key={item.name}
                  type="button"
                  className={`flex items-center justify-center gap-x-2.5 p-3 font-semibold ${item.classes}`}
                  onClick={item.action === 'save' ? handleSave : handleCancel}
                >
                  <item.icon className="h-5 w-5 flex-none" aria-hidden="true" />
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default InventoryFlyOut;
