import { PhotoIcon } from '@heroicons/react/24/solid';

const ImageThumbnail = ({ image, alt, w = 'w-16', h = 'h-16', className }) => {
  return (
    <div
      className={`${w} ${h} ${className} bg-gray-50 rounded-lg ring-1 ring-gray-900/20`}
    >
      {image ? (
        <img src={image} alt={alt} />
      ) : (
        <div
          className={`${w} ${h}  ${className} rounded-lg p-.5 flex justify-center items-center`}
        >
          <PhotoIcon className={`w-2/3 text-gray-400`} aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export default ImageThumbnail;
