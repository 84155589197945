import { PlusIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import Button from '../Button';

const Placeholder = ({ title, message, buttonText, href }) => {
  return (
    <Link to={href}>
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-[.8125rem] font-semibold text-gray-900">
            {title}
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">{message}</p>
          <div className="mt-6">
            <Button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-[.8125rem] font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Placeholder;
