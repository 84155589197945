import { Link } from 'react-router-dom';
import Badge from '../../../../shared/components/UIElements/Badge';

const IntegrationItem = ({ integration }) => {
  return (
    <Link to={`/settings/integrations/edit/${integration?._id}`}>
      <div
        className={`relative bg-gray-900/5 rounded-xl ring-2 ring-inset p-8 sm:p-10 ${integration?.active ? 'ring-gray-900' : 'ring-gray-900/20'}`}
      >
        <img
          className="max-h-12 w-full object-contain rounded-xl"
          src={integration?.image}
          alt={integration?.name}
          width={158}
          height={48}
        />
        {integration?.active && (
          <Badge className="bg-gray-900 text-white absolute right-2 top-2 ring-1 ring-gray-600/20 ring-inset">
            Active
          </Badge>
        )}
      </div>
    </Link>
  );
};

export default IntegrationItem;
