import Card from '../../../../../../shared/components/UIElements/Card';
import PriceInput from '../../../../../../shared/components/UIElements/Inputs/PriceInput';
import Checkbox from '../../../../../../shared/components/UIElements/Checkbox';

const ProductPricing = ({ handleChange, variant }) => {
  return (
    <Card className="mt-4">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Pricing
        </h2>
        <div className="flex gap-x-4 mt-4">
          <div className="md:w-1/2">
            <PriceInput
              label="Price"
              name="variants.0.price"
              id="variants.0.price"
              value={variant.price}
              onChange={handleChange}
              remove_currency="false"
            />
          </div>
          <div className="md:w-1/2">
            <PriceInput
              label="Compare-at price"
              name="variants.0.compare_at_price"
              id="variants.0.compare_at_price"
              value={variant.compare_at_price}
              onChange={handleChange}
              remove_currency="false"
            />
          </div>
        </div>
        <div className="mt-4">
          <Checkbox
            id="taxes"
            name="variants.0.taxes"
            text="Charge tax on this product"
          />
        </div>
      </div>
    </Card>
  );
};

export default ProductPricing;
