import React, { useState } from 'react';

import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import PageHeader from '../../../shared/components/UIElements/PageHeader';

const CreateOrder = () => {
  const [items, setItems] = useState(null);

  return !items ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : (
    <Container>
      <PageHeader pageTitle="Create Order" />
      <div className="mt-4 flow-root">
        <div className="-my-2 overflow-x-hidden">
          <div className="inline-block min-w-full py-2 align-middle"></div>
        </div>
      </div>
    </Container>
  );
};

export default CreateOrder;
