import { InboxStackIcon, TagIcon } from '@heroicons/react/24/outline';
// import { InboxStackIcon, TagIcon } from '@heroicons/react/20/solid';

import ToolTip from '../../../../../../shared/components/UIElements/ToolTip';
import Badge from '../../../../../../shared/components/UIElements/Badge';
import { usePopoutNotification } from '../../../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { copyToClipboard } from '../../../../../../utils/CopyToClipboard';

const ItemDataGrid = ({ fulfillmentData }) => {
  const { notify } = usePopoutNotification();

  return (
    <div className="px-4 pb-4">
      <div className="flex flex-col gap-y-4 w-full lg:w-1/2">
        <div className="p-2 rounded-md bg-gray-500/5 ring-1 ring-gray-200 w-full">
          <p className="text-['.8125']">Fulfillment Data</p>
          <div className="flex py-0 flex-row items-center gap-x-2 justify-between sm:justify-normal">
            <div className="mt-2 flex gap-x-2 items-center text-gray-400">
              <ToolTip content="Copy Fulfillment Order ID">
                <div
                  onClick={() => copyToClipboard(fulfillmentData.id, notify)}
                >
                  <Badge className="bg-gray-900/20 text-gray-600 !rounded-full !p-1.5">
                    <InboxStackIcon className="w-3 h-3" />
                  </Badge>
                </div>
              </ToolTip>
              <div className="text-[0.75rem]">{fulfillmentData.id}</div>
            </div>
            <div className="mt-2 flex gap-x-2 items-center text-gray-400">
              <ToolTip content="Copy Fulfillment Item ID">
                <div
                  onClick={() =>
                    copyToClipboard(fulfillmentData.item_id, notify)
                  }
                >
                  <Badge className="bg-gray-900/20 text-gray-600 !rounded-full !p-1.5">
                    <TagIcon className="w-3 h-3" />
                  </Badge>
                </div>
              </ToolTip>
              <div className="text-[.75rem]">{fulfillmentData.item_id}</div>
            </div>
          </div>
        </div>
        {fulfillmentData.prev &&
          fulfillmentData.prev.id !== fulfillmentData.id && (
            <div className="p-2 rounded-md bg-gray-500/5 ring-1 ring-gray-200 w-full">
              <p className="text-['.8125']">Prev Fulfillment Data</p>
              <div className="flex py-0 flex-row items-center gap-x-2">
                <div className="mt-2 flex gap-x-2 items-center text-gray-400">
                  <ToolTip content="Copy Fulfillment Order ID">
                    <div
                      onClick={() =>
                        copyToClipboard(fulfillmentData.prev.id, notify)
                      }
                    >
                      <Badge className="bg-gray-900/20 text-gray-600 !rounded-full !p-1.5">
                        <InboxStackIcon className="w-3 h-3" />
                      </Badge>
                    </div>
                  </ToolTip>
                  <div className="text-[0.75rem]">
                    {fulfillmentData.prev.id}
                  </div>
                </div>
                <div className="mt-2 flex gap-x-2 items-center text-gray-400">
                  <ToolTip content="Copy Fulfillment Item ID">
                    <div
                      onClick={() =>
                        copyToClipboard(fulfillmentData.prev.item_id, notify)
                      }
                    >
                      <Badge className="bg-gray-900/20 text-gray-600 !rounded-full !p-1.5">
                        <TagIcon className="w-3 h-3" />
                      </Badge>
                    </div>
                  </ToolTip>
                  <div className="text-[0.75rem]">
                    {fulfillmentData.prev.item_id}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ItemDataGrid;
