import React from 'react';
import UserItem from './UserItem';
import BasicTable from '../../../shared/components/UIElements/Tables/BasicTable';

const UsersList = ({ items }) => {
  const tableHeaders = () => (
    <tr>
      <th
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900"
      >
        {' '}
        Name
      </th>
      <th
        scope="col"
        className=" hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Created at
      </th>
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
      >
        Status
      </th>
      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-4">
        <span className="sr-only">Edit</span>
      </th>
    </tr>
  );

  return (
    <BasicTable
      items={items}
      tableHeaders={tableHeaders}
      RowComponent={UserItem}
    />
  );
};

export default UsersList;
