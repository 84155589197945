import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { createUser } from '../../../utils/Api/Users/Create';
import { updateUser } from '../../../utils/Api/Users/Update';
import { deleteUser } from '../../../utils/Api/Users/Delete';
import { onUploadImage } from '../../../utils/Api/Content/Upload';

import Button from '../../../shared/components/UIElements/Button';
import BtnLoadingSpinner from '../../../shared/components/UIElements/BtnLoadingSpinner';
import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useAlert } from '../../../shared/components/UIElements/Alerts/AlertProvider';
import Card from '../../../shared/components/UIElements/Card';

const UserForm = ({
  firstName = '',
  lastName = '',
  email = '',
  password = '',
  status = '',
  userId = '',
  image = '',
  enableDelete = true,
}) => {
  const navigate = useNavigate();
  const { notify } = usePopoutNotification();
  const [notification, setNotification] = useState(null);
  const clearNotification = () => setNotification(null);
  const [imageUploading, setImageUploading] = useState(false);
  const { prompt } = useAlert();

  const fileInputRef = useRef(null);

  // State for form fields
  const initialFormData = {
    firstName,
    lastName,
    email,
    password,
    status,
    image,
  };

  // Update state when props change
  useEffect(() => {
    setFormData({ firstName, lastName, password, email, status, image });
    // This will also reset `isChanged` since the form is now synced with new props
    setIsChanged(false);
  }, [firstName, lastName, email, status, password, image]);

  useEffect(() => {
    if (notification) {
      notify(notification); // Use the notify function from your notification system
      clearNotification();
    }
  }, [notification, notify]);

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if form is currently submitting
  const [isChanged, setIsChanged] = useState(false); // Track if form data has changed

  // Update form data state upon input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newData = { ...prevFormData, [name]: value };
      // Check if form data is different from initial data to enable/disable the save button
      setIsChanged(JSON.stringify(newData) !== JSON.stringify(initialFormData));
      return newData;
    });
  };

  const removeUser = async (userId) => {
    try {
      const response = await deleteUser(userId);
      notify('Successfully deleted!', response.data.message, 'success');
      setIsSubmitting(false);
      setIsChanged(false);
      navigate('/users');
    } catch (error) {
      notify('Error!', error.response.data.message, 'error');
    }
  };

  const handleDeleteUser = () => {
    prompt(
      'Confirm Delete',
      'Are you sure you want to delete this user? This action cannot be undone.',
      () => removeUser(userId),
      'Delete'
    );
  };

  const updateUserData = async (userData, userId) => {
    try {
      const response = await updateUser(userData, userId);
      notify('Successfully saved!', response.data.message, 'success');
      setIsSubmitting(false);
      setIsChanged(false);
    } catch (error) {
      console.error(error);
      notify('Error!', error.response.data.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const registerUser = async (userData) => {
    try {
      const response = await createUser(userData);
      notify('Successfully created!', response.data.message, 'success');
      setIsSubmitting(false);
      setIsChanged(false);
      navigate('/users');
    } catch (error) {
      notify('Error!', error.response.data.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsSubmitting(true);

    const userData = {
      ...formData,
    };
    if (userId && userId !== '') {
      updateUserData(userData, userId);
    } else {
      registerUser(userData);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      notify('Error!', 'No file selected', 'error');
      return;
    }

    setImageUploading(true);

    const uploadResult = await onUploadImage(file);

    if (uploadResult.success) {
      setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          image: uploadResult.data.cloudUrl,
        };
        setIsChanged(
          JSON.stringify(prevFormData) !== JSON.stringify(updatedFormData)
        );
        return updatedFormData;
      });
      notify('Success!', 'Image uploaded successfully', 'success');
    } else {
      notify('Error!', uploadResult.message, 'error');
    }
    setImageUploading(false);
  };

  return (
    <div className="w-full mx-auto mt-6">
      <div className="w-full md:w-3/4 mx-auto">
        <form onSubmit={handleSubmit}>
          <Card>
            <div className="space-y-6px-4 py-6 sm:p-6">
              <div>
                <h3 className="text-base font-semibold leading-5 text-gray-900">
                  Profile
                </h3>
                <p className="mt-1 text-[.8125rem] text-gray-500">
                  Choose a photo.
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  {/* <label className="block text-[.8125rem] font-medium leading-5 text-gray-900">Photo</label> */}
                  <div className="mt-2 flex items-center">
                    <span className="flex h-24 w-24 overflow-hidden items-center justify-center rounded-full bg-gray-100">
                      {imageUploading ? (
                        <BtnLoadingSpinner className="w-10 h-10 m-auto text-indigo-800" />
                      ) : formData.image ? (
                        <img
                          src={formData.image}
                          alt="User"
                          className="h-full w-full object-cover"
                        />
                      ) : (
                        <svg
                          className="h-24 w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange} // You'll implement this function
                    />
                    <button
                      type="button"
                      className="ml-5 rounded-md bg-white px-2.5 py-1.5 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Card className="mt-4">
            <div className="space-y-6 px-4 py-6 sm:p-6">
              <div>
                <h3 className="text-base font-semibold leading-5 text-gray-900">
                  Account Information
                </h3>
                <p className="mt-1 text-[.8125rem] text-gray-500">
                  Update users account information.
                </p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block text-[.8125rem] font-medium leading-5 text-gray-900"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="first-name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block text-[.8125rem] font-medium leading-5 text-gray-900"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="last-name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-[.8125rem] font-medium leading-5 text-gray-900"
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block text-[.8125rem] font-medium leading-5 text-gray-900"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                  />
                </div>
              </div>
            </div>
          </Card>

          <Card className="mt-4">
            <div className="space-y-6 px-4 py-6 sm:p-6">
              <div>
                <h3 className="text-base font-semibold leading-5 text-gray-900">
                  Account Status
                </h3>
                <p className="mt-1 text-[.8125rem] text-gray-500">
                  Activate or deactivate an account.
                </p>
              </div>

              <fieldset className="mt-3">
                <div className="space-y-4">
                  <div className="flex items-center">
                    <input
                      id="active"
                      name="status"
                      type="radio"
                      value="Active"
                      onChange={handleInputChange}
                      required
                      checked={formData.status === 'Active'}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label htmlFor="active" className="ml-3">
                      <span className="block text-[.8125rem] font-medium leading-5 text-gray-900">
                        Active
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="inactive"
                      name="status"
                      type="radio"
                      value="Inactive"
                      required
                      onChange={handleInputChange}
                      checked={formData.status === 'Inactive'}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label htmlFor="inactive" className="ml-3">
                      <span className="block text-[.8125rem] font-medium leading-5 text-gray-900">
                        Inactive
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </Card>
          <div
            className={`mt-6 flex items-center flex-row w-full ${!userId ? 'justify-end' : 'justify-between'}`}
          >
            {userId && enableDelete && (
              <div className="justify-start gap-x-6">
                <Button
                  type="button"
                  className="text-[.8125rem] font-semibold bg-red-600 hover:!bg-red-400"
                  onClick={handleDeleteUser}
                >
                  Delete
                </Button>
              </div>
            )}
            <div className="justify-self-end gap-x-6">
              {isSubmitting && (
                <BtnLoadingSpinner className="w-4 h-4 mr-3 text-indigo-700" />
              )}
              <Link to="/users">
                <Button
                  type="button"
                  className="text-[.8125rem] font-semibold bg-white !text-gray-900 hover:!bg-gray-200"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                className="ml-2 rounded-md text-[.8125rem] font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={!isChanged || isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
