import { PhotoIcon } from '@heroicons/react/24/solid';

const VariantTableImage = ({ variant, findImageSrcById, onImageChange }) => {
  const src = findImageSrcById(variant.image_id);
  return (
    <div
      className="h-11 w-11 flex-shrink-0 rounded-lg bg-gray-100 ring-1 ring-gray-200 hover:bg-gray-200 hover:ring-gray-300 cursor-pointer"
      onClick={onImageChange}
    >
      {src ? (
        <img
          className="h-11 w-11 rounded-lg p-.5"
          src={src}
          alt={variant.title}
        />
      ) : (
        <div className="h-11 w-11 rounded-lg p-.5 flex justify-center items-center">
          <PhotoIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export default VariantTableImage;
