import { Fragment } from 'react';
import MergeList from './MergeList';
import ContentAlert from '../../../../../../../../shared/components/UIElements/Notifications/ContentAlert/ContentAlert';

const alert = {
  message: 'Merging items will inherit the new fulfillment details.',
  type: 'warning',
};

const MergeForm = ({ allItems, selectedItem, mergeItemId, setMergeItemId }) => {
  const itemToMerge = selectedItem[0];

  return (
    <Fragment>
      <div className="flex flex-col py-4">
        <div className="block text-[.8125rem] font-medium leading-5 text-gray-900">
          Merge
        </div>
        <div className="block">
          <p className="text-[.8125rem] font-normal leading-5 text-gray-500">
            Selected item will be merged with the existing fulfillment for the
            selected item below.
          </p>
        </div>
      </div>
      <MergeList
        items={allItems}
        selected={itemToMerge}
        mergeItemId={mergeItemId} // Pass the merge item ID to MergeList
        onMergeItemSelected={setMergeItemId} // Pass the selected merge item ID to the parent component
      />
      <ContentAlert contentAlert={alert} className="!p-2 !mt-4" />
    </Fragment>
  );
};

export default MergeForm;
