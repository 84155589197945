import Card from '../../../../../../shared/components/UIElements/Card';
import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const ProductIntegrations = ({ productId, placeholder }) => {
  return (
    <Card className="mt-4 overflow-visible">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          Product information
        </h2>
        <Input
          label="Product Id"
          type="text"
          name="product_id"
          id="product_id"
          autoComplete="product_id"
          disabled
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[.8125rem] sm:leading-5"
          placeholder={placeholder}
          value={productId}
        />
      </div>
    </Card>
  );
};

export default ProductIntegrations;
