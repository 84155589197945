import React from 'react';
import Badge from './Badge';
import BackButton from './BackButton';

const PageHeader = ({
  children,
  pageTitle,
  subTitle = '',
  labels = [],
  backButton = false,
  riskData = [],
}) => {
  return (
    <div className="sm:flex sm:items-center sm:gap-x-3">
      {backButton && (
        <div className="mb-3 sm:mb-0">
          <BackButton />
        </div>
      )}
      <div className="sm:flex sm:items-center flex-1">
        <div className="sm:flex-auto">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
              {pageTitle}
            </h1>
            {labels && (
              <div>
                {labels
                  .filter((label) => label !== null)
                  .map((label, idx) => (
                    <Badge
                      key={idx}
                      className="ml-2 bg-indigo-200 text-indigo-900 capitalize"
                    >
                      {label}
                    </Badge>
                  ))}
              </div>
            )}
          </div>

          {subTitle !== '' && (
            <p className="mt-2 text-[.8125rem] text-gray-500 font-medium">
              {subTitle}
            </p>
          )}
        </div>
      </div>
      {children && (
        <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center">
          {children}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
