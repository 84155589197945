import Checkbox from '../../../../../../shared/components/UIElements/Checkbox';

const SelectAllItems = ({ isAllSelected, isSomeSelected, handleSelectAll }) => {
  return (
    <div className="border-b-[1px] border-gray-900/20">
      <div className="flex p-4 gap-4 text-[.8125rem] ">
        <Checkbox
          id="selectAll"
          text="Select all"
          checked={isAllSelected}
          indeterminate={isSomeSelected && !isAllSelected}
          onChange={handleSelectAll}
        />
      </div>
    </div>
  );
};

export default SelectAllItems;
