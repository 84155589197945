import { useNavigate } from 'react-router-dom';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <span
      onClick={() => navigate(-1)}
      className="font-bold rounded-xl cursor-pointer w-12 h-12"
    >
      <ArrowLongLeftIcon className="w-8 h-8 hover:bg-gray-900/10 rounded-lg p-1" />
    </span>
  );
};

export default BackButton;
