import { useState } from 'react';

import Card from '../../../../../shared/components/UIElements/Card';
import Input from '../../../../../shared/components/UIElements/Inputs/Input';
import Dropdown from '../../../../../shared/components/UIElements/Dropdown';
import Divider from '../../../../../shared/components/UIElements/Divider';
import Checkbox from '../../../../../shared/components/UIElements/Checkbox';

const weightOptions = ['lb', 'oz', 'kg', 'g'];

const VariantShipping = ({ fields, handleChange }) => {
  const [requiresShipping, setRequiresShipping] = useState(
    fields?.requires_shipping || false
  );

  const handleRequiredShipping = () => {
    const updatedValue = !requiresShipping;
    setRequiresShipping(updatedValue);
    handleChange({
      target: {
        name: `requires_shipping`,
        value: updatedValue,
      },
    });
  };

  return (
    <Card className="mt-4">
      <div className="space-y-6 px-4 py-6 sm:p-6">
        <div>
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Shipping information
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Enter variant shipping details.
          </p>
        </div>
        <Checkbox
          text="Shipping required?"
          name={`requires_shipping`}
          id={`requires_shipping`}
          checked={requiresShipping}
          onChange={handleRequiredShipping}
          description={
            'Is this a physical product that requires shipping to the customer?'
          }
        />
        {requiresShipping && (
          <>
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-6 sm:col-span-3`}>
                <Input
                  label="Weight (oz)"
                  type="number"
                  name={`weight`}
                  value={fields?.weight || 0}
                  id="weight"
                  autoComplete="weight"
                  onChange={handleChange}
                  className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
                />
              </div>
              <div className={`col-span-6 sm:col-span-3`}>
                <Dropdown
                  label="Weight unit"
                  labelsr="true"
                  options={weightOptions}
                  baseSelection={weightOptions[0]} // This line is changed
                  onChange={(selectedValue) =>
                    handleChange({
                      target: {
                        name: `weight_unit`,
                        value: selectedValue,
                      },
                    })
                  }
                />
              </div>
            </div>
            <Divider />
            <div>
              <h3 className="text-base font-semibold leading-5 text-gray-900">
                Package information
              </h3>
              <p className="mt-1 text-[.8125rem] text-gray-500">
                Enter variant package details.
              </p>
            </div>
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-6 sm:col-span-3`}>
                <Input
                  label="Width (in)"
                  type="number"
                  name={`width`}
                  value={fields?.width || 0}
                  id="width"
                  autoComplete="width"
                  onChange={handleChange}
                  className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
                />
              </div>
              <div className={`col-span-6 sm:col-span-3`}>
                <Input
                  label="Height (in)"
                  type="number"
                  name={`height`}
                  value={fields?.height || 0}
                  id="height"
                  autoComplete="height"
                  onChange={handleChange}
                  className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
                />
              </div>
              <div className={`col-span-6 sm:col-span-3`}>
                <Input
                  label="Length (in)"
                  type="number"
                  name={`length`}
                  value={fields?.length || 0}
                  id="length"
                  autoComplete="length"
                  onChange={handleChange}
                  className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default VariantShipping;
