import React, { createContext, useContext, useState, useCallback } from 'react';
import PopoutNotification from './PopoutNotification';

// Notification Context
const PopoutNotificationContext = createContext();

// Provider Component
export const PopoutNotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    header: '',
    message: '',
    type: '',
  });

  const notify = useCallback((header, message, type = 'error') => {
    setNotification({ header, message, type });
    setTimeout(
      () => setNotification({ header: '', message: '', type: '' }),
      3000
    ); // Auto-clear notification
  }, []);

  return (
    <PopoutNotificationContext.Provider value={{ notify }}>
      {notification.message && (
        <PopoutNotification notification={notification} />
      )}
      {children}
    </PopoutNotificationContext.Provider>
  );
};

// Hook to use notification
export const usePopoutNotification = () => {
  const context = useContext(PopoutNotificationContext);
  if (!context) {
    throw new Error(
      'usePopoutNotification must be used within a PopoutNotificationProvider'
    );
  }
  return context;
};
