import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { AuthContext } from '../../../context/auth-context';
import UserIcon from '../../UIElements/UserIcon';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const HeaderProfile = () => {
  const auth = useContext(AuthContext);

  const userNavigation = [
    { name: 'Account settings', href: `/settings/account` },
    { name: 'Sign out', href: '#' },
  ];

  const getInitials = (userName, userLastName) => {
    return `${userName ? userName[0].toUpperCase() : ''}${userLastName ? userLastName[0].toUpperCase() : ''}`;
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        {/* {auth?.image ? (
          <div className="bg-gray-900/5 rounded-full h-11 w-11 ring-1 ring-gray-300">
            <img
              className="h-11 w-11 rounded-full"
              src={auth.image}
              alt={`${auth.userName} ${auth?.userLastName}`}
            />
          </div>
        ) : (
          <div className="h-11 w-11 flex-none rounded-full bg-gray-900/5 text-gray-500/70 flex items-center justify-center text-[.8125rem] font-semibold">
            {getInitials(auth?.userName, auth?.userLastName)}
          </div>
        )} */}

        <UserIcon user={auth} />

        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 text-[.8125rem] font-semibold leading-5 text-gray-900"
            aria-hidden="true"
          >
            {auth?.userName} {auth?.userLastName}
          </span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  to={item.href}
                  className={classNames(
                    active ? 'bg-gray-50' : '',
                    'block px-4 py-2 text-[.8125rem] text-gray-700'
                  )}
                  onClick={(e) => {
                    if (item.name === 'Sign out') {
                      e.preventDefault();
                      auth.logout();
                    }
                  }}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderProfile;
