import Button from '../Button';

const TableLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="-my-2 overflow-x-scroll">
        <div className="inline-block min-w-full align-middle px-1 py-1 sm:px-0 sm:py-2">
          <div className="overflow-x-auto block w-full ring-1 ring-inset ring-gray-700/20 rounded-2xl">
            <table className="min-w-full divide-y divide-gray-300 overflow-x-scroll">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900">
                    <div className="h-2.5 bg-gray-300 rounded-full w-24" />
                  </th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white ring-1 rounded-b-2xl ring-inset ring-gray-700/20">
                <tr>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-18" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-24" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-18" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-300 rounded-full w-12" />
                  </td>
                  <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
                    <div className="h-2.5 bg-gray-200 rounded-full w-12" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <nav className="flex items-center py-3" aria-label="Pagination">
          <div className="hidden sm:block">
            <div className="flex items-center w-full max-w-[360px]">
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full w-80"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-full"></div>
            </div>
          </div>
          <div className="flex flex-1 justify-center gap-x-2 sm:justify-end items-center">
            <Button
              className="relative inline-flex items-center px-3 py-2 h-8 w-14 bg-gray-300"
              disabled={true}
            />
            <Button
              className="relative inline-flex items-center px-3 py-2 h-8 w-14 bg-gray-300"
              disabled={true}
            />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TableLoader;
