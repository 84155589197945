import { ApiRequest } from '../BaseApi';

export const updateOrderField = async (
  orderId,
  field,
  value,
  selected,
  actionData = {}
) => {
  const data = {};
  const itemsSelected = selected;
  const actions = actionData;

  data[field] = value; // Dynamically set the field to update
  const apiRequest = ApiRequest({
    method: 'patch',
    endpoint: `/orders/${orderId}`,
    data: { data: data, items: itemsSelected, action: actions },
  });
  return apiRequest;
};
