import { ApiRequest } from '../BaseApi';

// Users
export const getUsers = async (page = 1, limit = 20) => {
  return ApiRequest({
    method: 'get',
    endpoint: '/users',
    params: { page, limit },
  });
};

export const getUser = async (id) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/users/${id}`,
  });
};
