import React, { useState, useEffect } from 'react';

import { updateIntegration } from '../../../../utils/Api/Integrations/Update';

import { useFormContext } from '../../../../shared/context/form-context';
import { usePopoutNotification } from '../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';

import Input from '../../../../shared/components/UIElements/Inputs/Input';
import { useParams } from 'react-router-dom';

const IntegrationForm = ({ integration, setItem }) => {
  let { id } = useParams();
  const { fields, handleChange, handleSubmit, isSubmitting } = useFormContext();
  const { notify } = usePopoutNotification();

  // Initialize local form state with fallbacks to ensure fields are always controlled
  const [formFields, setFormFields] = useState({
    key: fields.key || '',
    secret: fields.secret || '',
    token: fields.token || '',
    url: fields.url || '',
  });

  // Effect to update local state when context fields change
  useEffect(() => {
    setFormFields({
      key: fields.key || '',
      secret: fields.secret || '',
      token: fields.token || '',
      url: fields.url || '',
    });
  }, [fields]);

  // Modified handleChange to update local state instead of context directly
  const handleLocalChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    handleChange(e); // Continue to invoke context's change handler if needed
  };

  // Local submit function that wraps the context's handleSubmit
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      // Correctly map the form fields to the integration update object
      const integrationData = {
        ...integration,
        key: formFields.key, // Assuming `formFields` state holds the current form values
        secret: formFields.secret,
        token: formFields.token,
        url: formFields.url,
      };

      const updatedIntegration = await updateIntegration(id, integrationData);

      if (updatedIntegration.success) {
        notify('Success!', updatedIntegration.data.message, 'success');
        setItem(updatedIntegration.data.data);
      }
    } catch (error) {
      console.error('Failed to update product: ', error);
      notify('Error!', error.response?.data?.error || 'Unknown error', 'error');
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
      {integration.category === 'storefront' && (
        <div>
          <Input
            type="text"
            label="Store URL"
            id="url"
            name="url"
            value={formFields.url}
            onChange={handleLocalChange}
            autoComplete="off"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[.8125rem]"
          />
          {/* {errors.key && <p className="text-red-500 text-[.8125rem]">{errors.key}</p>} */}
        </div>
      )}
      <div>
        <Input
          type="text"
          label="API Key"
          id="key"
          name="key"
          value={formFields.key}
          onChange={handleLocalChange}
          autoComplete="off"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[.8125rem]"
        />
        {/* {errors.key && <p className="text-red-500 text-[.8125rem]">{errors.key}</p>} */}
      </div>
      <div>
        <Input
          type="password"
          label="API Secret"
          id="secret"
          name="secret"
          value={formFields.secret}
          onChange={handleLocalChange}
          autoComplete="new-password"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[.8125rem]"
        />
        {/* {errors.secret && <p className="text-red-500 text-[.8125rem]">{errors.secret}</p>} */}
      </div>
      <div>
        <Input
          type="password"
          label="API Token"
          id="token"
          name="token"
          value={formFields.token}
          onChange={handleLocalChange}
          autoComplete="new-password"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[.8125rem]"
        />
        {/* {errors.token && <p className="text-red-500 text-[.8125rem]">{errors.token}</p>} */}
      </div>
      <div>
        <Input
          type="text"
          label="API Version"
          id="apiVersion"
          name="apiVersion"
          value={formFields.apiVersion}
          onChange={handleLocalChange}
          autoComplete="apiVersion"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[.8125rem]"
        />
        {/* {errors.token && <p className="text-red-500 text-[.8125rem]">{errors.token}</p>} */}
      </div>
      <button
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-[.8125rem] font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Saving...' : 'Save API Details'}
      </button>
    </form>
  );
};

export default IntegrationForm;
