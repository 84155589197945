import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StatusArray = [{ name: 'Received' }, { name: 'Batched' }];

const StatusForm = ({ selectedStatus, onStatusChange }) => {
  const findStatusByName = (name) => {
    return StatusArray.find((status) => status.name === name) || StatusArray[0];
  };

  const [currentStatus, setCurrentStatus] = useState(
    findStatusByName(selectedStatus?.name)
  );

  useEffect(() => {
    // Update currentStatus when selectedStatus changes, matching by name
    if (selectedStatus) {
      setCurrentStatus(findStatusByName(selectedStatus.name));
    }
  }, [selectedStatus]);

  const onChange = (newStatus) => {
    setCurrentStatus(newStatus);
    onStatusChange(newStatus);
  };

  return (
    <div className="mt-4 mb-1">
      <Listbox value={currentStatus} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-[.8125rem] font-medium leading-5 text-gray-900">
              New status
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5">
                <span className="block truncate">{currentStatus.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[.8125rem]">
                  {StatusArray.map((status, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={status}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {status.name}
                          </span>
                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default StatusForm;
