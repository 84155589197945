import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../shared/context/auth-context';
import Button from '../../../shared/components/UIElements/Button';
import Input from '../../../shared/components/UIElements/Inputs/Input';
import BtnLoadingSpinner from '../../../shared/components/UIElements/BtnLoadingSpinner';
import { AuthLogin } from '../../../utils/Api/Auth/Login';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Note the change from `body` to `data` for sending the request payload
      const response = await AuthLogin(email, password);
      // Axios response object doesn't have an `ok` property. Instead, check for response status code.
      if (response.success) {
        auth.login(
          response.data.uid,
          response.data.token,
          response.data.userName,
          response.data.userLastName,
          response.data.image,
          response.data.expiresAt
        );
        navigate('/dashboard');
      } else {
        throw new Error('An error occurred');
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={submitHandler}>
        <div>
          <Input
            label="Email"
            name="email"
            id="email"
            autoComplete="email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-zinc-200 py-1.5 px-1.5 text-zinc-900 block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-zinc-500 sm:text-[.8125rem] sm:leading-5"
          />
        </div>
        <div>
          <Input
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="bg-zinc-200 py-1.5 px-1.5 text-zinc-900 block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-zinc-500 sm:text-[.8125rem] sm:leading-5"
          />
        </div>
        <div>
          <Button
            type="submit"
            className="flex w-full justify-center rounded-md px-3 py-1.5 text-[.8125rem] font-semibold leading-5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            {isLoading ? (
              <span className="flex items-center">
                <BtnLoadingSpinner className="w-4 h-4 mr-2 text-white" />{' '}
                <span>Logging in...</span>
              </span>
            ) : (
              'Login'
            )}
          </Button>
        </div>
        {/* Error Message */}
        {error && <div className="text-red-500 text-[.8125rem]">{error}</div>}
      </form>

      {/* <p className="mt-10 text-center text-[.8125rem] text-zinc-400">
          Not registered?{' '}
          <a href="/auth/register" className="font-semibold leading-5 text-indigo-400 hover:text-indigo-300">
            Register today!
          </a>
        </p> */}
    </div>
  );
}
