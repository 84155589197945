import React, { useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import JsonResponseWrapper from './utils/JsonResponseWrapper';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import { PopoutNotificationProvider } from './shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { AlertProvider } from './shared/components/UIElements/Alerts/AlertProvider';
import { DialogProvider } from './shared/components/UIElements/Dialog/DialogProvider';

import PageLoadingSpinner from './shared/components/UIElements/PageLoadingSpinner';
import MainHeader from './shared/components/Layout/Navigation/MainHeader';
import MainNavigation from './shared/components/Layout/Navigation/MainNavigation';
import { ActivityLogProvider } from './shared/context/logging-context';
import Footer from './shared/components/Layout/Footer';

import Login from './main/auth/pages/Login';
import NotFound from './main/404/404';

import Dashboard from './main/dashboard/pages/Dashboard';
import Orders from './main/orders/pages/Orders';
import EditOrder from './main/orders/pages/EditOrder';
import CreateOrder from './main/orders/pages/CreateOrder';

import Users from './main/users/pages/Users';
import CreateUser from './main/users/pages/CreateUser';
import EditUser from './main/users/pages/EditUser';

import Batches from './main/shipments/pages/Batches';
import Shipments from './main/shipments/pages/Shipments';

import Products from './main/products/pages/Products';
import EditProduct from './main/products/pages/EditProduct';
import CreateProduct from './main/products/pages/CreateProduct';
import VariantEdit from './main/variants/pages/EditVariant';
import Inventory from './main/inventory/pages/Inventory';
import Settings from './main/settings/pages/Settings';
import Account from './main/settings/pages/Account';
import Integrations from './main/settings/pages/Integrations';
import EditIntegration from './main/settings/pages/EditIntegration';
import ShopifyInstall from './utils/Api/Shopify/ShopifyInstall';
import ShopifyCallback from './utils/Api/Shopify/ShopifyCallback';

import { isShopifyInstallRequest } from './utils/Api/Shopify/ShopifyCheck';

const App = () => {
  const { userId, token, login, logout, userName, userLastName, image } =
    useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleMenuOpen = () => setOpenMenu(!openMenu);
  const handleMenuClose = () => setOpenMenu(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [token]);

  // Moved location detection inside a component wrapped by Router
  const HandleShopifyRedirect = () => {
    const location = useLocation(); // Ensure useLocation is inside Router
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      console.log(location.pathname);
      if (params.get('shop') && location.pathname != '/install') {
        window.location.href = `/install?${params.toString()}`;
      }
    }, [location]);

    return null;
  };

  let routes;
  if (!isLoading) {
    if (token) {
      routes = (
        <Fragment>
          <MainNavigation
            openMenu={openMenu}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
          />
          <div className="lg:pl-72 flex flex-col min-h-screen">
            <main className="bg-gray-100/50 flex flex-col flex-1 pb-8">
              <MainHeader
                openMenu={openMenu}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
              />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:id" element={<EditOrder />} />
                <Route path="/orders/create" element={<CreateOrder />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/create" element={<CreateProduct />} />
                <Route path="/products/:id" element={<EditProduct />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route
                  path="/products/:id/variants/:variantId"
                  element={<VariantEdit />}
                />
                <Route path="/users" element={<Users />} />
                <Route path="/users/create" element={<CreateUser />} />
                <Route path="/users/edit/:id" element={<EditUser />} />
                <Route path="/shipments" element={<Shipments />} />
                <Route path="/shipments/batches" element={<Batches />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/account" element={<Account />} />
                <Route
                  path="/settings/integrations"
                  element={<Integrations />}
                />
                <Route
                  path="/settings/integrations/edit/:id"
                  element={<EditIntegration />}
                />
                <Route path="/404" element={<NotFound />} />
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route path="/*" element={<Navigate to="/404" replace />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Fragment>
      );
    } else {
      routes = (
        <Fragment>
          <main className="h-full">
            <Routes>
              {/* Public Shopify OAuth routes */}
              <Route path="/install" element={<ShopifyInstall />} />
              <Route path="/callback" element={<ShopifyCallback />} />
              {/* Authentication routes */}
              <Route path="/auth/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </main>
        </Fragment>
      );
    }

    return (
      <PopoutNotificationProvider>
        <AlertProvider>
          <AuthContext.Provider
            value={{
              isLoggedIn: !!token,
              token,
              uid: userId,
              userName,
              userLastName,
              image,
              login,
              logout,
            }}
          >
            <DialogProvider>
              <ActivityLogProvider>
                <Router basename={process.env.PUBLIC_URL}>
                  <HandleShopifyRedirect />{' '}
                  {/* Detect Shopify install request */}
                  <JsonResponseWrapper>{routes}</JsonResponseWrapper>
                </Router>
              </ActivityLogProvider>
            </DialogProvider>
          </AuthContext.Provider>
        </AlertProvider>
      </PopoutNotificationProvider>
    );
  } else {
    return (
      <PopoutNotificationProvider>
        <AlertProvider>
          <AuthContext.Provider
            value={{
              isLoggedIn: !!token,
              token,
              uid: userId,
              userName,
              userLastName,
              image,
              login,
              logout,
            }}
          >
            <DialogProvider>
              <main className="flex items-center justify-center w-full h-full bg-gray-100/50">
                <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
              </main>
            </DialogProvider>
          </AuthContext.Provider>
        </AlertProvider>
      </PopoutNotificationProvider>
    );
  }
};

export default App;
