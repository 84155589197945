import { ApiRequest } from '../BaseApi';

export const ExportVariants = async (exportOption, additionalParams = {}) => {
  try {
    if (!exportOption) {
      throw new Error('No export option provided');
    }

    let endpoint = `/variants/export/csv?option=${exportOption}`;

    // Append additional parameters based on the export option
    if (exportOption === 'currentPage' && additionalParams.currentPage) {
      endpoint += `&currentPage=${additionalParams.currentPage}`;
    } else if (
      exportOption === 'searchResults' &&
      additionalParams.searchQuery
    ) {
      endpoint += `&searchQuery=${encodeURIComponent(additionalParams.searchQuery)}`;
    }

    const response = await ApiRequest({
      method: 'get',
      endpoint: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv',
      },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'variants.csv');
    document.body.appendChild(link);
    link.click();

    return { success: true };
  } catch (error) {
    console.error('Failed to export variants:', error);
    return { success: false, error };
  }
};
