import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';

import { FormatDateTime } from '../../../../shared/utils/FormatDateTime';
import Popover from '../../../../shared/components/UIElements/Popover';
import useOrderData from '../../../../shared/hooks/useOrderData';
import ToolTip from '../../../../shared/components/UIElements/ToolTip';

function getStatusClass(status) {
  const statuses = ['Shipped', 'Cancelled', 'Archived'];
  return statuses.includes(status) ? 'bg-gray-900/20' : '';
}

const OrderItem = ({ item, isSelected, onSelectToggle }) => {
  const { itemCount, itemsByFulfillmentOrder, orderStatus } = useOrderData(
    item?.line_items
  );

  const noteContent = (note) => {
    return note.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  };

  return (
    <tr className={`group ${getStatusClass(item?.status)}`}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 font-bold text-xs text-gray-900">
        <div className="flex gap-x-3">
          <Link
            to={`/orders/${item._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            {item?.name}
          </Link>
          {item.note && (
            <div>
              <ToolTip content={noteContent(item?.note)}>
                <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />
              </ToolTip>
            </div>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            orderStatus === 'Cancelled'
              ? 'bg-red-50 text-red-700 ring-red-600/20'
              : orderStatus === 'Shipped'
                ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
                : orderStatus === 'Partially shipped'
                  ? 'bg-amber-50 text-amber-700 ring-amber-600/20'
                  : orderStatus === 'On Hold' || orderStatus === 'Partial Hold'
                    ? 'bg-orange-50 text-orange-700 ring-orange-600/20'
                    : 'bg-indigo-50 text-indigo-700 ring-indigo-600/20'
          }`}
        >
          {orderStatus}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 lg:table-cell">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            item?.internal_status === 'Shipped'
              ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
              : item?.priority === 'Urgent'
                ? 'bg-red-50 text-red-700 ring-red-600/20'
                : item?.priority === 'Normal'
                  ? 'bg-blue-50 text-indigo-700 ring-indigo-600/20'
                  : 'bg-blue-50 text-indigo-700 ring-indigo-600/20' // Default case for other statuses
          }`}
        >
          {item?.priority}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-4 text-xs text-gray-500 lg:table-cell">
        {itemCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
        {FormatDateTime(item?.created_at)}
      </td>
    </tr>
  );
};

export default OrderItem;
