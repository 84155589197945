import React, { useState, useEffect } from 'react';
import { Country, State } from 'country-state-city';
import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const EditShippingAddress = ({ value, onChange }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setCountries(Country.getAllCountries());
    if (inputValue.country_code) {
      setStates(State.getStatesOfCountry(inputValue.country_code));
    }
  }, [inputValue.country_code]);

  // UseEffect to handle name combination when first_name or last_name changes
  useEffect(() => {
    const newName =
      `${inputValue.first_name || ''} ${inputValue.last_name || ''}`.trim();
    if (newName !== inputValue.name) {
      const updatedValue = { ...inputValue, name: newName };
      setInputValue(updatedValue);
      onChange(updatedValue); // Ensure this doesn't cause re-render issues
    }
  }, [
    inputValue.first_name,
    inputValue.last_name,
    inputValue.name,
    onChange,
    inputValue,
  ]); // Added inputValue.name in dependencies

  const handleChange = (e) => {
    const { name, value: newValue } = e.target;
    const updatedValue = { ...inputValue, [name]: newValue };
    setInputValue(updatedValue); // This sets the new value for the specific field
    onChange(updatedValue);
  };

  const handleCountryChange = (e) => {
    const isoCode = e.target.value;
    const countryName = countries.find((c) => c.isoCode === isoCode)?.name;
    const updatedValue = {
      ...inputValue,
      country_code: isoCode,
      country: countryName,
      province_code: '',
      province: '',
    };
    setInputValue(updatedValue);
    onChange(updatedValue);
    setStates(State.getStatesOfCountry(isoCode));
  };

  const handleProvinceChange = (e) => {
    const isoCode = e.target.value;
    const provinceName = states.find((s) => s.isoCode === isoCode)?.name;
    const updatedValue = {
      ...inputValue,
      province_code: isoCode,
      province: provinceName,
    };
    setInputValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <div className="sm:col-span-6">
        <label
          htmlFor="country"
          className="block text-[.8125rem] font-medium leading-5 text-gray-900 text-left"
        >
          Country
        </label>
        <div className="mt-2">
          <select
            key={inputValue?.country_code || 'country-select'}
            id="country"
            name="country"
            autoComplete="country-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            value={inputValue?.country_code || ''}
            onChange={handleCountryChange}
          >
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="sm:col-span-3">
        <Input
          label="First name"
          type="text"
          name="first_name"
          id="first_name"
          autoComplete="first_name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.first_name || ''}
          onChange={handleChange}
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          label="Last name"
          type="text"
          name="last_name"
          id="last_name"
          autoComplete="last_name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.last_name || ''}
          onChange={handleChange}
        />
      </div>

      <div className="sm:col-span-6">
        <Input
          label="Company"
          id="company"
          name="company"
          type="text"
          autoComplete="company"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.company || ''}
          onChange={handleChange}
        />
      </div>

      <div className="col-span-full">
        <Input
          label="Address"
          type="text"
          name="address1"
          id="address1"
          autoComplete="address1"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.address1 || ''}
          onChange={handleChange}
        />
      </div>

      <div className="col-span-full">
        <Input
          label="Apartment, suite, etc."
          type="text"
          name="address2"
          id="address2"
          autoComplete="address2"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.address2 || ''}
          onChange={handleChange}
        />
      </div>

      <div className="sm:col-span-3 sm:col-start-1">
        <Input
          label="City"
          type="text"
          name="city"
          id="city"
          autoComplete="city"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.city || ''}
          onChange={handleChange}
        />
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="province"
          className="text-left block text-[.8125rem] font-medium leading-5 text-gray-900"
        >
          State / Province
        </label>
        <div className="mt-2">
          <select
            key={value?.province_code || 'province-select'}
            id="province"
            name="province"
            autoComplete="province"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            value={inputValue?.province_code || ''}
            onChange={handleProvinceChange}
          >
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="sm:col-span-6">
        <Input
          label="ZIP / Postal code"
          type="text"
          name="zip"
          id="zip"
          autoComplete="zip"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
          value={inputValue?.zip || ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EditShippingAddress;
