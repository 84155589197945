// React Components
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Shared Components
import { FormProvider } from '../../../shared/context/form-context';
import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';

// Page Components
import VariantForm from '../components/VariantForm/VariantForm';

// API Components
import { getProduct } from '../../../utils/Api/Products/Get';
import { deleteVariants } from '../../../utils/Api/Variants/Delete';
import { getOrderedVariant } from '../../../utils/Api/Orders/Get';
import { updateVariant } from '../../../utils/Api/Variants/Update';
import ButtonDropdown from '../../../shared/components/UIElements/ButtonDropdown';

const VariantEdit = () => {
  // Globally used variables
  const { notify } = usePopoutNotification(); // Optional

  const formRef = useRef();
  const navigate = useNavigate();
  let { id, variantId } = useParams();
  console.log(id, variantId);
  const [item, setItem] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Custom variables for Variants
  const [product, setProduct] = useState(null);
  const [committed, setCommitted] = useState(null);
  const [availableInventory, setAvailableInventory] = useState(null);

  // Grab data effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await getProduct(id);
        let variantData;
        if (productData.data && productData.data.variants) {
          setProduct(productData.data);
          variantData = productData.data.variants.find(
            (variant) => variant._id === variantId
          );
          if (variantData) {
            setItem(variantData);
          } else {
            notify(
              'Error!',
              `Variant with ID ${variantId} not found.`,
              'error'
            );
          }
        } else {
          notify('Error!', `Product data is missing variants array.`, 'error');
        }
        if (variantData.sku) {
          const orderedData = await getOrderedVariant(variantData.sku);
          console.log(orderedData);
          if (
            orderedData.data &&
            variantData.sku in orderedData.data.ordered_skus
          ) {
            setCommitted(orderedData.data.ordered_skus[variantData.sku]);
            setAvailableInventory(
              variantData.inventory_quantity -
                orderedData.data.ordered_skus[variantData.sku]
            );
          } else {
            setCommitted(0); // Fallback if SKU is not found
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [notify, id, variantId]);

  // Form delete handler
  const handleDeleteVariant = () => {
    prompt(
      'Confirm Delete',
      'Are you sure you want to delete this variant? This action cannot be undone.',
      // () => deleteVar(variantId),
      async () => {
        try {
          const response = await deleteVariants(id);
          notify('Successfully deleted!', response.data.message, 'success');
          setIsSubmitting(false);
          navigate('/products/' + id);
        } catch (error) {
          notify('Error!', error.response.data.message, 'error');
        }
      },
      'Delete'
    );
  };

  // Form discard handler
  const handleDiscard = () => {
    navigate(-1); // Navigates back to the previous page
  };

  // Handle form submission
  const handleFormSubmit = async (formData) => {
    setIsSubmitting(true);
    try {
      const updatedProduct = await updateVariant(formData, formData._id);
      if (updatedProduct.success) {
        notify('Success!', updatedProduct.data.message, 'success');
        setItem(formData);
      } else {
        notify('Error!', updatedProduct.message, 'error');
      }
    } catch (error) {
      console.error('Failed to update product:', error);
      notify('Error!', error, 'error');
    }
    setIsSubmitting(false);
  };

  return !item ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : (
    <FormProvider initialFields={item || {}} onFormSubmit={handleFormSubmit}>
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <PageHeader pageTitle={`Edit Variant`} backButton={true}>
            <ButtonDropdown
              onDelete={handleDeleteVariant}
              onDiscard={handleDiscard}
              onSave={() => formRef.current.submitForm()}
            />
          </PageHeader>
          <div className="w-full grow lg:flex">
            <VariantForm
              ref={formRef}
              product={product}
              setProduct={setProduct}
              committed={committed}
              inventoryAvailable={availableInventory}
              onFormSubmit={handleFormSubmit}
            />
          </div>
        </div>
      </Container>
    </FormProvider>
  );
};

export default VariantEdit;
