import { ApiRequest } from '../BaseApi';

// ====================================
// Get all orders
export const getOrders = async (page = 1, limit = 20, searchQuery = '') => {
  return ApiRequest({
    method: 'get',
    endpoint: '/orders',
    params: { page, limit, searchQuery },
  });
};

// Get single order
export const getOrder = async (id) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/orders/${id}`,
  });
};

// ====================================
// For inventory
export const getOrderedProducts = async () => {
  return ApiRequest({
    method: 'get',
    endpoint: '/orders/items',
  });
};

export const getOrderedVariant = async (productSku) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/orders/item/${productSku}`,
  });
};
