import React, { useState, useEffect } from 'react';
import IntegrationOptionItem from './IntegrationOptionItem';
import { FormatUnderscore } from '../../../../shared/utils/FormatUnderscore';

const IntegrationOptions = ({ data }) => {
  const [groupedOptions, setGroupedOptions] = useState({});

  useEffect(() => {
    let groupServices;
    if (data.category === 'shipping') {
      groupServices = data.serviceTypes.reduce((acc, service) => {
        acc[service.carrier] = acc[service.carrier] || [];
        acc[service.carrier].push(service);
        return acc;
      }, {});
    } else if (data.category === 'storefront') {
      groupServices = data.webhooks.reduce((acc, webhook) => {
        acc[webhook.category] = acc[webhook.category] || [];
        acc[webhook.category].push(webhook);
        return acc;
      }, {});
    }

    setGroupedOptions(groupServices);
  }, [data]);

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-[.8125rem] font-medium text-gray-900">
        {data.category === 'shipping' ? 'Service Types' : 'Webhooks'}
      </dt>
      <dd className="mt-1 text-[.8125rem] leading-5 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
        {Object.entries(groupedOptions).map(([key, items], idx) => (
          <div
            key={key}
            className={`pt-4 pb-4 ${idx !== 0 && 'border-t border-gray-100'}`}
          >
            <h4 className="font-bold my-2 capitalize">
              {FormatUnderscore(key)}
            </h4>
            <div className="grid grid-cols-2 gap-y-2 gap-x-4">
              {items.map((item, idx) => (
                <IntegrationOptionItem
                  key={idx}
                  item={item}
                  integration={data}
                />
              ))}
            </div>
          </div>
        ))}
      </dd>
    </div>
  );
};

export default IntegrationOptions;
