import React, { useEffect } from 'react';

const ShopifyInstall = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shop = params.get('shop');
    const hmac = params.get('hmac');
    const timestamp = params.get('timestamp');
    const host = params.get('host');

    if (shop) {
      // Instead of using Axios, just redirect to the backend endpoint
      window.location.href = `https://api.daileyproject.com/api/shopify/install?hmac=${hmac}&shop=${shop}&timestamp=${timestamp}&host=${host}`;
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <p>Processing Shopify installation...</p>
    </div>
  );
};

export default ShopifyInstall;
