import Card from '../../../../../../shared/components/UIElements/Card';
import ProductDescription from './ProductDescription';
import ProductTitle from './ProductTitle';

const ProductInformation = ({ title, bodyHtml, handleChange, errors }) => {
  return (
    <Card>
      <ProductTitle title={title} handleChange={handleChange} errors={errors} />
      <ProductDescription bodyHtml={bodyHtml} handleChange={handleChange} />
    </Card>
  );
};

export default ProductInformation;
