import { ClipboardIcon } from '@heroicons/react/24/outline';

import { usePopoutNotification } from '../UIElements/Notifications/Popout/PopoutNotificationProvider';
import { copyToClipboard } from '../../../utils/CopyToClipboard';

const CopyIcon = ({ data }) => {
  const { notify } = usePopoutNotification();

  return (
    <div className="p-1.5">
      <ClipboardIcon
        className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
        onClick={() => copyToClipboard(data, notify)}
      />
    </div>
  );
};

export default CopyIcon;
