// React components
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// API components
import { getOrders } from '../../../utils/Api/Orders/Get';

// Shared components
import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import Button from '../../../shared/components/UIElements/Button';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import TablePagination from '../../../shared/components/UIElements/Pagination/TablePagination';
import Placeholder from '../../../shared/components/UIElements/EmptyPlaceholder/Placeholder';

// Page components
import OrdersList from '../components/OrderTable/OrdersList';
import TableActions from '../../../shared/components/UIElements/Tables/components/TableActions';

const Orders = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState(null);
  const [limit, setLimit] = useState(20); // If you want to allow changing the number of items per page
  const [totalPages, setTotalPages] = useState(0); // Add state for total pages
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Add currentPage state
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isDatabaseEmpty, setIsDatabaseEmpty] = useState(false); // New state for empty check

  // Search the database
  useEffect(() => {
    // Debounce search query
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300); // Debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); // Reset to first page on new search
  };

  // Fetch initial data from server
  const fetchData = async (page = 1) => {
    try {
      const data = await getOrders(page, limit, debouncedSearchQuery);
      setItems(data.data.items);
      setTotalItems(data.data.totalItems);
      const calculatedTotalPages = Math.ceil(data.data.totalItems / limit);
      setTotalPages(calculatedTotalPages);
      // Only update isDatabaseEmpty once during initial load
      if (page === 1 && searchQuery === '' && data.data.items.length === 0) {
        setIsDatabaseEmpty(true);
      } else if (data.data.items.length > 0) {
        setIsDatabaseEmpty(false);
      }
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data whenever currentPage, limit, or debouncedSearchQuery changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit, debouncedSearchQuery]); // Use debounced search query here

  // Handle pagination
  const handlePageChange = (newPage) => {
    if (newPage > totalPages || newPage < 1) return;
    setCurrentPage(newPage);
  };

  const tableActions = {
    button: {
      name: 'Orders',
      label: 'Order',
      action: () => navigate('/orders/create'),
    },
    menu: [
      {
        name: 'Import',
        action: async () => {
          /* import logic */
        },
      },
      {
        name: 'Export',
        action: async () => {
          // await ExportVariants()
        },
      },
    ],
  };

  return items === null ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : isDatabaseEmpty ? (
    <Fragment>
      <Container>
        <PageHeader pageTitle="Orders" />
      </Container>
      <Container>
        <Placeholder
          title="No orders found"
          message="Get started by creating a order."
          buttonText="Create Order"
          to="/orders/create"
        />
      </Container>
    </Fragment>
  ) : (
    <Container>
      <PageHeader pageTitle="Orders">
        <TableActions actions={tableActions} />
      </PageHeader>
      <div className="mt-4 flow-root">
        <OrdersList orders={items} handleSearch={handleSearch} />
        <div className="mt-2">
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            limit={limit}
            onPageChange={handlePageChange}
            loading={loading}
            className="border-t-0"
          />
        </div>
      </div>
    </Container>
  );
};

export default Orders;
