import { Fragment, useState, useEffect } from 'react';

const RiskAnalysis = ({ riskData = {} }) => {
  const [risks, setRisks] = useState(riskData);
  useEffect(() => {
    setRisks(riskData);
  }, [riskData]);
  return (
    <Fragment>
      {risks?.riskLevel !== 'NONE' ? (
        <div className="flex items-center text-xs space-between mt-2">
          <div className="flex-1 mr-2 flex flex-col gap-x-2">
            <p className="font-semibold">Fraud analysis:</p>
            <ul>
              {risks?.messages?.map((risk, idx) => (
                <li className="break-all flex gap-x-2" key={idx}>
                  <span className="font-semibold">{idx + 1}.</span> {risk}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="flex items-center text-xs space-between mt-2">
          <div className="flex-1 mr-2 flex flex-col gap-x-2">
            <p className="font-semibold">Fraud analysis:</p>
            <p className="italic">No fraud risk found.</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default RiskAnalysis;
