import Button from '../Button';
import BtnLoadingSpinner from '../BtnLoadingSpinner';

const TablePagination = ({
  totalPages,
  limit,
  totalItems,
  currentPage,
  onPageChange,
  loading,
  className,
}) => {
  const startItem = (currentPage - 1) * limit + 1;
  const endItem = Math.min(startItem + limit - 1, totalItems); // Ensure it doesn't exceed totalItems

  const nextPage = () => {
    onPageChange((current) => Math.min(current + 1, totalPages)); // Use passed callback
  };

  const prevPage = () => {
    onPageChange((current) => Math.max(current - 1, 1)); // Use passed callback
  };
  return (
    <nav
      className={`flex items-center border-t border-gray-200 py-3 ${className}`}
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-[.8125rem] text-gray-700">
          Showing <span className="font-medium">{startItem}</span> to{' '}
          <span className="font-medium">{endItem}</span> of{' '}
          <span className="font-medium">{totalItems}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-center sm:justify-end items-center">
        {loading && (
          <BtnLoadingSpinner className="w-4 h-4 mr-3 text-indigo-700" />
        )}
        <Button
          onClick={prevPage}
          className="relative inline-flex items-center px-3 py-2"
          disabled={currentPage <= 1}
        >
          Previous
        </Button>
        <Button
          onClick={nextPage}
          className="relative ml-3 inline-flex px-3 py-2"
          disabled={currentPage >= totalPages || totalPages === 0}
        >
          Next
        </Button>
      </div>
    </nav>
  );
};

export default TablePagination;
