import { useState, useRef, useEffect } from 'react';
import { Popover } from '@headlessui/react';

const ToolTip = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState('right');
  const panelRef = useRef(null);
  const closeTimeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen && panelRef.current) {
      const { right, left } = panelRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (right > viewportWidth) {
        setPosition('left'); // Shift to left if overflow on the right
      } else if (left < 0) {
        setPosition('right'); // Shift to right if overflow on the left
      }
    }
  }, [isOpen]);

  const handleMouseEnter = () => {
    clearTimeout(closeTimeoutRef.current); // Clear any pending close timeouts
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    // Set a timeout to close the tooltip after a short delay
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200); // Adjust the delay as needed (200ms in this case)
  };

  return (
    <Popover className="relative">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="inline-block"
      >
        {children}
      </div>
      {isOpen && (
        <div
          ref={panelRef}
          className={`absolute z-10 p-2 text-nowrap bg-white border border-gray-200 rounded-md shadow-lg text-gray-400 ${
            position === 'right' ? 'left-full -ml-4' : 'right-full -mr-4'
          }`}
          onMouseEnter={handleMouseEnter} // Keep the tooltip open if hovered
          onMouseLeave={handleMouseLeave} // Close the tooltip after delay when leaving
        >
          {content}
        </div>
      )}
    </Popover>
  );
};

export default ToolTip;
