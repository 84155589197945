import React, { useState, useEffect } from 'react';
import { differenceInCalendarDays, parseISO } from 'date-fns';

import Card from '../../../../../shared/components/UIElements/Card';
import Input from '../../../../../shared/components/UIElements/Inputs/Input';
import Badge from '../../../../../shared/components/UIElements/Badge';
import Divider from '../../../../../shared/components/UIElements/Divider';
import InfoBubble from '../../../../../shared/components/UIElements/InfoBubble/InfoBubble';
import PreOrderEvent from './PreOrderEvent';
import PreOrderForm from './PreOrderForm';

const getBadgeProps = (inventoryAvailable) => {
  if (inventoryAvailable < 0) {
    return {
      text: 'Low',
      classes: 'bg-red-200/30 text-red-900 ring-1 ring-red-300/40',
      fill: 'fill-red-500',
    };
  } else if (inventoryAvailable <= 10) {
    return {
      text: 'Low',
      classes: 'bg-yellow-200/30 text-yellow-900 ring-1 ring-yellow-300/40',
      fill: 'fill-yellow-500',
    };
  } else {
    return null; // No badge for values above 10
  }
};

const VariantInventory = ({
  fields,
  committed,
  inventoryAvailable,
  handleChange,
}) => {
  const [showPreOrderCalendar, setShowPreOrderCalendar] = useState(false);
  const [daysUntilPreOrder, setDaysUntilPreOrder] = useState(
    fields?.internal_data?.pre_order_date || null
  );

  useEffect(() => {
    if (fields?.internal_data?.pre_order_date) {
      const preOrderDate = parseISO(fields.internal_data.pre_order_date);
      const today = new Date();
      const daysDifference = differenceInCalendarDays(preOrderDate, today);
      setDaysUntilPreOrder(daysDifference);
    }
  }, [fields?.internal_data?.pre_order_date]);

  return (
    <Card className="mt-4">
      <div className="space-y-6 px-4 py-6 sm:p-6">
        <div>
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Variant Inventory
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Enter variant inventory details.
          </p>
        </div>
        <div className="grid grid-cols-12 gap-6 items-center">
          <div className={`col-span-12 sm:col-span-3`}>
            <Input
              label="Inventory"
              type="number"
              name={`inventory_quantity`}
              id="inventory_quantity"
              badge={getBadgeProps(fields?.inventory_quantity)}
              autoComplete="inventory_quantity"
              value={fields?.inventory_quantity || 0}
              onChange={handleChange}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
            />
          </div>
          <div className={`col-span-12 sm:col-span-3`}>
            <Input
              label="Committed"
              type="number"
              name="committed"
              id="committed"
              autoComplete="committed"
              value={committed || 0}
              disabled={true}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-[.8125rem] sm:leading-5 opacity-50 cursor-not-allowed ring-gray-300 bg-gray-200 text-gray-900`}
            />
          </div>
          <div className={`col-span-12 sm:col-span-3`}>
            <Input
              label="Available"
              badge={getBadgeProps(inventoryAvailable)}
              type="number"
              name="after_orders"
              id="after_orders"
              autoComplete="after_orders"
              value={inventoryAvailable || 0}
              disabled={true}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-[.8125rem] sm:leading-5 opacity-50 cursor-not-allowed ring-gray-300 bg-gray-200 text-gray-900`}
            />
          </div>
          <div className={`col-span-12 sm:col-span-3`}>
            <Input
              label="Store Inventory"
              type="number"
              name={`store_inventory`}
              id="store_inventory"
              autoComplete="store_inventory"
              badge={getBadgeProps(fields?.internal_data.store_inventory || 0)}
              value={fields?.internal_data.store_inventory || 0}
              onChange={handleChange}
              disabled={true}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-[.8125rem] sm:leading-5 opacity-50 cursor-not-allowed ring-gray-300 bg-gray-200 text-gray-900`}
            />
          </div>
        </div>
        <Divider />
        <div className="flex flex-col items-start">
          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-3">
            <div className="flex flex-row gap-x-1 items-center">
              <h3 className="text-base font-semibold leading-5 text-gray-900">
                Pre-Order Inventory
              </h3>
              <InfoBubble details="Pre-order inventory will adjust your store inventory quantity" />
            </div>
            {daysUntilPreOrder !== null && (
              <Badge className="bg-indigo-200 text-indigo-900">
                <svg
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                  className={`h-1.5 w-1.5 fill-gray-500`}
                >
                  <circle r={3} cx={3} cy={3} />
                </svg>
                <span>Expected in {daysUntilPreOrder} days</span>
              </Badge>
            )}
          </div>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Enter variant pre-order inventory details.
          </p>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className={`col-span-12 sm:col-span-3`}>
            <Input
              label="Pre-Order"
              type="number"
              name={`internal_data.pre_order`}
              id="preOrder"
              autoComplete="preOrder"
              badge={getBadgeProps(fields?.internal_data.pre_order || 0)}
              value={fields?.internal_data.pre_order || 0}
              onChange={handleChange}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
            />
          </div>
          <div className={`col-span-12 sm:col-span-9 flex flex-col gap-3`}>
            <div className="flex flex-row gap-2 items-center">
              <label
                className={`block text-[.8125rem] font-medium text-gray-900`}
              >
                Pre-order Date
              </label>
            </div>
            <PreOrderEvent
              showPreOrderCalendar={showPreOrderCalendar}
              setShowPreOrderCalendar={setShowPreOrderCalendar}
              preOrderDate={fields?.internal_data?.pre_order_date || null}
              fields={fields}
              handleChange={handleChange}
              setDaysUntilPreOrder={setDaysUntilPreOrder}
            />
          </div>
        </div>
        {showPreOrderCalendar && (
          <PreOrderForm
            fields={fields}
            handleChange={handleChange}
            showPreOrderCalendar={showPreOrderCalendar}
            setShowPreOrderCalendar={setShowPreOrderCalendar}
          />
        )}
      </div>
    </Card>
  );
};

export default VariantInventory;
