import React from 'react';

import VariantItem from './VariantItem';
import SelectTable from '../../../shared/components/UIElements//Tables/SelectTable';

const VariantList = ({ variants, images, committed, handleSearch }) => {
  const tableHeaders = () => (
    <>
      <th
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900 sm:pl-0"
      >
        Title
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 lg:table-cell"
      >
        SKU
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Stock
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Sold
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Available
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Pre-Order
      </th>
    </>
  );

  return (
    <SelectTable
      items={variants}
      tableHeaders={tableHeaders}
      RowComponent={VariantItem}
      handleSearch={handleSearch}
      rowProps={{
        committed: committed,
        images: images,
      }}
    />
  );
};

export default VariantList;
