const Badge = ({ dot, className, children }) => {
  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 ${className}`}
    >
      {dot && (
        <svg className="h-1.5 w-1.5" viewBox="0 0 6 6" aria-hidden="true">
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {children}
    </span>
  );
};
export default Badge;
