import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

const InfoBubble = ({ details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover className="relative">
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className="flex items-center"
      >
        <Popover.Button as="div" className="focus:outline-none">
          <InformationCircleIcon className="w-4 h-auto text-gray-400 hover:text-gray-600 cursor-pointer" />
        </Popover.Button>

        {isOpen && (
          <Popover.Panel
            static
            className="absolute z-10 w-64 p-2 mt-2 top-5 text-sm text-white bg-gray-800 rounded-md shadow-lg transform -translate-x-1/2 left-1/2"
          >
            {details}
          </Popover.Panel>
        )}
      </div>
    </Popover>
  );
};

export default InfoBubble;
