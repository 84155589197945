import Card from '../../../../../../shared/components/UIElements/Card';
import Input from '../../../../../../shared/components/UIElements/Inputs/Input';
import Checkbox from '../../../../../../shared/components/UIElements/Checkbox';

import InventoryTable from './InventoryTable';

const ProductInventory = ({ handleChange, variant }) => {
  const handleInventoryChange = (event) => {
    handleChange({
      target: {
        name: 'variants.0.inventory_management',
        value: event.target.checked ? 'shopify' : null,
      },
    });
  };
  return (
    <Card className="mt-4 overflow-visible">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Inventory
        </h2>
        <div className="flex gap-x-4 mt-4">
          <div className="w-1/2">
            <Input
              type="text"
              name="variants.0.sku"
              id="variants.0.sku"
              autoComplete="sku"
              label="SKU"
              className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
              value={variant?.sku}
              onChange={handleChange}
            />
          </div>
          <div className="w-1/2">
            <Input
              type="text"
              name="variants[0].barcode"
              id="barcode"
              autoComplete="barcode"
              label="Barcode"
              className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
              value={variant?.barcode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mt-4">
          <Checkbox
            id="inventory_management"
            name="inventory_management"
            text="Manage inventory with Shopify"
            checked={variant.inventory_management === 'shopify'}
            onChange={handleInventoryChange}
          />
        </div>
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-visible sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <InventoryTable variant={variant} handleChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductInventory;
