import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const ProductTitle = ({ title, handleChange, errors }) => {
  return (
    <div>
      <Input
        type="text"
        name="title"
        id="title"
        autoComplete="title"
        placeholder="Short sleeve t-shirt"
        label="Title"
        className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
        value={title}
        error={errors?.title}
        onChange={handleChange}
      />
    </div>
  );
};

export default ProductTitle;
