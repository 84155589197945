// React components
import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// API components
import { getVariants } from '../../../utils/Api/Variants/Get';
import { getOrderedProducts } from '../../../utils/Api/Orders/Get';
import { ExportVariants } from '../../../utils/Api/Variants/Export';

// Shared components
import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import TablePagination from '../../../shared/components/UIElements/Pagination/TablePagination';
import Placeholder from '../../../shared/components/UIElements/EmptyPlaceholder/Placeholder';
import TableActions from '../../../shared/components/UIElements/Tables/components/TableActions';

// Page components
import VariantList from '../components/VariantList';

const Inventory = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState(null);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [committed, setCommitted] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isDatabaseEmpty, setIsDatabaseEmpty] = useState(false); // New state for empty check

  // Search the table
  useEffect(() => {
    // Debounce search query
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300); // Debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); // Reset to first page on new search
  };

  // Fetch initial data from server
  const fetchData = async (page = 1) => {
    setLoading(true);
    try {
      const data = await getVariants(page, limit, debouncedSearchQuery);
      console.log(`Fetched items for page ${page}:`, data.data.items.length);
      setItems(data.data.items);
      setTotalItems(data.data.totalItems);
      setProductImages(data.data.images);
      const calculatedTotalPages = Math.ceil(data.data.totalItems / limit);
      setTotalPages(calculatedTotalPages);
      // Only update isDatabaseEmpty once during initial load
      if (page === 1 && searchQuery === '' && data.data.items.length === 0) {
        setIsDatabaseEmpty(true);
      } else if (data.data.items.length > 0) {
        setIsDatabaseEmpty(false);
      }
    } catch (error) {
      console.error('Failed to fetch variants:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data whenever currentPage, limit, or debouncedSearchQuery changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit, debouncedSearchQuery]); // Use debounced search query here

  // Fetch ordered inventory
  useEffect(() => {
    const fetchOrderedData = async () => {
      try {
        const data = await getOrderedProducts();
        setCommitted(data.data.ordered_skus);
      } catch (error) {
        console.error('Failed to fetch ordered products:', error);
      }
    };
    fetchOrderedData();
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    if (newPage > totalPages || newPage < 1) return;
    setCurrentPage(newPage);
  };

  const tableActions = {
    button: {
      name: 'Inventory',
      label: 'Product',
      action: () => navigate('/products/create'),
    },
    menu: [
      {
        name: 'Import',
        action: () => {
          /* import logic */
        },
      },
      {
        name: 'Export',
        action: async (selectedOption) => await ExportVariants(selectedOption),
      },
    ],
  };

  return items === null ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : isDatabaseEmpty ? (
    <Fragment>
      <Container>
        <PageHeader pageTitle="Inventory" />
      </Container>
      <Container>
        <Placeholder
          title="No variants found"
          message="Create a product and add a variant or add variants to a product."
          buttonText="New Product"
          to="/products/create"
        />
      </Container>
    </Fragment>
  ) : (
    <Container>
      <PageHeader pageTitle="Inventory">
        <TableActions actions={tableActions} />
      </PageHeader>
      <div className="mt-4 flow-root">
        <VariantList
          variants={items}
          committed={committed}
          images={productImages}
          handleSearch={handleSearch} // Pass handleSearch to allow searching
        />
        <div>
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            limit={limit}
            onPageChange={handlePageChange}
            loading={loading}
            className="border-t-0"
          />
        </div>
      </div>
    </Container>
  );
};

export default Inventory;
