import axios from 'axios';
import queryString from 'query-string';
import Cookie from 'js-cookie';

export const ApiRequest = async ({
  method,
  endpoint,
  data = null,
  params = {},
  headers = {},
  requireAuth = true,
  responseType = 'json', // Default responseType to 'json'
}) => {
  let token;
  if (requireAuth) {
    const cookie = Cookie.get('daileyOMS.auth');
    const decodedCookie = decodeURIComponent(cookie); // Decode the URL-encoded JSON
    const cookieObject = JSON.parse(decodedCookie); // Parse the JSON string
    token = cookieObject.token; // Extract the token
  }

  const urlParams = queryString.stringify(params);
  const baseUrl =
    endpoint === '/install'
      ? process.env.REACT_APP_SHOPIFY_INSTALL_URL
      : process.env.REACT_APP_API_URL;
  const url = `${baseUrl}${endpoint}${urlParams ? '?' + urlParams : ''}`;
  console.log('Request URL', url);
  // Conditionally set Content-Type only if there is a data payload
  const defaultHeaders = {
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(data && { 'Content-Type': 'application/json' }),
    ...headers,
  };

  console.log('Request:', { method, url, data, headers: defaultHeaders });

  try {
    const response = await axios({
      method,
      url,
      data,
      headers: defaultHeaders,
      responseType, // Pass the responseType to Axios
    });
    console.log('Response: ', response);
    return { success: true, data: response.data };
  } catch (error) {
    console.log('Error Response: ', error);
    throw error;
  }
};
