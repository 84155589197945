import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { createProduct } from '../../../utils/Api/Products/Create';

import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import BtnLoadingSpinner from '../../../shared/components/UIElements/BtnLoadingSpinner';
import ProductForm from '../components/ProductForm/ProductForm';
import { FormProvider } from '../../../shared/context/form-context';
import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';

const emptyProduct = {
  id: '',
  status: 'inactive',
  title: '',
  body_html: '',
  vendor: '',
  product_type: '',
  created_at: new Date(),
  updated_at: null,
  published_at: null,
  tags: '',
  variants: [
    {
      id: '',
      product_id: 0,
      title: '',
      image_id: '',
      sku: '',
      inventory_quantity: 0,
      inventory_item_id: 0,
      inventory_management: false,
      option1: '',
      option2: '',
      option3: '',
      price: '0.00',
      compare_at_price: '0.00',
      barcode: '',
      width: '',
      height: '',
      length: '',
      weight: '',
      weight_unit: '',
      requires_shipping: false,
      internal_data: {
        pid: '',
        pre_order: 0,
      },
    },
  ],
  images: [],
  options: [],
};

const CreateProduct = () => {
  const [formEdited, setFormEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { notify } = usePopoutNotification();
  const productFormRef = useRef();
  const navigate = useNavigate();

  const handleFormChange = (edited) => {
    setFormEdited(edited);
  };

  const handleFormSubmit = async (formData) => {
    setIsSubmitting(true);
    try {
      const createdProduct = await createProduct(formData);
      if (createdProduct.success) {
        notify('Success!', createdProduct.data.message, 'success');
        const productId = createdProduct.data.product._id;
        navigate('/products/' + productId);
        setFormEdited(false);
      } else {
        notify('Error!', createdProduct.message, 'error');
      }
    } catch (error) {
      console.error('Failed to create product:', error);
      notify(
        'Error!',
        error.response?.data?.message || 'Network error',
        'error'
      );
    }
    setIsSubmitting(false);
  };

  return (
    <FormProvider
      initialFields={emptyProduct}
      onFormEdit={handleFormChange}
      onFormSubmit={handleFormSubmit}
    >
      <Container>
        <div className="mx-auto">
          <PageHeader pageTitle="Create New Product">
            {isSubmitting && (
              <BtnLoadingSpinner className="w-4 h-4 text-white mr-2" />
            )}
            <Link to="/products">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Discard
              </button>
            </Link>
            <button
              onClick={() => productFormRef.current.submitForm()}
              disabled={!formEdited}
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-[.8125rem] font-semibold text-white shadow-sm hover:bg-indigo-700 disabled:cursor-not-allowed disabled:opacity-30"
            >
              Save
            </button>
          </PageHeader>
          <div className="w-full grow lg:flex">
            <ProductForm
              ref={productFormRef}
              onFormEdit={handleFormChange}
              onFormSubmit={handleFormSubmit}
            />
          </div>
        </div>
      </Container>
    </FormProvider>
  );
};

export default CreateProduct;
