const PaymentCardTotal = ({ total }) => {
  return (
    <div className="px-2 py-2 grid grid-cols-2 sm:gap-4 sm:px-0">
      <dt className="text-sm font-bold leading-6 col-span-1 text-gray-900">
        Total
      </dt>
      <dd className="text-sm leading-6 font-bold text-gray-700 col-span-1 mt-0 flex justify-end">
        ${total.toFixed(2)}
      </dd>
    </div>
  );
};

export default PaymentCardTotal;
