import React, { forwardRef } from 'react';

const Button = (
  { onClick = () => {}, type, className, children, disabled },
  ref
) => {
  return (
    <button
      // Directly use the `onClick` with the provided default function if no function is passed
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`
        rounded-md 
        px-3 
        py-2 
        text-center 
        text-xs
        font-semibold 
        text-white 
        shadow-sm 
        focus-visible:outline 
        focus-visible:outline-2 
        focus-visible:outline-offset-2 
        focus-visible:outline-indigo-600
        ${disabled ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-700'}
        ${className}
      `}
    >
      {children}
    </button>
  );
};

export default forwardRef(Button);
