import { ApiRequest } from '../BaseApi';

export const onUploadImage = async (input) => {
  let data;
  let headers = {};

  if (input instanceof File) {
    const formData = new FormData();
    formData.append('file', input);
    data = formData;
    headers['Content-Type'] = 'multipart/form-data';
  } else if (typeof input === 'string') {
    data = { url: input };
    headers['Content-Type'] = 'application/json';
  } else {
    console.error('Invalid input type:', typeof input);
    return { success: false, message: 'Invalid input type' };
  }

  try {
    const response = await ApiRequest({
      method: 'post',
      endpoint: '/content/upload',
      data,
      headers,
    });

    if (response.success) {
      return {
        success: true,
        data: {
          cloudId: response.data.data.cloudId, // Ensure the correct path to the URL
          cloudUrl: response.data.data.cloudUrl, // Ensure the correct path to the URL
        },
      };
    } else {
      return { success: false, message: 'Image upload failed' };
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    return { success: false, message: 'Error uploading image' };
  }
};
