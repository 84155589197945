// React components
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

// Shared components
import { usePopoutNotification } from '../../../../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useAlert } from '../../../../../../../shared/components/UIElements/Alerts/AlertProvider';
import Flyout from '../../../../../../../shared/components/UIElements/Flyout';

// Table components
import VariantTableItem from './VariantTableItem';

// API / Utilities
import { useOpenImageDialog } from '../../../../../../../utils/openImageDialog';
import { deleteVariants } from '../../../../../../../utils/Api/Variants/Delete';
import VariantTableFilters from './VariantTableFilters';

const VariantTable = ({ fields, handleChange, committed = 0 }) => {
  const checkbox = useRef();
  const selectedImageRef = useRef({});

  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variantItems, setVariantItems] = useState(fields.variants);
  const { notify } = usePopoutNotification();
  const { prompt } = useAlert();
  const [selectedImage, setSelectedImage] = useState({});
  const openImageDialog = useOpenImageDialog();

  // Dynamic filter data
  const [filterData, setFilterData] = useState({});

  const variantFilters = [
    {
      id: 'option1',
      name: 'Color',
      options: fields.options[0].values.map((value) => ({
        value,
        label: value,
      })),
    },
    {
      id: 'option2',
      name: 'Size',
      options: fields.options[1].values.map((value) => ({
        value,
        label: value,
      })),
    },
    {
      id: 'option3',
      name: 'Style',
      options: fields.options[2].values.map((value) => ({
        value,
        label: value,
      })),
    },
  ];

  const handleFilterChange = (sectionId, optionValue, checked) => {
    setFilterData((prev) => {
      let newSection = prev[sectionId] || [];

      if (checked) {
        // Add the selected value to the corresponding section array
        newSection.push(optionValue);
      } else {
        // Remove the value if it was unchecked
        newSection = newSection.filter((item) => item !== optionValue);
      }

      return {
        ...prev,
        [sectionId]: newSection,
      };
    });
  };

  useEffect(() => {
    const filteredVariants = fields.variants.filter((variant) => {
      return Object.entries(filterData).every(([key, values]) => {
        // Allow variants that match all selected filter criteria
        return (
          values.length === 0 || values.some((value) => variant[key] === value)
        );
      });
    });

    setVariantItems(filteredVariants);
  }, [fields.variants, filterData]);

  useEffect(() => {
    selectedImageRef.current = selectedImage; // Update ref whenever selectedImage changes
  }, [selectedImage]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedVariants.length > 0 &&
      selectedVariants.length < variantItems.length;
    setChecked(selectedVariants.length === variantItems.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedVariants, variantItems.length]);

  function toggleAll() {
    const isAllSelected = checked || indeterminate;
    const newSelectedVariants = isAllSelected ? [] : variantItems;

    setSelectedVariants(newSelectedVariants);
    setChecked(!isAllSelected);
    setIndeterminate(false);
  }

  const handleDelete = async () => {
    const variantIdsToDelete = selectedVariants.map((variant) => variant._id);

    try {
      const response = await deleteVariants(variantIdsToDelete, fields._id);
      if (response.success) {
        const updatedVariants = fields.variants.filter(
          (variant) => !variantIdsToDelete.includes(variant._id)
        );
        setVariantItems(updatedVariants);
        setSelectedVariants([]); // Clear the selection after deletion
        notify('Success!', 'Variants deleted successfully.', 'success');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Failed to delete variants:', error);
      notify('Error!', error.message, 'error');
    }
  };

  const onDelete = () => {
    prompt(
      'Confirm Delete',
      'Are you sure you want to delete the variant(s)? This action cannot be undone.',
      () => handleDelete(),
      'Delete'
    );
  };

  // Image adjustments
  const handleVariantImageChange = (selectedImage, variantIds) => {
    const updatedVariants = fields.variants.map((variant) =>
      variantIds.includes(variant._id)
        ? { ...variant, image_id: selectedImage._id }
        : variant
    );

    handleChange({
      target: {
        name: 'variants',
        value: updatedVariants,
      },
    });
  };

  // Transfer to variant item
  const handleSelectImageOpen = () => {
    openImageDialog({
      images: fields.images,
      selectedImage: selectedImage,
      onSelectImage: setSelectedImage,
      onSave: () => {
        const variantIds = selectedVariants.map((variant) => variant._id);
        handleVariantImageChange(selectedImageRef.current, variantIds);
        setSelectedVariants([]); // Clear the selection after save
      },
      onClose: () => console.log('Dialog Closed'),
    });
  };

  const flyoutActions = [
    { name: 'Edit images', action: handleSelectImageOpen },
    { name: 'Delete', action: onDelete },
  ];

  return (
    <>
      <VariantTableFilters
        onFilterChange={handleFilterChange}
        filterData={filterData}
        setFilterData={setFilterData}
        filters={variantFilters}
      />
      <div className="mt-4 flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="relative">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-100">
                  <tr className="mx-10">
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        ref={checkbox}
                        checked={checked}
                        value=""
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-[.8125rem] font-semibold text-gray-900"
                    >
                      Variant
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 hidden lg:table-cell "
                    >
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="min-w-[5rem] px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
                    >
                      Stock
                    </th>
                    <th
                      scope="col"
                      className="min-w-[5rem] px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 hidden lg:table-cell"
                    >
                      Sold
                    </th>
                    <th
                      scope="col"
                      className="min-w-[5rem] px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
                    >
                      Avail
                    </th>
                    <th
                      scope="col"
                      className="min-w-[2rem] px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
                    >
                      Pre-Order
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {variantItems.map((variant, idx) => (
                    <VariantTableItem
                      key={variant._id || idx}
                      variant={variant} // Pass the variant directly
                      selectedVariants={selectedVariants}
                      setSelectedVariants={setSelectedVariants}
                      handleVariantImageChange={handleVariantImageChange}
                      committed={committed}
                      handleChange={handleChange}
                      fields={fields}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {selectedVariants.length > 0 && (
          <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 px-4 py-2 space-x-2 bg-gray-900 shadow-lg rounded-md ring-1 ring-gray-600/20 mb-2">
            <div className="flex items-center gap-x-2">
              {selectedVariants.length > 1 && (
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Bulk edit
                </button>
              )}
              <span className="inline-flex items-center rounded bg-white px-2 py-1 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">
                <Flyout
                  content={<EllipsisHorizontalIcon className="w-5 h-auto" />}
                  actions={flyoutActions}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VariantTable;
