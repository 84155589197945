import { PencilSquareIcon } from '@heroicons/react/24/outline';

const EditIcon = ({ onClick, active }) => {
  return (
    <div
      className={`p-1.5 rounded-lg cursor-pointer ${active ? 'bg-gray-900/10 hover:bg-gray-900/15' : 'hover:bg-gray-900/10'}`}
      onClick={onClick}
    >
      <PencilSquareIcon className="w-5 h-5" />
    </div>
  );
};

export default EditIcon;
