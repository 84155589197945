import React from 'react';
import InfoAlert from '../InfoAlert';

const Input = ({ className, onChange, ...props }) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <label
          htmlFor={props.id}
          className={`block text-[.8125rem] font-medium leading-5 text-gray-900 ${props?.labelsr && 'sr-only'}`}
        >
          {props.label}
        </label>
        {props.badge && (
          <span
            className={`inline-flex items-center gap-x-1.5 rounded-md text-[.7125rem] font-medium p-1 py-0.5 ring-inset ${props.badge.classes}`}
          >
            <svg
              viewBox="0 0 6 6"
              aria-hidden="true"
              className={`h-1.5 w-1.5 ${props.badge.fill}`}
            >
              <circle r={3} cx={3} cy={3} />
            </svg>
            <span>{props.badge.text}</span>
          </span>
        )}
        {props.type === 'password' && props.name === 'password' && (
          <div className="text-[.8125rem]">
            <a
              href="/forgot-password"
              className="font-semibold text-indigo-400 hover:text-indigo-300"
            >
              Forgot password?
            </a>
          </div>
        )}
      </div>
      <div className={`${!props?.labelsr && 'mt-2'}`}>
        <input
          className={className}
          {...props}
          onChange={onChange} // Regular change handler
        />
      </div>
      {props.error && <InfoAlert type="error" header={props.error} />}
    </div>
  );
};

export default Input;
