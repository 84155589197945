import {
  ArrowTopRightOnSquareIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';

import { Link } from 'react-router-dom';

import Badge from '../../../../../../shared/components/UIElements/Badge';
import { FormatDateIntl } from '../../../../../../shared/utils/FormatDateTime';
import RiskAnalysis from './shopify/RiskAnalysis';
import ToolTip from '../../../../../../shared/components/UIElements/ToolTip';

const OrderIntegration = ({ item, order, riskData = {}, className }) => {
  return (
    <div className={`mt-4 w-full text-[.8125rem] py-2 ${className}`}>
      <div className="flex items-center space-between">
        <div className="flex-1 text-[.8125rem] mr-2 flex items-center gap-x-3">
          <Badge className="!text-gray-200 bg-gray-900 group flex gap-x-3 text-[.8125rem] leading-5 font-semibold cursor-pointer hover:bg-gray-700">
            {item.name}
            <ArrowPathIcon className="w-auto h-3.5" />
          </Badge>
        </div>
        <div className="p-1">
          <ToolTip content="Open third party url">
            <Link
              to={`https://${item.url}/admin/orders/${order.id}`}
              target="_blank"
              className="cursor-pointer"
            >
              <ArrowTopRightOnSquareIcon className="w-auto h-5 text-indigo-500 cursor-pointer hover:text-indigo-700" />
            </Link>
          </ToolTip>
        </div>
      </div>
      <div className="mt-2 rounded-lg ring-1 ring-gray-900/20 px-2 py-1.5 bg-gray-900/5">
        <div className="flex items-center text-xs space-between">
          <div className="flex-1 mr-2 flex flex-col gap-x-2">
            <p className="font-semibold">Last update:</p>
            <p className="break-all">{FormatDateIntl(order.updated_at)}</p>
          </div>
        </div>
        <div className="mt-2 flex items-center text-xs space-between">
          <div className="flex-1 mr-2 flex items-center gap-x-2">
            <p className="font-semibold">Order ID:</p>
            <p className="break-all">{order.id}</p>
          </div>
          {/* <div className="relative top-0">
          <CopyIcon data={order.id} />
        </div> */}
        </div>
        <RiskAnalysis riskData={riskData} />
      </div>
    </div>
  );
};

export default OrderIntegration;
