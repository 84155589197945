import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { FormatDateTime } from '../../../../shared/utils/FormatDateTime';
import Badge from '../../../../shared/components/UIElements/Badge';
import CopyIcon from '../../../../shared/components/UIElements/CopyIcon';
import ImageThumbnail from '../../../../shared/components/UIElements/ImageThumbnail';
import { XMarkIcon } from '@heroicons/react/24/solid';

const LineItemMenu = ({ fulfillmentOrders, itemCount }) => {
  console.log(fulfillmentOrders);

  const handleMenuClick = (e) => {
    e.preventDefault();
    return;
  };

  return (
    <Fragment>
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <div className="flex items-center">
            <span>{itemCount}</span>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity text-black">
              <ChevronDownIcon className="h-4 w-4 ml-.5" />
            </span>
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="
              absolute 
              right-0 
              z-10 
              mt-0.5 
              w-full
              sm:w-96
              origin-top-right 
              rounded-lg 
              bg-white 
              shadow-lg 
              ring-1 
              ring-gray-300 
              focus:outline-none
              max-h-56
              overflow-y-auto"
          >
            {Object.entries(fulfillmentOrders).map(([fulfillmentId, items]) => (
              <Fragment key={fulfillmentId}>
                <div className="px-3 py-2 text-sm font-medium bg-gray-100">
                  Fulfillment Order ID: {fulfillmentId}
                </div>
                {items.map((item, idx) => (
                  <Menu.Item
                    key={`${fulfillmentId}-${idx}`}
                    onClick={handleMenuClick}
                  >
                    <div
                      className={`block px-3 py-3 text-[.8125rem] leading-5 text-gray-900`}
                    >
                      <div className="flex items-start">
                        <div className="flex flex-1 flex-col mt-1 gap-y-1">
                          <div className="flex gap-x-2">
                            <ImageThumbnail
                              image={item.image}
                              className="!h-10 !w-10"
                              alt={item.sku}
                            />
                            <div className="flex flex-col gap-y-0">
                              <div className="font-semibold">{item.sku}</div>
                              <div>x {item.quantity}</div>
                            </div>
                          </div>
                          {item.holdReason && (
                            <div className="bg-gray-50">
                              Hold Reason:{' '}
                              <span className="text-red-500">
                                {item.holdReason}
                              </span>
                            </div>
                          )}
                        </div>
                        <Badge
                          className={`capitalize inline-flex items-center rounded-md px-2 py-1 mr-1 text-xs font-medium ring-1 ring-gray-600/20 ring-inset`}
                        >
                          <svg
                            className={`h-1.5 w-1.5`}
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {item.status}
                        </Badge>
                      </div>
                      {/* Example: Displaying tracking information, if applicable */}
                      {/* {item.fulfillment && item.fulfillment.id && (
                        <div className="mt-1">
                          Fulfillment ID: {item.fulfillment.id}
                        </div>
                      )} */}
                    </div>
                  </Menu.Item>
                ))}
              </Fragment>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  );
};

export default LineItemMenu;
