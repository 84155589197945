import React, { useState, useEffect } from 'react';
import { TrashIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { onUploadImage } from '../../../../../../utils/Api/Content/Upload';

import ImageUpload from '../../../../../../shared/components/UIElements/ImageUpload';
import { usePopoutNotification } from '../../../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import BtnLoadingSpinner from '../../../../../../shared/components/UIElements/BtnLoadingSpinner';

const ProductImageUploader = ({
  images,
  onUploadSuccess,
  deleteImage,
  onUpdateImageOrder,
}) => {
  const [imageList, setImageList] = useState(images);
  const { notify } = usePopoutNotification();
  const [uploadingImageId, setUploadingImageId] = useState(null);

  useEffect(() => {
    setImageList(images);
  }, [images]);

  // const onDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const reorderedItems = reorder(
  //     imageList,
  //     result.source.index,
  //     result.destination.index,
  //   );

  //   setImageList(reorderedItems);
  //   onUpdateImageOrder(reorderedItems);
  // };

  const onDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list

    const reorderedItems = reorderGrid(
      imageList,
      result.source.index,
      result.destination.index,
      4 // assuming 3 columns in a simple case
    );

    setImageList(reorderedItems);
    onUpdateImageOrder(reorderedItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((item, index) => ({ ...item, position: index + 1 }));
  };

  const [draggedIndex, setDraggedIndex] = useState(null);

  const reorderGrid = (list, sourceIndex, destinationIndex, columns) => {
    const sourceRow = Math.floor(sourceIndex / columns);
    const sourceColumn = sourceIndex % columns;
    const destinationRow = Math.floor(destinationIndex / columns);
    const destinationColumn = destinationIndex % columns;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(sourceIndex, 1);
    const destinationInsertIndex = destinationRow * columns + destinationColumn;
    reorderedList.splice(destinationInsertIndex, 0, removed);

    return reorderedList;
  };

  const handleDeleteImage = async (image) => {
    deleteImage(image._id, image.cloudId);
  };

  // This function is to upload an image brought in by third party like Shopify using the images src attribute
  const handleImageUpload = async (file, itemId) => {
    setUploadingImageId(itemId);
    const response = await onUploadImage(file);
    if (response.success) {
      // Ensure correct object construction for existing images
      onUploadSuccess([
        {
          _id: itemId, // Existing image's _id or tempId
          cloudId: response.data.cloudId,
          cloudUrl: response.data.cloudUrl,
          isNew: false, // Flag to indicate this is not a new upload
        },
      ]);
      notify('Success!', response.message, 'success');
    } else {
      console.error(`Failed to upload image to Cloudinary.`);
      notify('Error!', response.message, 'error');
    }
    setUploadingImageId(null);
  };

  const handleUploadToCloudinary = (image) => {
    // Handle upload to Cloudinary using image URL
    handleImageUpload(image.src, image._id);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-images" direction="horizontal">
        {(provided, snapshot) => (
          <ul
            // className="grid grid-cols-3 gap-4 sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4"
            className={`grid grid-cols-3 gap-4 sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {imageList.map((image, index) => (
              <Draggable
                key={image._id}
                draggableId={image._id}
                index={index}
                type="TASK"
              >
                {(provided, snapshot) => {
                  if (snapshot.isDragging) setDraggedIndex(index);
                  return (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`relative group`}
                    >
                      {uploadingImageId === image._id ? (
                        <div className="aspect-w-1 aspect-h-1 h-full items-center flex justify-center w-full overflow-hidden rounded-lg bg-gray-100 relative ring-1 ring-gray-200 hover:ring-gray-400/65 hover:opacity-75">
                          <BtnLoadingSpinner className="w-12 h-12 mr-2 text-indigo-600" />
                        </div>
                      ) : (
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-100 relative ring-1 ring-gray-200 hover:ring-gray-400/65 hover:opacity-75">
                          <img
                            src={image.src ? image.src : image.cloudUrl}
                            alt=""
                            className="h-full w-full object-cover"
                          />
                          <div className="absolute top-0 right-0 m-2 hidden group-hover:block">
                            <div className="grid grid-cols-1 gap-y-2">
                              {!image.cloudId && ( // If cloudId doesn't exist
                                <span
                                  onClick={() =>
                                    handleUploadToCloudinary(image)
                                  }
                                  className="p-1 rounded-full bg-indigo-300 text-indigo-500 hover:bg-indigo-200 hover:ring-1 hover:ring-indigo-300 hover:text-indigo-600 cursor-pointer"
                                >
                                  <CloudArrowUpIcon className="h-6 w-6" />
                                </span>
                              )}
                              <span
                                onClick={() => handleDeleteImage(image)}
                                className="p-1 rounded-full bg-red-300 text-red-500 hover:bg-red-200 hover:ring-1 hover:ring-red-300 hover:text-red-600 cursor-pointer"
                                aria-label="Delete image"
                              >
                                <TrashIcon className="h-6 w-6" />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  );
                }}
              </Draggable>
            ))}
            {snapshot.isDraggingOver && (
              <li className="h-20 col-span-1 border-2 border-dashed border-gray-400 flex justify-center items-center text-gray-500">
                Drop here
              </li>
            )}
            {provided.placeholder}
            <li className="relative group">
              <ImageUpload onUploadSuccess={onUploadSuccess} />
            </li>
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductImageUploader;
