import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import UserForm from '../components/UserForm';
import { getUser } from '../../../utils/Api/Users/Get';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';

const EditUser = () => {
  let { id } = useParams();

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Define an async function inside the effect to fetch users
    const fetchData = async () => {
      try {
        const data = await getUser(id); // Wait for getUsers to resolve
        setUser(data.data); // Update the state with the fetched data
      } catch (error) {
        console.error('Failed to fetch users:', error);
        // Handle error here, e.g., set error state or show a notification
      }
    };

    fetchData(); // Call the async function
  }, [id]);
  return !user ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : (
    <Container>
      <div className="mx-auto max-w-screen-xl">
        <PageHeader pageTitle={`Edit User`} backButton={true}>
          {/* <ButtonDropdown
            onDelete={handleDeleteVariant}
            onDiscard={handleDiscard}
            onSave={() => formRef.current.submitForm()}
          /> */}
        </PageHeader>
        <div className="w-full grow lg:flex">
          <UserForm
            firstName={user?.firstName}
            lastName={user?.lastName}
            email={user?.email}
            status={user?.status}
            image={user?.image}
            userId={id}
          />
        </div>
      </div>
    </Container>
  );
};

export default EditUser;
