import React from 'react';

import LoginForm from '../components/LoginForm';
import daileyprojectLogo from '../../../shared/images/test-logo-app.png';

const Login = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img src={daileyprojectLogo} alt="" className="mx-auto h-40 w-auto" />
        <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-zinc-900">
          Sign in to your account
        </h2>
      </div>
      <LoginForm />
    </div>
  );
};

export default Login;
