import React from 'react';

import OrderItem from './OrderItem';
import SelectTable from '../../../../shared/components/UIElements/Tables/SelectTable';

const OrdersList = ({ orders, handleSearch }) => {
  const tableHeaders = () => (
    <>
      <th
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900 sm:pl-0"
      >
        Order #
      </th>
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
      >
        Status
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 lg:table-cell"
      >
        Priority
      </th>
      <th
        scope="col"
        className="hidden  px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Items
      </th>
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
      >
        Created
      </th>
      <th
        scope="col"
        className="hidden  px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Shipment Status
      </th>
    </>
  );

  return (
    <SelectTable
      items={orders}
      tableHeaders={tableHeaders}
      RowComponent={OrderItem}
      className="overflow-x-visible"
      handleSearch={handleSearch}
    />
  );
};

export default OrdersList;
