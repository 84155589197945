import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import Logo from '../../../images/test-logo.png';

import {
  ChartPieIcon,
  Cog6ToothIcon,
  HomeIcon,
  TruckIcon,
  UserGroupIcon,
  TagIcon,
  InboxIcon,
} from '@heroicons/react/20/solid';
import MainMobileNav from './MainMobileNav';
import Badge from '../../UIElements/Badge';
import MainNavHeader from './MainNavHeader';

const nav = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  {
    name: 'Orders',
    href: '/orders',
    icon: InboxIcon,
    current: false,
  },
  {
    name: 'Shipments',
    icon: TruckIcon,
    current: false,
    badge: 'Coming Soon',
    children: [
      { name: 'Batches', href: '/shipments/batches' },
      { name: 'Processing', href: '/shipments/process' },
    ],
  },
  {
    name: 'Products',
    icon: TagIcon,
    current: false,
    children: [
      { name: 'Products', href: '/products' },
      { name: 'Inventory', href: '/inventory' },
    ],
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: ChartPieIcon,
    current: false,
    badge: 'Coming Soon',
  },
  { name: 'Users', href: '/users', icon: UserGroupIcon, current: false },
];

const teams = [
  {
    id: 1,
    name: 'Heroicons',
    href: '#',
    initial: 'H',
    current: false,
    badge: 'Coming Soon',
  },
  {
    id: 2,
    name: 'Tailwind Labs',
    href: '#',
    initial: 'T',
    current: false,
    badge: 'Coming Soon',
  },
  {
    id: 3,
    name: 'Workcation',
    href: '#',
    initial: 'W',
    current: false,
    badge: 'Coming Soon',
  },
];

const footerNavigation = [
  { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MainNavigation = ({ openMenu, onMenuClose }) => {
  const [navigation, setNavigation] = useState(nav);
  const [footerNav, setFooterNav] = useState(footerNavigation);
  const location = useLocation();

  useEffect(() => {
    const updateCurrentStatus = (path) => {
      setNavigation((currentNavigation) => {
        return currentNavigation.map((item) => {
          const hasChildren = item.children && item.children.length > 0;

          if (hasChildren) {
            const updatedChildren = item.children.map((child) => ({
              ...child,
              current: path.startsWith(child.href), // Updated check
            }));

            const childIsCurrent = updatedChildren.some(
              (child) => child.current
            );

            return {
              ...item,
              children: updatedChildren,
              current: path.startsWith(item.href) || childIsCurrent, // Updated check
            };
          } else {
            return {
              ...item,
              current: path.startsWith(item.href), // Updated check
            };
          }
        });
      });

      // Update footer navigation similarly
      setFooterNav((currentFooterNav) => {
        return currentFooterNav.map((navItem) => ({
          ...navItem,
          current: path.startsWith(navItem.href), // Updated check
        }));
      });
    };

    updateCurrentStatus(location.pathname);
  }, [location]); // Ensure location is the only dependency

  return (
    <>
      <MainMobileNav
        navigation={navigation}
        teams={teams}
        footerNav={footerNav}
        openMenu={openMenu}
        onMenuClose={onMenuClose}
      />
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
          <div className="flex shrink-0 items-center gap-x-1 py-2">
            <div className="w-full px-1 py-2 flex">
              <img className="h-8 w-auto" src={Logo} alt="Dailey Project" />
              <MainNavHeader />
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-[.8125rem] leading-5 font-semibold items-center'
                          )}
                        >
                          <item.icon
                            className="h-4 w-4 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                          {item?.badge && (
                            <Disclosure
                              as="div"
                              className="ml-auto flex flex-row items-center gap-x-2"
                            >
                              <Badge
                                dot={false}
                                className="tracking-wide uppercase !text-[.5rem] font-bold bg-gray-100/40 text-gray-900 !py-0.5"
                              >
                                {item?.badge}
                              </Badge>
                            </Disclosure>
                          )}
                        </Link>
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-800 text-white'
                                    : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                  'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-[.8125rem] leading-5 font-semibold text-gray-400'
                                )}
                              >
                                <item.icon
                                  className="h-4 w-4 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {item.name}
                                <Disclosure
                                  as="div"
                                  className="ml-auto flex flex-row items-center gap-x-2"
                                >
                                  {item?.badge && (
                                    <Badge
                                      dot={false}
                                      className="tracking-wide uppercase !text-[.5rem] font-bold bg-gray-100/40 text-gray-900 !py-0.5"
                                    >
                                      {item?.badge}
                                    </Badge>
                                  )}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? 'rotate-90 text-white'
                                        : 'text-gray-400',
                                      'h-5 w-5 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure>
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    <Disclosure.Button
                                      as="div" // Use 'div' or another suitable tag for the Disclosure.Button wrapper
                                      className="block pt-0.5"
                                    >
                                      <Link
                                        to={subItem.href} // Use 'to' prop for Link instead of 'href'
                                        className={classNames(
                                          subItem.current
                                            ? 'bg-gray-800 text-white'
                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                          'block rounded-md py-2 pr-2 pl-9 text-[.8125rem]/[1.25rem] leading-5 text-gray-400 w-full items-center' // Adjust the styling as needed
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li>
                <div className="text-xs font-semibold leading-5 text-gray-400">
                  Your teams
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  {teams.map((team) => (
                    <li key={team.name}>
                      <Link
                        to={team.href}
                        className={classNames(
                          team.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-[.8125rem] leading-5 font-semibold",
                        )}
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                          {team.initial}
                        </span>
                        <span className="truncate">{team.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li> */}
              <li className="mt-auto">
                <ul>
                  {footerNav.map((navItem, idx) => (
                    <li key={idx}>
                      <Link
                        to={navItem.href}
                        // className="group -mx-2 flex gap-x-3 rounded-md p-2 text-[.8125rem] font-semibold leading-5 text-gray-400 hover:bg-gray-800 hover:text-white"
                        className={classNames(
                          navItem.current
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                          'group -mx-2 flex gap-x-3 rounded-md p-2 text-[.8125rem] font-semibold leading-5 items-center' // Adjust the styling as needed
                        )}
                      >
                        <navItem.icon
                          className="h-4 w-4 shrink-0"
                          aria-hidden="true"
                        />
                        {navItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MainNavigation;
