import { ApiRequest } from '../BaseApi';

export const AuthLogin = (email, password) => {
  return ApiRequest({
    method: 'post',
    endpoint: '/auth/login',
    data: { email, password },
    requireAuth: false, // Not requiring auth token for login
  });
};
