import InfoAlert from '../InfoAlert';

const PriceInput = ({ onChange, ...props }) => {
  return (
    <>
      <label
        htmlFor={props?.id}
        className={`block text-[.8125rem] font-medium leading-5 text-gray-900 ${props.labelsr && 'sr-only'}`}
      >
        {props?.label}
      </label>
      <div
        className={`relative rounded-md shadow-sm ${!props.labelsr && 'mt-2'}`}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-[.8125rem]">$</span>
        </div>
        <input
          type="number"
          name={props.name}
          id={props.id}
          className={`block w-full rounded-md border-0 py-1.5 pl-7 ${!props.remove_currency && 'pr-12'} text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5`}
          placeholder="0.00"
          aria-describedby="price-currency"
          {...props}
          onChange={onChange}
        />
        {!props?.remove_currency && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className="text-gray-500 sm:text-[.8125rem]"
              id="price-currency"
            >
              USD
            </span>
          </div>
        )}
      </div>
      {props.error && <InfoAlert type="error" header={props.error} />}
    </>
  );
};

export default PriceInput;
