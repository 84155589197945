import { Fragment, useState, useEffect } from 'react';

import { getIntegrations } from '../../../utils/Api/Integrations/Get';

import SettingsNavigation from '../components/SettingsNavigation';
import Container from '../../../shared/components/UIElements/Container';
import IntegrationList from '../components/Integrations/IntegrationList';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';

const Integrations = () => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    // Define an async function inside the effect to fetch users
    const fetchData = async () => {
      try {
        const data = await getIntegrations(); // Wait for getUsers to resolve
        setItems(data.data.integrations); // Update the state with the fetched data
      } catch (error) {
        console.error('Failed to fetch users:', error);
        // Handle error here, e.g., set error state or show a notification
      }
    };

    fetchData(); // Call the async function
  }, []);

  return !items ? (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <h1 className="sr-only">Integrations</h1>

          <div className="mt-4 flex flex-col">
            <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
          </div>
        </div>
      </Container>
    </Fragment>
  ) : (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-md">
          <h1 className="sr-only">Integrations</h1>

          <div className="mt-4 flex flex-col">
            <IntegrationList integrations={items} />
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Integrations;
