// React components
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Shared components
import Input from '../../../../../../../shared/components/UIElements/Inputs/Input';
import { useDialog } from '../../../../../../../shared/components/UIElements/Dialog/DialogProvider';

// Page components
import VariantTableImage from './VariantTableImage';
import SelectVariantImage from './SelectVariantImage';

// Component functions
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const findImageSrcById = (images, imageId) => {
  const image = images.find(
    (img) => img.id === Number(imageId) || img._id === imageId
  );
  return image ? image.src || image.cloudUrl || '' : '';
};

const VariantTableItem = ({
  variant, // Receive the variant directly
  selectedVariants,
  setSelectedVariants,
  handleVariantImageChange,
  committed,
  handleChange,
  fields,
}) => {
  const { prompt } = useDialog();

  const [selectedImage, setSelectedImage] = useState({});
  const [saveRequested, setSaveRequested] = useState(false);
  const [onHand, setOnHand] = useState(0);
  const [itemsCommitted, setItemsCommitted] = useState(0);

  // Set image thumbnail
  useEffect(() => {
    setSelectedImage(
      fields?.images?.find(
        (img) =>
          img.id === Number(variant.image_id) || img._id === variant.image_id
      )
    );
  }, [fields?.images, variant]);

  useEffect(() => {
    if (saveRequested && selectedImage?._id) {
      handleVariantImageChange(selectedImage, [variant._id]);
      setSaveRequested(false); // Reset the flag
    }
  }, [selectedImage, saveRequested, handleVariantImageChange, variant._id]);

  useEffect(() => {
    if (variant && committed) {
      const sku = variant.sku;
      const committedQuantity = committed[sku] || 0;
      const onHandItems = variant.inventory_quantity - committedQuantity;
      setOnHand(onHandItems);
      setItemsCommitted(committedQuantity);
    }
  }, [variant, committed]);

  const handleVariantChange = (field, event) => {
    const value = event.target.value;

    const updatedVariants = fields.variants.map((v) => {
      if (v._id === variant._id) {
        if (field.startsWith('internal_data.')) {
          const internalField = field.split('.')[1];
          return {
            ...v,
            internal_data: {
              ...v.internal_data,
              [internalField]: value,
            },
          };
        } else {
          return { ...v, [field]: value };
        }
      }
      return v;
    });

    handleChange({
      target: {
        name: 'variants',
        value: updatedVariants,
      },
    });
  };

  const handleSelectImageOpen = () => {
    const onSave = () => {
      setSaveRequested(true);
    };

    const dialogActions = {
      primary: {
        title: 'Save',
        classes: 'bg-indigo-800 text-white hover:bg-indigo-600',
        action: onSave,
      },
      secondary: {
        title: 'Cancel',
        classes:
          'bg-white-600 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        action: 'close',
      },
    };

    const key = selectedImage
      ? selectedImage._id
      : { _id: null, src: null, cloudUrl: null, id: null, cloudId: null };

    prompt(
      'Select an image',
      <SelectVariantImage
        key={key}
        images={fields.images}
        selectedImage={selectedImage}
        onSelectImage={setSelectedImage}
      />,
      dialogActions
    );
  };

  return (
    <tr
      className={selectedVariants.includes(variant) ? 'bg-gray-50' : undefined}
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {selectedVariants.includes(variant) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={variant._id}
          checked={selectedVariants.includes(variant)}
          onChange={(e) =>
            setSelectedVariants(
              e.target.checked
                ? [...selectedVariants, variant]
                : selectedVariants.filter((p) => p !== variant)
            )
          }
        />
      </td>
      <td
        className={classNames(
          'whitespace-nowrap py-4 pr-3 text-[.8125rem] font-medium',
          selectedVariants.includes(variant)
            ? 'text-indigo-600'
            : 'text-gray-900'
        )}
      >
        <div className="flex items-center">
          <VariantTableImage
            variant={variant}
            findImageSrcById={(imageId) =>
              findImageSrcById(fields?.images, imageId)
            }
            onImageChange={handleSelectImageOpen}
          />

          <div className="ml-4">
            <Link
              to={`/products/${fields._id}/variants/${variant._id}`}
              className="hover:underline"
            >
              <div className="font-medium text-xs text-gray-900 flex items-center">
                {[variant.option1, variant.option2, variant.option3]
                  .filter(Boolean)
                  .map((option, index, filteredOptions) => (
                    <Fragment key={index}>
                      <span>{option}</span>
                      {index < filteredOptions.length - 1 && (
                        <span className="px-1">&#8729;</span>
                      )}
                    </Fragment>
                  ))}
              </div>
              <div className="text-xs text-gray-400 font-normal">
                {variant.sku}
              </div>
            </Link>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500 hidden lg:table-cell ">
        <div>
          <Input
            label="SKU"
            labelsr="true"
            type="text"
            name="sku"
            id="sku"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[.8125rem] sm:leading-5"
            value={variant.sku}
            onChange={(e) => handleVariantChange('sku', e)}
          />
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500">
        <div>
          <Input
            label="Stock"
            labelsr="true"
            type="number"
            name="stock"
            id="stock"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            value={variant.inventory_quantity}
            onChange={(e) => handleVariantChange('inventory_quantity', e)}
          />
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500 hidden lg:table-cell">
        <div>
          <Input
            label="Sold"
            labelsr="true"
            type="number"
            name="sold"
            id="sold"
            disabled
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[.8125rem] sm:leading-5"
            value={itemsCommitted}
          />
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500">
        <div>
          <Input
            label="Available"
            labelsr="true"
            type="number"
            name="available"
            id="available"
            disabled
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-[.8125rem] sm:leading-5"
            value={onHand}
          />
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500">
        <div>
          <Input
            label="Pre-Order"
            labelsr="true"
            type="number"
            name="pre_order"
            id="pre_order"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            value={variant.internal_data?.pre_order}
            onChange={(e) => handleVariantChange('internal_data.pre_order', e)}
          />
        </div>
      </td>
    </tr>
  );
};

export default VariantTableItem;
