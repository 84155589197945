import React from 'react';

const BasicTable = ({
  items,
  tableHeaders,
  RowComponent,
  rowProps,
  className,
}) => {
  return (
    <div className="overflow-x-visible">
      <div className="inline-block min-w-full align-middle ring-1 ring-gray-700/20 rounded-xl bg-white">
        <div className={`overflow-x-auto block w-full ${className}`}>
          <table className="min-w-full divide-y divide-gray-300 overflow-x-auto">
            <thead>{tableHeaders()}</thead>
            <tbody className="divide-y divide-gray-200 rounded-b-xl">
              {items.map((item) => (
                <RowComponent key={item._id} item={item} {...rowProps} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BasicTable;
