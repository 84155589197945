import { useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';

const settings = [
  {
    name: 'Current page',
    description: 'Export the data on the current page only',
    value: 'currentPage',
  },
  {
    name: 'All products',
    description: 'All products will be exported',
    value: 'allProducts',
  },
  {
    name: 'Products matching search',
    description: 'Export products based on the search criteria',
    value: 'searchResults',
  },
];

const ExportDialog = ({ onSelect }) => {
  const [selected, setSelected] = useState(settings[0]);

  const handleSelectionChange = (selectedOption) => {
    console.log('SELECTED', selectedOption);
    setSelected(selectedOption);
    onSelect(selectedOption.value); // Call onSelect whenever selection changes
  };

  return (
    <fieldset aria-label="Export setting">
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        Export Options
      </legend>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Select the export option you prefer.
      </p>
      <RadioGroup
        value={selected}
        onChange={handleSelectionChange} // Use handleSelectionChange to capture selection
        className="-space-y-px rounded-md bg-white mt-4"
      >
        {settings.map((setting, settingIdx) => (
          <Radio
            key={setting.value}
            value={setting}
            className={`
              group relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none 
              ${settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : ''} 
              ${settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : ''} 
              data-[checked]:z-10 data-[checked]:border-indigo-200 data-[checked]:bg-indigo-50
            `}
          >
            <span
              className="mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white 
                group-data-[checked]:border-transparent group-data-[checked]:bg-indigo-600 group-data-[focus]:ring-2 group-data-[focus]:ring-indigo-600 group-data-[focus]:ring-offset-2"
            >
              <span className="h-1.5 w-1.5 rounded-full bg-white" />
            </span>
            <span className="ml-3 flex flex-col">
              <span className="block text-sm font-medium text-gray-900 group-data-[checked]:text-indigo-900">
                {setting.name}
              </span>
              <span className="block text-sm text-gray-500 group-data-[checked]:text-indigo-700">
                {setting.description}
              </span>
            </span>
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
};

export default ExportDialog;
