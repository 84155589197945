import React, { createContext, useContext, useState, useEffect } from 'react';

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({
  children,
  initialFields,
  validate,
  onSubmitting,
  onFormSubmit,
}) => {
  const [fields, setFields] = useState(initialFields || {});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formEdited, setFormEdited] = useState(false);

  // Initialize fields only once
  useEffect(() => {
    setFields(initialFields);
  }, [initialFields]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFields((prevFields) => {
      const newFields = JSON.parse(JSON.stringify(prevFields)); // Deep clone the fields

      if (name.includes('.')) {
        const keys = name.split('.');
        let obj = newFields;

        // Traverse through keys to reach the appropriate level
        for (let i = 0; i < keys.length - 1; i++) {
          if (!obj[keys[i]]) {
            obj[keys[i]] = {};
          }
          obj = obj[keys[i]];
        }

        // Update the final key with the new value
        obj[keys[keys.length - 1]] = value;
      } else {
        newFields[name] = value;
      }

      // Check if the new fields are different from the initial fields
      const fieldsChanged =
        JSON.stringify(initialFields) !== JSON.stringify(newFields);

      setFormEdited(fieldsChanged);
      return newFields;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validate(fields);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && onFormSubmit) {
      try {
        await onFormSubmit(fields);
        setFormEdited(false);
      } catch (error) {
        console.error('Form submission error:', error);
      }
    }

    setIsSubmitting(false);
  };
  return (
    <FormContext.Provider
      value={{
        fields,
        setFields,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        formEdited,
        setFormEdited,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
