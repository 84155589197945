import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getIntegration } from '../../../utils/Api/Integrations/Get';
import { updateIntegration } from '../../../utils/Api/Integrations/Update';
import { usePopoutNotification } from '../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';

import SettingsNavigation from '../components/SettingsNavigation';
import Container from '../../../shared/components/UIElements/Container';
import IntegrationForm from '../components/Integrations/IntegrationForm';
import Card from '../../../shared/components/UIElements/Card';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import { FormProvider } from '../../../shared/context/form-context';
import IntegrationDetails from '../components/Integrations/IntegrationDetails';

const EditIntegration = ({ integration }) => {
  let { id } = useParams();
  const [item, setItem] = useState(null);
  const { notify } = usePopoutNotification();

  useEffect(() => {
    // Define an async function inside the effect to fetch users
    const fetchData = async () => {
      try {
        const data = await getIntegration(id); // Wait for getUsers to resolve
        setItem(data.data); // Update the state with the fetched data
      } catch (error) {
        console.error('Failed to fetch users:', error);
        // Handle error here, e.g., set error state or show a notification
      }
    };

    fetchData(); // Call the async function
  }, [id]);

  // const handleFormSubmit = async (fields) => {
  //   try {
  //     await updateIntegration(id, fields.key, fields.secret);
  //     notify("Successfully saved!", "API keys successfully stored.", "success");

  //   } catch (error) {
  //     console.error("Failed to update integration:", error);
  //     notify("Error!", "Failed to update integration.", "error");
  //   }
  // };

  return !item ? (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <h1 className="sr-only">Integration</h1>

          <div className="mt-4 flex flex-col">
            <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
          </div>
        </div>
      </Container>
    </Fragment>
  ) : (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-md">
          <h1 className="sr-only">{item.name} Integration</h1>

          <div className="mt-4 flex flex-col">
            <Card className="w-1/2 m-auto">
              <img
                className="w-auto h-20 mx-auto"
                src={item.image}
                alt={item.name}
              />
            </Card>
            {(item.key && item.secret) || item.token ? (
              <Card className="mt-4">
                <IntegrationDetails integration={item} setItem={setItem} />
              </Card>
            ) : (
              <Card className="mt-4">
                <FormProvider
                  initialFields={{ key: item.key, secret: item.secret }}
                  validate={(fields) => {
                    const errors = {};
                    if (!fields.key) errors.key = 'API Key is required';
                    if (!fields.secret)
                      errors.secret = 'API Secret is required';
                    return errors;
                  }}
                  onFormEdit={() => {}}
                >
                  <IntegrationForm integration={item} setItem={setItem} />
                </FormProvider>
              </Card>
            )}
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default EditIntegration;
