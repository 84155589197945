import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import StatLoader from '../Loaders/StatLoader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Stats = ({ stats }) => {
  const [dashStats, setDashStats] = useState(null);
  useEffect(() => {
    setDashStats(stats);
  }, [stats]);

  return (
    <Fragment>
      {!dashStats ? (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <StatLoader />
          <StatLoader />
          <StatLoader />
        </dl>
      ) : (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {dashStats.map((item) => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow ring-1 ring-gray-900/10 sm:px-6 sm:pt-6"
            >
              <div className="flex items-center">
                <div className="flex-1">
                  <dt>
                    <div className="absolute rounded-md bg-gray-900 p-3">
                      <item.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 truncate text-[.8125rem] font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline gap-x-4">
                    <p className="text-2xl font-semibold text-gray-900">
                      {item.stat}
                    </p>
                    <p
                      className={classNames(
                        item.changeType === 'increase'
                          ? 'text-green-600'
                          : 'text-red-600',
                        'ml-2 flex items-baseline text-[.8125rem] font-semibold'
                      )}
                    >
                      {item.changeType === 'increase' ? (
                        <ArrowUpIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowDownIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                          aria-hidden="true"
                        />
                      )}

                      <span className="sr-only">
                        {' '}
                        {item.changeType === 'increase'
                          ? 'Increased'
                          : 'Decreased'}{' '}
                        by{' '}
                      </span>
                      {item.change}
                    </p>
                    {/* <div className="absolute inset-x-0 bottom-0 bg-gray-50 border-t border-gray-900/10 px-4 py-4 sm:px-6">
                      <div className="text-[.8125rem]">
                        <Link to="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          View all<span className="sr-only"> {item.name} stats</span>
                        </Link>
                      </div>
                    </div> */}
                  </dd>
                </div>
                <div className="text-[.8125rem]">
                  <Link
                    to="#"
                    className="font-medium text-gray-400 hover:text-gray-500"
                  >
                    <ArrowLongRightIcon className="w-5 h-5" />
                    <span className="sr-only">View all {item.name} stats</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </dl>
      )}
    </Fragment>
  );
};

export default Stats;
