import React, { useEffect, useState } from 'react';
import TextAreaPreview from '../../../../../../shared/components/UIElements/TextArea/TextAreaPreview';

const ProductDescription = ({ bodyHtml, handleChange }) => {
  const [description, setDescription] = useState(bodyHtml);
  useEffect(() => {
    setDescription(bodyHtml);
  }, [bodyHtml]);

  return (
    <div className="mt-4">
      <TextAreaPreview
        id="body_html"
        name="body_html"
        label="Description"
        rows={3}
        className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
        placeHolder="Add a product description"
        defaultValue={description}
        value={description}
        descriptionChange={handleChange}
      />
    </div>
  );
};

export default ProductDescription;
