import { useState } from 'react';
import Checkbox from '../../../../shared/components/UIElements/Checkbox';
import { updateIntegrationOption } from '../../../../utils/Api/Integrations/Update';
import { FormatUnderscore } from '../../../../shared/utils/FormatUnderscore';

const IntegrationOptionItem = ({ item, integration }) => {
  const [itemActive, setItemActive] = useState(item.active);

  const handleCheckbox = async () => {
    try {
      const updatedItem = { active: !itemActive };

      // Update the state optimistically
      setItemActive(!itemActive);

      await updateIntegrationOption(integration._id, updatedItem, item._id);
    } catch (error) {
      // Revert the state if there is an error
      setItemActive(item.active);
    }
  };

  return (
    <div className="grid-cols-1 sm:grid-cols-6">
      <Checkbox
        text={
          integration.category === 'shipping'
            ? FormatUnderscore(item.name)
            : FormatUnderscore(item.topic)
        }
        name={item._id}
        id={item._id}
        checked={itemActive}
        onChange={handleCheckbox}
      />
    </div>
  );
};

export default IntegrationOptionItem;
