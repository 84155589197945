import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client'; // Notice the change here

import { Analytics } from '@vercel/analytics/react';

import './index.css';
import App from './App';

// Use createRoot to create a root for your application
const root = ReactDOM.createRoot(document.getElementById('root'));

// Use the root to render your App component
root.render(
  <Fragment>
    <App />
    <Analytics />
  </Fragment>
);
