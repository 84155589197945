import { useDialog } from '../shared/components/UIElements/Dialog/DialogProvider';
import SelectVariantImage from '../main/products/components/ProductForm/components/Variants/VariantTable/SelectVariantImage';

export const useOpenImageDialog = () => {
  const { prompt } = useDialog();

  return ({
    promptTitle = 'Select an image',
    images,
    selectedImage,
    onSelectImage,
    onSave,
    onClose = () => {},
  }) => {
    const dialogActions = {
      primary: {
        title: 'Save',
        classes: 'bg-indigo-800 text-white hover:bg-indigo-600',
        action: onSave,
      },
      secondary: {
        title: 'Cancel',
        classes:
          'bg-white-600 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        action: onClose,
      },
    };

    const key = selectedImage
      ? selectedImage._id
      : { _id: null, src: null, cloudUrl: null, id: null, cloudId: null };

    prompt(
      promptTitle,
      <SelectVariantImage
        key={key}
        images={images}
        selectedImage={selectedImage}
        onSelectImage={onSelectImage}
      />,
      dialogActions
    );
  };
};
