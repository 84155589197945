import { TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import AddItemProperty from './EditItem/AddItemProperty';

const ItemProperties = ({
  itemProperties,
  selectedOption,
  selectedItems,
  setSelectedItems,
  lineItem,
}) => {
  const [properties, setProperties] = useState(itemProperties || []);

  useEffect(() => {
    setProperties(itemProperties || []);
  }, [itemProperties]);

  const handleRemoveProperty = (index) => {
    const newProperties = properties.filter((_, i) => i !== index);
    setProperties(newProperties);
    handlePropertyChange(newProperties);
  };

  const handlePropertyChange = (newProperties) => {
    const itemId = lineItem?._id;
    setSelectedItems((prevState) => ({
      ...prevState,
      [itemId]: {
        ...prevState[itemId],
        edits: {
          ...prevState[itemId].edits,
          properties: newProperties.length > 0 ? newProperties : undefined,
        },
      },
    }));
  };

  return (
    <div className="mt-2">
      {properties && properties.length > 0 && (
        <ul
          className={`${selectedOption === 'Edit items' && 'flex flex-col gap-y-2 mt-2'}`}
        >
          {properties.map((property, idx) => {
            return (
              <li key={idx} className={`flex items-center flex-row gap-x-2`}>
                {/* <span>{property.name}: {property.value}</span> */}
                {selectedOption === 'Edit items' &&
                  !selectedItems[lineItem?._id]?.edits?.removing && (
                    <div onClick={() => handleRemoveProperty(idx)}>
                      <TrashIcon className="w-auto h-3.5 text-gray-600 cursor-pointer hover:text-gray-900" />
                    </div>
                  )}
                <span>
                  {property.name}: {property.value}
                </span>
              </li>
            );
          })}
        </ul>
      )}
      {selectedOption === 'Edit items' &&
        !selectedItems[lineItem?._id]?.edits?.removing && (
          <AddItemProperty
            propertyList={properties}
            onPropertyChange={handlePropertyChange}
          />
        )}
    </div>
  );
};

export default ItemProperties;
