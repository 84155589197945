import { useState, useEffect } from 'react';

const handleInputClick = (event) => {
  event.stopPropagation(); // This stops the click from bubbling up to the parent
};

const LineItemQuantityInput = ({
  fulfillableQuantity,
  onQuantityChange,
  itemId,
  selectedOption,
  disabled,
  hold_data,
  setIsInputFocused,
}) => {
  const [quantity, setQuantity] = useState(
    hold_data?.quantity || fulfillableQuantity || 0
  );

  useEffect(() => {
    setQuantity(hold_data?.quantity || fulfillableQuantity || 0);
  }, [fulfillableQuantity, hold_data?.quantity]);

  const handleChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxQuantity = hold_data?.quantity || fulfillableQuantity;

    if (isNaN(value)) {
      value = '';
    } else if (value > maxQuantity && selectedOption !== 'Edit items') {
      value = maxQuantity;
    } else if (value < 0) {
      value = 0;
    }

    setQuantity(value);
    if (!isNaN(value)) {
      onQuantityChange(itemId, value);
    }
  };

  const displayQuantity = hold_data?.quantity || fulfillableQuantity;

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <div
      className="items-center relative py-2 sm:py-0"
      onClick={handleInputClick}
    >
      <input
        type="number"
        max={selectedOption !== 'Edit items' ? displayQuantity : undefined}
        className={`max-w-20 pl-3 pr-1 border border-gray-300 py-1 rounded text-[.8125rem] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
        onChange={handleChange}
        value={quantity}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <span className="pointer-events-none absolute end-2 sm:end inset-y-0 flex items-center text-gray-500">
        of {displayQuantity}
      </span>
    </div>
  );
};

export default LineItemQuantityInput;
