import { useState } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import Button from './Button';
import BtnLoadingSpinner from './BtnLoadingSpinner';
import { useFormContext } from '../../context/form-context';

const publishingOptions = [
  {
    title: 'Save',
    description: 'This will save the product.',
    action: 'save',
    current: true,
  },
  {
    title: 'Permanently Delete',
    description: 'This action cannot be undone.',
    classes: 'text-red-700 data-[focus]:bg-red-600 data-[focus]:text-white',
    action: 'delete',
    current: false,
  },
];

const ButtonDropdown = ({ onDelete, onDiscard, onSave }) => {
  const [selected, setSelected] = useState(publishingOptions[0]);
  const { formEdited, isSubmitting } = useFormContext();
  console.log(formEdited);
  const handleAction = (selectedOption) => {
    if (selectedOption.action === 'save' && (!formEdited || isSubmitting)) {
      return; // Prevent action if the form is not edited or if it's submitting
    }

    setSelected(selectedOption);

    switch (selectedOption.action) {
      case 'save':
        onSave();
        break;
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-row gap-x-2">
      <Button
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-[.8125rem] font-semibold !text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:!bg-gray-50"
        onClick={onDiscard}
      >
        Discard
      </Button>
      <Listbox value={selected} onChange={handleAction}>
        <Label className="sr-only">Save, delete, discard</Label>
        <div className="relative">
          <div
            className={`inline-flex divide-x divide-indigo-900 rounded-md shadow-sm cursor-pointer ${isSubmitting ? 'opacity-50 !cursor-not-allowed' : ''}`}
          >
            <div
              className={`inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-white shadow-sm ${
                selected.action === 'save' && (!formEdited || isSubmitting)
                  ? 'bg-indigo-400 cursor-not-allowed'
                  : 'bg-indigo-800 hover:bg-indigo-600'
              }`}
              onClick={() => {
                if (selected.action === 'save' && formEdited && !isSubmitting) {
                  onSave(); // Call the onSave function when the conditions are met
                }
              }}
            >
              <p className="text-sm font-semibold">
                {isSubmitting && selected.action === 'save' ? (
                  <BtnLoadingSpinner className="w-4 h-4 text-white mr-2" />
                ) : (
                  publishingOptions[0].title
                )}
              </p>
            </div>
            <ListboxButton
              className={`inline-flex items-center rounded-l-none rounded-r-md bg-indigo-800 p-2 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50 ${isSubmitting ? 'cursor-not-allowed' : ''}`}
            >
              <span className="sr-only">Save, delete, discard</span>
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-white"
              />
            </ListboxButton>
          </div>

          <ListboxOptions
            transition
            className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {publishingOptions.map((option) => (
              <ListboxOption
                key={option.title}
                value={option}
                disabled={option.title === 'Save' && !formEdited} // Disable if it's the Save option and formEdited is false
                className={`group cursor-pointer select-none p-4 text-sm ${
                  option.classes
                    ? option.classes
                    : 'text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white'
                } ${
                  option.title === 'Save' && !formEdited
                    ? 'opacity-50 !cursor-not-allowed data-[focus]:!text-gray-400 data-[focus]:!bg-gray-200'
                    : ''
                }`} // Apply disabled styles
              >
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p
                      className={`font-normal group-data-[selected]:font-semibold ${
                        option.title === 'Save' && !formEdited
                          ? 'text-gray-400'
                          : ''
                      }`}
                    >
                      {option.title}
                    </p>
                    <span
                      className={`text-indigo-600 group-data-[focus]:text-white ${
                        option.title === 'Save' && !formEdited ? 'hidden' : ''
                      } [.group:not([data-selected])_&]:hidden`}
                    >
                      <CheckIcon aria-hidden="true" className="h-5 w-5" />
                    </span>
                  </div>
                  {option.description && (
                    <p
                      className={`mt-2 ${
                        option.title === 'Permanently Delete'
                          ? 'text-gray-600 group-data-[focus]:text-white'
                          : 'text-gray-600 group-data-[focus]:text-indigo-200'
                      } ${
                        option.title === 'Save' && !formEdited
                          ? 'text-gray-400'
                          : ''
                      }`}
                    >
                      {option.description}
                    </p>
                  )}
                </div>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
};

export default ButtonDropdown;
