import { XMarkIcon } from '@heroicons/react/20/solid';

const DialogClose = ({ setOpen }) => {
  return (
    <button
      type="button"
      className="rounded-md bg-transparent text-gray-500/90 hover:text-gray-500 focus:outline-none"
      onClick={() => setOpen(false)}
    >
      <span className="sr-only">Close</span>
      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
};

export default DialogClose;
