import Checkbox from '../../../../../../../../shared/components/UIElements/Checkbox';
import ManualTracking from './ManualTracking';

const FulfillmentForm = () => {
  return (
    <div className="mt-4 mb-1 w-full">
      <ManualTracking />
      <div className="flex flex-col mt-4 gap-x-6 py-2 px-4 my-2 rounded-xl bg-gray-500/5 ring-1 ring-gray-900/20">
        <p className="font-semibold text-[.8125rem] mb-1">
          Notify customer of shipment
        </p>
        <Checkbox
          text="Send shipment details to the customer"
          id="notify_customer"
        />
      </div>
    </div>
  );
};

export default FulfillmentForm;
