import Popover from '../../../../../../shared/components/UIElements/Popover';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

const ItemActionReason = ({ reason, title }) => {
  return (
    <div className="flex w-full">
      <div className="flex items-center gap-x-6 bg-gray-900/5 px-6 py-2.5 rounded-b-lg w-full sm:px-3.5 border-t border-gray-900/10">
        <div className="w-full flex items-center gap-x-2">
          <p className="text-[.8125rem] leading-5 text-gray-900">
            <strong className="font-semibold">{title}</strong>
            <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            {reason.reason}
          </p>
          {reason.message && (
            <Popover content={reason.message}>
              <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemActionReason;
