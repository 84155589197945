import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiRequest } from './Api/BaseApi';

const JsonResponseWrapper = ({ children }) => {
  const location = useLocation();
  const [jsonResponse, setJsonResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.pathname.endsWith('.json')) {
      const apiUrl = `${location.pathname.slice(0, -5)}`;

      ApiRequest({
        method: 'GET',
        endpoint: apiUrl,
        requireAuth: true,
      })
        .then((response) => {
          setJsonResponse(response.data);
        })
        .catch((error) => {
          console.error('Error fetching JSON:', error);
          setError('Failed to fetch JSON data.');
        });
    }
  }, [location.pathname]);

  if (location.pathname.endsWith('.json')) {
    return (
      <div>
        {error ? (
          <div>
            <h1>Error Fetching JSON</h1>
            <pre>{error}</pre>
          </div>
        ) : (
          <pre>{JSON.stringify(jsonResponse, null, 2)}</pre>
        )}
      </div>
    );
  }

  return children;
};

export default JsonResponseWrapper;
