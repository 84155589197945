import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/20/solid';

import Input from '../../../../../../../../shared/components/UIElements/Inputs/Input';

const initialTracking = [
  {
    trackingNumber: '',
    shippingCarrier: '',
  },
];

const ManualTracking = () => {
  const [manualTracking, setManualTracking] = useState(false);
  const [trackingList, setTrackingList] = useState(initialTracking);

  const handleAddNewTracking = () => {
    if (trackingList.length < 5) {
      setTrackingList([
        ...trackingList,
        {
          trackingNumber: '',
          shippingCarrier: '',
        },
      ]);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedTrackingList = trackingList.map((tracking, i) => {
      if (i === index) {
        return { ...tracking, [field]: value };
      }
      return tracking;
    });
    setTrackingList(updatedTrackingList);
  };

  const handleDeleteTracking = (index) => {
    const updatedTrackingList = trackingList.filter((_, i) => i !== index);
    setTrackingList(updatedTrackingList);
  };

  const toggleManualTracking = () => {
    setTrackingList(initialTracking);
    setManualTracking(!manualTracking);
  };

  return (
    <div className="flex flex-col w-full">
      {manualTracking !== true ? (
        <div
          className="flex mt-4 gap-x-1 text-[.8125rem] text-indigo-500 items-center cursor-pointer underline-offset-1 hover:underline"
          onClick={toggleManualTracking}
        >
          <PlusCircleIcon className="w-auto h-5" />
          <p>Manually add tracking</p>
        </div>
      ) : (
        <div className="p-3 ring-1 ring-gray-900/20 rounded-lg">
          <div className="flex flex-row items-center mb-2">
            <p className="font-semibold text-[.8125rem] flex-1">
              Tracking information
            </p>
            <div
              className="p-2 rounded-lg bg-gray-500/20 text-gray-900 hover:bg-gray-500/30 cursor-pointer"
              onClick={toggleManualTracking}
            >
              <TrashIcon className="w-auto h-3.5 text-gray-600" />
            </div>
          </div>

          {/* Render dynamic input fields */}
          {trackingList.map((tracking, index) => (
            <div
              key={index}
              className={`flex flex-row items-center gap-x-2 sm:gap-x-4 w-full mt-2 ${index < trackingList.length - 1 ? 'border-b pb-4 border-gray-300' : ''}`}
            >
              <div className="mt-2 sm:mt-0 w-full">
                <Input
                  label={`Tracking number ${index !== 0 ? index + 1 : ''}`}
                  id={`tracking_number_${index}`}
                  value={tracking.trackingNumber}
                  onChange={(e) =>
                    handleInputChange(index, 'trackingNumber', e.target.value)
                  }
                  className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                />
              </div>
              <div className="mt-2 sm:mt-0 w-full">
                <Input
                  label={`Shipping carrier ${index !== 0 ? index + 1 : ''}`}
                  id={`shipping_carrier_${index}`}
                  value={tracking.shippingCarrier}
                  onChange={(e) =>
                    handleInputChange(index, 'shippingCarrier', e.target.value)
                  }
                  className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                />
              </div>

              {/* Delete dynamic input fields */}
              {trackingList.length > 1 && (
                <div
                  className="p-2 relative top-5 sm:top-4 rounded-lg hover:bg-red-500/20 hover:text-red-900 cursor-pointer"
                  onClick={() => handleDeleteTracking(index)}
                >
                  <TrashIcon className="w-auto h-3.5 text-red-600" />
                </div>
              )}
            </div>
          ))}
          {trackingList.length < 5 && (
            <div
              className="flex mt-4 gap-x-1 text-[.8125rem] text-indigo-500 items-center cursor-pointer underline-offset-1 hover:underline"
              onClick={handleAddNewTracking}
            >
              <PlusCircleIcon className="w-auto h-5" />
              <p>Add another tracking number</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ManualTracking;
