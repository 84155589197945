import { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

// import {
//   Dialog,
//   DialogBackdrop,
//   DialogPanel,
//   Disclosure,
//   DisclosureButton,
//   DisclosurePanel,
// } from '@headlessui/react';
// import { XMarkIcon } from '@heroicons/react/24/outline';

// const sortOptions = [
//   { name: 'Most Popular', href: '#' },
//   { name: 'Best Rating', href: '#' },
//   { name: 'Newest', href: '#' },
// ];

const VariantTableFilters = ({
  onFilterChange,
  filters,
  filterData,
  setFilterData,
  sortOptions = [],
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (sectionId, optionValue, event) => {
    onFilterChange(sectionId, optionValue, event.target.checked);
  };

  return (
    <>
      <div className="border-t border-gray-200 pt-4">
        {/* Mobile filter dialog */}
        {/* <Dialog
          open={open}
          onClose={setOpen}
          className="relative z-40 sm:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              

              {filters.map((section) => (
                <Disclosure
                  key={section.name}
                  as="div"
                  className="border-t border-gray-200 px-4 py-6"
                >
                  <h3 className="-mx-2 -my-3 flow-root">
                    <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="pt-6">
                    <div className="space-y-6">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            defaultValue={option.value}
                            id={`filter-mobile-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            onChange={(e) =>
                              handleChange(section.id, option.value, e)
                            }
                            checked={
                              filterData[section.id]?.includes(option.value) ||
                              false
                            }
                          />
                          <label
                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-500"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              ))}
              {Object.keys(filterData).some(
                (key) => filterData[key].length > 0
              ) && (
                <div className="border-t border-gray-200 px-4 py-6">
                  <h3 className="-mx-2 -my-3 flow-root">
                    <button
                      className="group flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400"
                      onClick={() => {
                        setFilterData({});
                        setOpen(false); // Remove if this gets annoying
                      }}
                    >
                      <span className="font-medium text-gray-900">
                        Clear filters
                      </span>
                      <span className="ml-6 flex items-center">
                        <XCircleIcon
                          aria-hidden="true"
                          className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                        />
                      </span>
                    </button>
                  </h3>
                </div>
              )}
            </DialogPanel>
          </div>
        </Dialog> */}

        <div className="mx-auto max-w-3xl px-4 text-center lg:max-w-7xl">
          <section aria-labelledby="filter-heading" className="">
            <h2 id="filter-heading" className="sr-only">
              Product filters
            </h2>

            <div className="flex items-center justify-between w-full">
              {sortOptions.length > 0 && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      />
                    </MenuButton>
                  </div>

                  <MenuItems
                    transition
                    className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <MenuItem key={option}>
                          <a
                            href={option.href}
                            className="block px-4 py-2 text-sm font-medium text-gray-900 data-[focus]:bg-gray-100"
                          >
                            {option.name}
                          </a>
                        </MenuItem>
                      ))}
                    </div>
                  </MenuItems>
                </Menu>
              )}

              <div
                className={`flex items-center space-x-2 ${
                  sortOptions.length === 0 ? 'ml-auto' : ''
                }`}
              >
                {Object.keys(filterData).some(
                  (key) => filterData[key].length > 0
                ) && (
                  <button
                    type="button"
                    className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none"
                    onClick={() => setFilterData({})} // Clears all filters
                  >
                    <XCircleIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Clear Filters</span>
                  </button>
                )}
                <PopoverGroup className="flex items-center space-x-4">
                  {filters.map((section, sectionIdx) => {
                    const selectedCount = filterData[section.id]?.length || 0;
                    return (
                      <Popover
                        key={section.name}
                        className="relative inline-block text-left"
                      >
                        <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          <span
                            className={`${selectedCount > 0 && 'text-indigo-700'}`}
                          >
                            {section.name}
                          </span>
                          {selectedCount > 0 && (
                            <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                              {selectedCount}
                            </span>
                          )}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          />
                        </PopoverButton>

                        <PopoverPanel
                          transition
                          className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                defaultValue={option.value}
                                id={`filter-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={(e) =>
                                  handleChange(section.id, option.value, e)
                                }
                                checked={
                                  filterData[section.id]?.includes(
                                    option.value
                                  ) || false
                                }
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </PopoverPanel>
                      </Popover>
                    );
                  })}
                </PopoverGroup>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default VariantTableFilters;
