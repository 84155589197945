import { Link } from 'react-router-dom';
import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/UIElements/Button';

const NotFound = () => {
  const goBack = () => {
    window.history.back();
  };
  return (
    <main className="lg:grid lg:min-h-full lg:place-items-center px-6 py-6">
      <Card className="w-full lg:w-2/3 xl:w-2/3">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              onClick={goBack}
              className="rounded-md bg-indigo-800 px-3.5 py-2.5 text-[.8125rem] font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back
            </Button>
            <Link
              to="/tickets"
              className="text-[.8125rem] font-semibold text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </Card>
    </main>
  );
};

export default NotFound;
