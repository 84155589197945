import { useRef, useEffect } from 'react';

const Checkbox = ({
  text,
  subtext,
  value,
  name,
  id,
  checked,
  onChange,
  indeterminate,
  className,
  description = null,
}) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
      // Explicitly set checked state even when indeterminate is true
      checkboxRef.current.checked = checked;
    }
  }, [checked, indeterminate]);

  if (!description) {
    return (
      <div className={`relative flex items-center w-full ${className}`}>
        <div className="flex h-6 items-center">
          <input
            ref={checkboxRef}
            id={id}
            aria-describedby={name}
            name={name}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
            checked={checked} // Bind the checked attribute to the checked prop
            onChange={onChange} // Handle changes
            value={value}
          />
        </div>
        <div className="ml-3 text-[.8125rem] leading-5">
          <label
            htmlFor={id}
            className="text-gray-900 cursor-pointer capitalize"
          >
            {text}
          </label>{' '}
          <span id={id} className="text-gray-500">
            <span className="sr-only">{text} </span> {subtext && subtext}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            ref={checkboxRef}
            id={id}
            aria-describedby={`${id}-description`}
            name={name}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
            checked={checked} // Bind the checked attribute to the checked prop
            onChange={onChange} // Handle changes
            value={value}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor={id} className="font-medium text-gray-900">
            {text}
          </label>
          <p id={`${id}-description`} className="text-gray-500">
            {description}
          </p>
          <span className="sr-only">{text} </span> {subtext && subtext}
          <span className="sr-only">{description} </span>
        </div>
      </div>
    );
  }
};

export default Checkbox;
