import { useEffect, useState } from 'react';

import { getIntegrations } from '../../../../../../utils/Api/Integrations/Get';
import OrderIntegration from './OrderIntegration';
import Card from '../../../../../../shared/components/UIElements/Card';

const OrderIntegrations = ({ order, riskData = {}, className }) => {
  const [integrations, setIntegrations] = useState(null);
  useEffect(() => {
    // Define an async function inside the effect to fetch users
    const fetchData = async () => {
      try {
        const data = await getIntegrations(); // Wait to resolve
        setIntegrations(data.data.integrations); // Update the state with the fetched data
      } catch (error) {
        console.error('Failed to fetch users:', error);
        // Handle error here, e.g., set error state or show a notification
      }
    };
    fetchData(); // Call the async function
  }, []);

  return (
    <Card className={className}>
      <div className="text-[.8125rem]">
        <div className="flex items-center space-between mb-2">
          <span className="flex-1 font-semibold text-gray-900">
            Integrations
          </span>
        </div>
        {integrations &&
          integrations
            // Filter out duplicates by name (adjust 'name' to the appropriate unique property)
            .filter(
              (integration, index, self) =>
                index === self.findIndex((t) => t.name === integration.name)
            )
            .map((item, idx, filteredIntegrations) =>
              item.category === 'storefront' ? (
                item.active ? (
                  <OrderIntegration
                    className={`${
                      idx !== 0 && idx !== filteredIntegrations.length - 1
                        ? 'border-b border-gray-900/10'
                        : ''
                    }`}
                    key={idx}
                    item={item}
                    order={order}
                    riskData={riskData}
                  />
                ) : (
                  <p className="italic text-gray-300" key={idx}>
                    No integrations available
                  </p>
                )
              ) : null
            )}
      </div>
    </Card>
  );
};

export default OrderIntegrations;
