import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';

const PreOrderEvent = ({
  showPreOrderCalendar,
  setShowPreOrderCalendar,
  preOrderDate = null,
  handleChange,
  setDaysUntilPreOrder,
}) => {
  const handleShowCalendar = (e, closeMenu) => {
    e.preventDefault();
    setShowPreOrderCalendar(!showPreOrderCalendar);
    closeMenu(); // Close the menu after toggling the calendar
  };

  const handleCloseCalendar = (e, closeMenu) => {
    e.preventDefault();
    setShowPreOrderCalendar(false);
    closeMenu(); // Close the menu after toggling the calendar
  };

  const handleRemovePreOrderDate = (e, closeMenu) => {
    const removeEvent = {
      target: {
        name: 'internal_data.pre_order_date',
        value: null,
      },
    };
    closeMenu();
    setDaysUntilPreOrder(null);
    handleChange(removeEvent);
  };

  return (
    <ol className="divide-y divide-gray-100 text-sm">
      {preOrderDate ? (
        <li className="relative flex space-x-6 xl:static">
          <dl className="flex flex-col text-gray-500 xl:flex-row">
            <div className="flex items-start space-x-3">
              <dt>
                <span className="sr-only">Date</span>
                <CalendarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <time dateTime={preOrderDate}>
                  {preOrderDate.split('T')[0]}
                </time>
              </dd>
            </div>
          </dl>
          <Menu
            as="div"
            className="top-6 xl:relative xl:top-auto xl:self-center"
          >
            {({ close }) => (
              <>
                <div>
                  <MenuButton className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      {({ active }) => (
                        <span
                          onClick={(e) => handleShowCalendar(e, close)}
                          className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                            active ? 'bg-gray-100 text-gray-900' : ''
                          }`}
                        >
                          Edit
                        </span>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <span
                          onClick={(e) => handleRemovePreOrderDate(e, close)}
                          className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                            active ? 'bg-gray-100 text-gray-900' : ''
                          }`}
                        >
                          Delete
                        </span>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <span
                          onClick={(e) => handleCloseCalendar(e, close)}
                          className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                            active ? 'bg-gray-100 text-gray-900' : ''
                          }`}
                        >
                          Cancel
                        </span>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </>
            )}
          </Menu>
        </li>
      ) : (
        <li className="relative flex space-x-6 xl:static">
          <dl className="flex flex-col items-center text-gray-500 xl:flex-row">
            <div className="flex items-center space-x-3">
              <dt className="mt-0.5">
                <span className="sr-only">Date</span>
                <CalendarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <time>No dates are set...</time>
              </dd>
            </div>
          </dl>
          <Menu
            as="div"
            className="absolute right-0 lg:relative lg:top-6 xl:relative xl:top-auto xl:self-center"
          >
            {({ close }) => (
              <>
                <div>
                  <MenuButton className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      {({ active }) => (
                        <span
                          onClick={(e) => handleShowCalendar(e, close)}
                          className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                            active ? 'bg-gray-100 text-gray-900' : ''
                          }`}
                        >
                          Add
                        </span>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <span
                          onClick={(e) => handleCloseCalendar(e, close)}
                          className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                            active ? 'bg-gray-100 text-gray-900' : ''
                          }`}
                        >
                          Cancel
                        </span>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </>
            )}
          </Menu>
        </li>
      )}
    </ol>
  );
};

export default PreOrderEvent;
