import { Fragment } from 'react';
import {
  InboxIcon,
  CurrencyDollarIcon,
  TagIcon,
} from '@heroicons/react/24/outline';

import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import Stats from '../../../shared/components/UIElements/Reports/Stats';
import DashboardOrderTable from '../components/OrderTable/DashboardOrderTable';

const Dashboard = () => {
  const totalItems = '3';

  const stats = [
    {
      id: 1,
      name: 'Total Orders',
      stat: totalItems,
      icon: InboxIcon,
      change: '122',
      changeType: 'increase',
    },
    {
      id: 2,
      name: 'Avg. Order Total',
      stat: '58.16%',
      icon: CurrencyDollarIcon,
      change: '5.4%',
      changeType: 'increase',
    },
    {
      id: 3,
      name: 'Total Items',
      stat: '24.57%',
      icon: TagIcon,
      change: '3.2%',
      changeType: 'decrease',
    },
  ];

  return (
    <Fragment>
      <Container>
        <PageHeader pageTitle="Dashboard" />
        <div className="mt-4">
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Last 30 days
          </h3>
          <Stats stats={!totalItems ? null : stats} />
        </div>
        <div className="mt-4 flex gap-x-4">
          <DashboardOrderTable />
        </div>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
