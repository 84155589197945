import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { marked } from 'marked';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TextAreaPreview = ({ defaultValue, ...props }) => {
  const [content, setContent] = useState(defaultValue);
  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  const handleContentChange = (event) => {
    setContent(event.target.value);
    props.descriptionChange(event);
  };

  return (
    <>
      <label
        htmlFor="about"
        className="block text-[.8125rem] font-normal leading-5 text-gray-900"
      >
        {props.label}
      </label>
      <div className="mt-2">
        <Tab.Group>
          {({ selectedIndex }) => (
            <>
              <Tab.List className="flex items-center">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-indigo-800 text-white hover:bg-indigo-600'
                        : 'bg-white text-gray-500 hover:bg-indigo-100 hover:text-gray-900',
                      'rounded-md border border-transparent px-3 py-1.5 text-[.8125rem] font-medium'
                    )
                  }
                >
                  Write
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-indigo-800 text-white hover:bg-indigo-600'
                        : 'bg-white text-gray-500 hover:bg-indigo-100 hover:text-gray-900',
                      'ml-2 rounded-md border border-transparent px-3 py-1.5 text-[.8125rem] font-medium'
                    )
                  }
                >
                  Preview
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                  <label htmlFor={props?.id} className="sr-only">
                    {props?.label}
                  </label>
                  <div>
                    <textarea
                      rows={8}
                      name={props?.name}
                      id={props?.id}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
                      placeholder={props?.placeHolder}
                      defaultValue={content}
                      onChange={handleContentChange}
                    />
                  </div>
                </Tab.Panel>
                <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                  <div className="border border-dashed rounded-lg py-4 px-4">
                    {content ? (
                      <div
                        className="prose"
                        dangerouslySetInnerHTML={{ __html: marked(content) }}
                      />
                    ) : (
                      <div className="text-[0.8125rem] text-gray-400 italic">
                        No content available
                      </div>
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </div>
    </>
  );
};

export default TextAreaPreview;
