import React from 'react';
import LineItemGroup from './LineItemGroup';

const statusOrder = ['Received', 'On Hold', 'Batched', 'Cancelled', 'Removed'];

const LineItems = ({ lineItems, allItems, handleSaveField, orderId }) => {
  return (
    <div>
      {Object.keys(lineItems)
        .filter((status) => status !== 'Shipped')
        .sort((a, b) => statusOrder.indexOf(a) - statusOrder.indexOf(b))
        .map((status) =>
          Object.keys(lineItems[status]).map(
            (key) =>
              lineItems[status][key].length > 0 && (
                <LineItemGroup
                  key={`${status}-${key}`}
                  items={lineItems[status][key]} // Pass only the specific items array
                  handleSaveField={handleSaveField}
                  orderId={orderId}
                  status={status}
                  fulfillmentOrder={key}
                  allLineItems={allItems}
                />
              )
          )
        )}
    </div>
  );
};

export default LineItems;
