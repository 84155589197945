import { Link } from 'react-router-dom';

import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import Button from '../../../shared/components/UIElements/Button';

const Shipments = () => {
  return (
    <Container>
      <PageHeader pageTitle="Products">
        <Link to="/products/create">
          <Button>Shipments</Button>
        </Link>
      </PageHeader>
      <div className="mt-4 flow-root">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:overflow-x-visible overflow-x-auto">
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">No shipments available</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Shipments;
