// React Components
import React, { forwardRef, useImperativeHandle } from 'react';

// Shared Components
import { usePopoutNotification } from '../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useFormContext } from '../../../../shared/context/form-context';

// Page Components
import VariantProductImage from './components/VariantProductImage';
import VariantInformation from './components/VariantInformation';
import VariantOptions from './components/VariantOptions';
import VariantInventory from './components/VariantInventory';
import VariantShipping from './components/VariantShipping';

const VariantForm = (
  { product, setProduct, committed, inventoryAvailable, onFormSubmit },
  ref
) => {
  const { fields, handleChange } = useFormContext();

  const { notify } = usePopoutNotification();

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      onFormSubmit(fields);
    },
  }));

  return (
    <form ref={ref} className="w-full mt-6">
      <div className="w-full md:w-3/4 mx-auto">
        <VariantProductImage
          fields={fields}
          product={product}
          setProduct={setProduct}
          notify={notify}
          onImageChange={handleChange}
        />

        <VariantInformation fields={fields} handleChange={handleChange} />

        <VariantOptions
          fields={fields}
          product={product}
          handleChange={handleChange}
        />

        <VariantInventory
          fields={fields}
          committed={committed}
          inventoryAvailable={inventoryAvailable}
          handleChange={handleChange}
        />

        <VariantShipping fields={fields} handleChange={handleChange} />
      </div>
    </form>
  );
};

export default forwardRef(VariantForm);
