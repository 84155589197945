import { ApiRequest } from '../BaseApi';

// ====================================
// For integration list
export const getIntegrations = async () => {
  return ApiRequest({
    method: 'get',
    endpoint: '/integrations',
  });
};

export const getIntegration = async (id) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/integrations/${id}`,
  });
};
