import React, { forwardRef, useImperativeHandle } from 'react';

// Shared components
import { usePopoutNotification } from '../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import { useFormContext } from '../../../../shared/context/form-context';

// Page components
import ProductStatus from './components/ProductStatus';
import ProductImages from './components/Images/ProductImages';
import ProductInformation from './components/Information/ProductInformation';
import ProductPricing from './components/Pricing/ProductPricing';
import ProductInventory from './components/Inventory/ProductInventory';
import ProductVariants from './components/Variants/ProductVariants';
import ProductOrganization from './components/Organization/ProductOrganization';
import ProductIntegrations from './components/Integrations/ProductIntegrations';

const ProductForm = ({ onFormSubmit, committed }, ref) => {
  const { fields, handleChange } = useFormContext();
  const { notify } = usePopoutNotification();

  // console.log('[INFO] - [FIELDS]', fields);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      onFormSubmit(fields);
    },
  }));

  return (
    <form
      ref={ref}
      className="grid grid-cols-1 md:flex md:flex-1 md:gap-x-4 mt-6"
    >
      <div className="w-full md:w-3/4">
        <ProductInformation
          title={fields?.title}
          bodyHtml={fields?.body_html}
          handleChange={handleChange}
        />
        <ProductImages
          fields={fields}
          handleChange={handleChange}
          notify={notify}
        />
        {(!fields?.variants || fields?.variants?.length <= 1) && (
          <>
            <ProductPricing
              handleChange={handleChange}
              variant={fields?.variants[0]}
            />
            <ProductInventory
              handleChange={handleChange}
              variant={fields?.variants[0]}
            />
          </>
        )}

        <ProductVariants
          fields={fields}
          handleChange={handleChange}
          committed={committed}
        />
      </div>
      <aside className="w-full mt-4 md:mt-0 md:w-1/4">
        <ProductStatus productStatus={fields?.status} onChange={handleChange} />
        <ProductOrganization handleChange={handleChange} fields={fields} />
        <ProductIntegrations
          placeholder={!fields ? '' : 'No id available'}
          productId={fields?.id}
        />
      </aside>
    </form>
  );
};

export default forwardRef(ProductForm);
