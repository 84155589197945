import MergeItem from './MergeItem';

const MergeList = ({ items, selected, mergeItemId, onMergeItemSelected }) => {
  const selectedFulfillmentOrderId = selected.fulfillment_order.id;

  // Extract fulfillment_order.id numbers into an array and ensure uniqueness
  const fulfillmentOrderIds = [
    ...new Set(
      items
        .filter(
          (item) =>
            item?.fulfillment_order?.id &&
            item.fulfillment_order.id !== selectedFulfillmentOrderId
        )
        .map((item) => item.fulfillment_order.id)
    ),
  ];

  return (
    <ul className="divide-y divide-gray-100">
      {fulfillmentOrderIds.map((fulfillmentOrderId, idx) => {
        // Find matching items for the current fulfillmentOrderId
        const matchingItems = items.filter(
          (item) => item.fulfillment_order.id === fulfillmentOrderId
        );

        return (
          <MergeItem
            key={idx}
            items={matchingItems}
            mergeItemId={mergeItemId} // Pass the merge item ID to MergeItem
            onMergeItemSelected={onMergeItemSelected}
            fulfillmentOrderId={fulfillmentOrderId}
          />
        );
      })}
    </ul>
  );
};

export default MergeList;
