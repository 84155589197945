import React, { useLayoutEffect, useState, forwardRef } from 'react';
import TableHeaderActions from './components/TableHeaderActions';

const SelectTable = forwardRef(
  (
    { items, RowComponent, tableHeaders, rowProps, className, handleSearch },
    ref
  ) => {
    const [selectedItems, setSelectedItems] = useState([]);

    useLayoutEffect(() => {
      if (ref && ref.current) {
        ref.current.indeterminate =
          selectedItems.length > 0 && selectedItems.length < items.length;
        ref.current.checked = selectedItems.length === items.length;
      }
    }, [selectedItems, items.length, ref]);

    const toggleAll = (event) => {
      const isChecked = event.target.checked;
      if (isChecked) {
        setSelectedItems(items.map((item) => item._id));
      } else {
        setSelectedItems([]);
      }
    };

    const handleSelectToggle = (id) => {
      setSelectedItems((prevSelectedItems) => {
        const newSelectedItems = new Set(prevSelectedItems);
        if (newSelectedItems.has(id)) {
          newSelectedItems.delete(id);
        } else {
          newSelectedItems.add(id);
        }
        return Array.from(newSelectedItems);
      });
    };

    return (
      <div className="overflow-x-visible">
        <div className="inline-block min-w-full align-middle ring-1 ring-gray-700/20 rounded-xl bg-white">
          <TableHeaderActions onSearch={handleSearch} />

          <div className={`overflow-x-auto block w-full ${className}`}>
            <table className="min-w-full divide-y divide-gray-300 overflow-x-auto">
              <thead>
                <tr>
                  <th scope="col" className="relative px-4 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      checked={selectedItems.length === items.length}
                      onChange={toggleAll}
                      ref={ref}
                    />
                  </th>
                  {tableHeaders()}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 rounded-b-xl">
                {items.map((item) => (
                  <RowComponent
                    key={item._id}
                    item={item}
                    selectedItems={selectedItems}
                    isSelected={selectedItems.includes(item._id)}
                    onSelectToggle={() => handleSelectToggle(item._id)}
                    {...rowProps}
                  />
                ))}
              </tbody>
            </table>
            {/* Display a message if no items are found */}
            {items.length === 0 && (
              <div className="px-4 py-2 text-sm text-gray-500">
                No results found for the current search criteria.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

SelectTable.displayName = 'SelectTable';

export default SelectTable;
