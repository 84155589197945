import React, { useState, useEffect } from 'react';
import Card from '../../../../../../shared/components/UIElements/Card';
import PaymentCardHeader from './components/PaymentCardHeader';
import PaymentCardDiscounts from './components/PaymentCardDiscounts';
import PaymentCardSubtotal from './components/PaymentCardSubtotal';
import PaymentCardShipping from './components/PaymentCardShipping';
import PaymentCardTaxes from './components/PaymentCardTaxes';
import PaymentCardTotal from './components/PaymentCardTotal';
import PaymentCardFooter from './components/PaymentCardFooter';

const PaymentCardContent = ({ order }) => {
  const [paymentData, setPaymentData] = useState({
    subtotal: 0,
    discount: 0,
    shipping: 0,
    taxes: 0,
    total: 0,
    discountCodes: [],
    shippingLines: [],
    taxLines: [],
  });

  useEffect(() => {
    if (order) {
      const subtotal = order.line_items.reduce(
        (sum, item) => sum + parseFloat(item.price),
        0
      );
      const discount = parseFloat(order.total_discounts) || 0;
      const shipping = order.shipping_lines.reduce(
        (sum, line) => sum + parseFloat(line.price),
        0
      );
      const total = parseFloat(order.total_price) || 0;
      const taxes = parseFloat(order.total_tax) || 0;

      setPaymentData({
        subtotal,
        discount,
        shipping,
        total,
        taxes,
        discountCodes: order.discount_codes,
        shippingLines: order.shipping_lines,
        taxLines: order.tax_lines,
      });
    }
  }, [order]);

  return (
    <Card>
      <PaymentCardHeader paymentStatus={order.financial_status} />
      <div className="mt-2 grid grid-cols-1 w-full rounded-lg ring-1 ring-gray-900/20">
        <div className="px-4 py-2">
          <dl className="">
            <PaymentCardSubtotal
              lineItems={order.line_items}
              subtotal={paymentData.subtotal}
            />
            <PaymentCardDiscounts discounts={paymentData.discountCodes} />
            <PaymentCardShipping shippingLines={paymentData.shippingLines} />
            <PaymentCardTaxes
              taxes={paymentData.taxes}
              taxLines={paymentData.taxLines}
            />
            <PaymentCardTotal total={paymentData.total} />
          </dl>
        </div>
        <PaymentCardFooter total={paymentData.total} />
      </div>
    </Card>
  );
};

export default PaymentCardContent;
