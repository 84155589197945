import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import Logo from '../../../../shared/images/test-logo.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MainNavHeader = () => {
  return (
    <Menu as="div" className="inline-block text-left flex-1">
      <div>
        <Menu.Button className="inline-flex w-full text-left justify-center gap-x-1.5 rounded-md px-3 py-2 text-[.8125rem] font-normal text-white tracking-wide hover:text-gray-200">
          <div className="flex w-full items-center justify-between">
            <div className="flex-1">Dailey Project</div>
            <div>
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 min-w-52 w-fit origin-top-right divide-y divide-white/10 rounded-md bg-gray-800 shadow-lg border border-white/10 ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="#"
                  className={classNames(
                    active
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-400 hover:text-white hover:bg-gray-900',
                    'group flex gap-x-3 rounded-md p-2 text-[.8125rem] leading-5 font-semibold'
                  )}
                >
                  <img
                    src={Logo}
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  Dailey Project
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="#"
                  className={classNames(
                    active
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-400 hover:text-white hover:bg-gray-900',
                    'group flex gap-x-2 items-center rounded-md p-2 text-[.8125rem] leading-5 font-semibold'
                  )}
                >
                  <PlusIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                  New company
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default MainNavHeader;
