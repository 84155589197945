import { useState, useEffect } from 'react';
import {
  CheckCircleIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';

const alertStyles = {
  success: {
    bgColor: 'bg-green-50',
    borderColor: 'border-green-400',
    textColor: 'text-green-800',
    iconColor: 'text-green-400',
    Icon: CheckCircleIcon,
  },
  error: {
    bgColor: 'bg-red-50',
    borderColor: 'border-red-400',
    textColor: 'text-red-800',
    iconColor: 'text-red-400',
    Icon: XCircleIcon,
  },
  warning: {
    bgColor: 'bg-yellow-50',
    borderColor: 'border-yellow-400',
    textColor: 'text-yellow-800',
    iconColor: 'text-yellow-400',
    Icon: ExclamationTriangleIcon,
  },
  info: {
    bgColor: 'bg-blue-50',
    borderColor: 'border-blue-400',
    textColor: 'text-blue-800',
    iconColor: 'text-blue-400',
    Icon: InformationCircleIcon,
  },
  default: {
    bgColor: 'bg-gray-50',
    borderColor: 'border-gray-400',
    textColor: 'text-gray-800',
    iconColor: 'text-gray-400',
    Icon: QuestionMarkCircleIcon,
  },
};

const ContentAlert = ({ contentAlert, className }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Whenever notification changes and has a message, show the notification
    if (contentAlert?.message) {
      setShow(true);
      // Automatically hide the notification after some time
      const timer = setTimeout(() => setShow(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [contentAlert]);

  const { type = 'default', header, message, actions, close } = contentAlert;
  const { bgColor, borderColor, textColor, iconColor, Icon } =
    alertStyles[type];

  return (
    <div
      className={`rounded-md ${bgColor} border-l-4 ${borderColor} p-4 my-2 ${className}`}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {header && (
            <h3 className={`text-[.8125rem] font-medium ${textColor}`}>
              {header}
            </h3>
          )}
          <div className={`${header && 'mt-2'} text-[.8125rem] ${textColor}`}>
            <p>{message}</p>
          </div>
          {actions && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  type="button"
                  className={`rounded-md ${bgColor} px-2 py-1.5 text-[.8125rem] font-medium ${textColor} hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2`}
                >
                  View
                </button>
                <button
                  type="button"
                  className={`ml-3 rounded-md ${bgColor} px-2 py-1.5 text-[.8125rem] font-medium ${textColor} hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2`}
                >
                  Dismiss
                </button>
              </div>
            </div>
          )}
        </div>
        {close && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex rounded-md ${bgColor} p-1.5 ${textColor} hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentAlert;
