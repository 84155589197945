import InventoryFlyOut from './InventoryFlyOut';

const InventoryTable = ({ variant, handleChange }) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-center text-[.8125rem] font-semibold text-gray-900 sm:pl-0"
          >
            <span className="sr-only">Location</span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-[.8125rem] font-semibold text-gray-900"
          >
            Stock
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-[.8125rem] font-semibold text-gray-900"
          >
            Sold
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-[.8125rem] font-semibold text-gray-900"
          >
            Available
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-[.8125rem] font-semibold text-gray-900"
          >
            Pre-Order
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        <tr>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[.8125rem] font-medium text-gray-900 sm:pl-0">
            <span className="sr-only">Shopify</span>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-indigo-500 text-center">
            <InventoryFlyOut
              quantity={variant?.inventory_quantity}
              name="inventory_quantity"
              handleChange={handleChange}
            >
              <span className="text-indigo-500">
                {variant?.inventory_quantity}
              </span>
            </InventoryFlyOut>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500 text-center">
            0
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-gray-500 text-center">
            0
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-[.8125rem] text-indigo-500 text-center">
            <InventoryFlyOut
              quantity={variant?.internal_data?.pre_order}
              name="internal_data.pre_order"
              handleChange={handleChange}
            >
              <span className="text-indigo-500">
                {variant?.internal_data?.pre_order}
              </span>
            </InventoryFlyOut>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InventoryTable;
