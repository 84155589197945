const StatLoader = () => {
  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow ring-1 ring-gray-900/10 sm:px-6 sm:pt-6">
      <div role="status" className="animate-pulse">
        <div className="flex items-center">
          <div className="w-12 h-12 me-3 rounded-md bg-gray-200" />
          <div>
            <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2"></div>
            <div className="w-48 h-2 bg-gray-200 rounded-full"></div>
          </div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default StatLoader;
