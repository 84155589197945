const VariantOptionList = ({ name, values }) => {
  return (
    <div className="flex flex-1">
      <div className="w-full">
        <div className="flex gap-6 items-center">
          <div className="w-4"></div>
          <div className="w-full">
            <p className="block text-[.8125rem] font-semibold leading-5 text-gray-900">
              {name}
            </p>
          </div>
          <div className="w-4"></div>
        </div>
        <div>
          <div className="flex gap-6 items-center mt-2">
            <div className="w-4"></div>
            <div className="w-full flex flex-wrap">
              {values?.map((value, index) => (
                <span
                  key={index}
                  className="flex items-center bg-gray-200 rounded-full text-xs font-normal text-gray-700 mr-2 mb-2 p-3 py-1"
                >
                  {value}
                </span>
              ))}
            </div>
            <div className="w-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantOptionList;
