import { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { InboxStackIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ItemCardHeader = ({
  status,
  getStatusStyles,
  statusText = '',
  itemsEditing,
  onSelectOption,
  splitHeaders,
  fulfillmentOrder,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const cardMenu = {
    Received: [
      { name: 'Change status', href: '#' },
      { name: 'Split items', href: '#' },
      { name: 'Merge items', href: '#' },
      { divider: true },
      { name: 'Edit items', href: '#' },
    ],
    Batched: [{ name: 'Change status', href: '#' }],
    Shipped: [
      { name: 'Edit tracking', href: '#' },
      { name: 'Print packing slip', href: '#' },
      { name: 'Print label', href: '#' },
      { name: 'Cancel shipment', href: '#' },
    ],
    SplitItem: [
      { name: 'Merge items', href: '#' },
      { name: 'Change status', href: '#' },
    ],
  };

  let styledBadge;
  if (getStatusStyles) {
    styledBadge = getStatusStyles(status);
  } else {
    styledBadge = 'ring-gray-700/20 bg-gray-100 text-gray-800 fill-gray-500';
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [buttonRef]);

  const handleMenuSelection = (selectedOption) => {
    setMenuOpen(false); // Optional: close the menu after selection
    onSelectOption(selectedOption); // Pass the selected option to the parent
  };

  return (
    <div className="w-full flex items-center space-between">
      <div className="flex-1 items-center flex gap-x-2">
        <span
          className={`inline-flex items-center gap-x-1.5 rounded-lg ring-1 px-2 py-1 text-[.8125rem] font-medium ${styledBadge}`}
        >
          <svg className="h-1.5 w-1.5" viewBox="0 0 6 6" aria-hidden="true">
            <circle cx={3} cy={3} r={3} />
          </svg>
          {status}
        </span>
        <div className="flex flex-row flex-nowrap gap-x-2">
          {fulfillmentOrder && (
            <div className="text-[0.8125rem] flex flex-row items-center">
              <div className="font-semibold mr-1">
                <InboxStackIcon className="w-4" />
              </div>
              {fulfillmentOrder}
            </div>
          )}
          <div>{statusText && statusText}</div>
        </div>
      </div>
      {!itemsEditing && cardMenu[status] && (
        <Menu as="div" className="relative">
          <Menu.Button
            className="-m-1.5 flex items-center p-1.5"
            ref={buttonRef}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div
              className={`p-1.5 rounded-lg cursor-pointer hover:bg-gray-900/10 ${menuOpen ? 'bg-gray-900/10' : ''}`}
            >
              <EllipsisHorizontalIcon className="w-5 h-5" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-44 origin-top-right rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-300 focus:outline-none">
              {splitHeaders
                ? cardMenu['SplitItem'].map((item, index) => (
                    <Fragment key={index}>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-[.8125rem] leading-5 text-gray-900'
                            )}
                            onClick={() => handleMenuSelection(item.name)}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                      {item.divider && (
                        <div className="border-t border-gray-200 my-1"></div>
                      )}
                    </Fragment>
                  ))
                : cardMenu[status] &&
                  cardMenu[status].map((item, index) => (
                    <Fragment key={index}>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-[.8125rem] leading-5 text-gray-900'
                            )}
                            onClick={() => handleMenuSelection(item.name)}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                      {item.divider && (
                        <div className="border-t border-gray-200 my-1"></div>
                      )}
                    </Fragment>
                  ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default ItemCardHeader;
