import { Fragment, useState, useEffect, useRef } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { FormatDateTime } from '../../../utils/FormatDateTime';
import CommentForm from '../CommentForm/CommentForm';
import ActivityLoader from '../Loaders/ActivityLoader';

import { useActivityLog } from '../../../context/logging-context';

import CardPagination from '../Pagination/CardPagination';

import { EyeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Card from '../Card';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ActivityLog = ({
  referenceId,
  referenceType,
  allowComments,
  className,
}) => {
  const {
    activityLog,
    fetchActivityLog,
    loading,
    updateActivityLog,
    setLoading,
  } = useActivityLog();
  const [currentPage, setCurrentPage] = useState(1); // Add currentPage state
  const [limit, setLimit] = useState(10); // If you want to allow changing the number of items per page
  const hasFetched = useRef(false); // Ref to track if fetchActivityLog has been called

  useEffect(() => {
    console.log('Fetching activity log', {
      referenceId,
      referenceType,
      currentPage,
      limit,
    });

    if (!hasFetched.current) {
      fetchActivityLog(referenceId, referenceType, currentPage, limit);
      hasFetched.current = true;
    }
  }, [referenceId, referenceType, currentPage, limit]);

  const handleNewComment = (newComment) => {
    updateActivityLog(newComment);
  };

  const handlePageChange = (newPage) => {
    setLoading(true); // Optionally set loading here or rely on useEffect
    setCurrentPage(newPage);
  };

  return (
    <Card className={className}>
      {allowComments && (
        <div className="mb-6">
          <CommentForm
            referenceId={referenceId}
            referenceType={referenceType}
            onCommentSubmit={handleNewComment}
          />
        </div>
      )}
      {loading ? (
        <ActivityLoader />
      ) : (
        <Fragment>
          {!activityLog?.items?.length > 0 ? (
            <div className="text-[.8125rem] text-gray-400 text-center">
              There is currently no activity.
            </div>
          ) : (
            <Fragment>
              <ul className="space-y-6">
                {activityLog?.items.map((activityItem, activityItemIdx) => (
                  <li key={activityItemIdx} className="relative flex gap-x-4">
                    <div
                      className={classNames(
                        activityItemIdx === activityLog.items.length - 1
                          ? 'h-6'
                          : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                      )}
                    >
                      <div className="w-px bg-gray-200" />
                    </div>
                    {activityItem.action === 'commented' ? (
                      <>
                        <img
                          src={activityItem.user.image}
                          alt=""
                          className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-200 ring-gray-300 ring-1 ring-inset"
                        />
                        <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                          <div className="flex justify-between gap-x-4">
                            <div className="py-0.5 text-xs leading-5 text-gray-500">
                              <span className="font-medium text-gray-900">
                                {activityItem.user.name}
                              </span>{' '}
                              commented
                            </div>
                            <time
                              dateTime={activityItem.created_at}
                              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                            >
                              {FormatDateTime(activityItem.created_at)}
                            </time>
                          </div>
                          <p className="text-[.8125rem] leading-5 text-gray-500">
                            {activityItem.comment}
                          </p>
                          {activityItem.imageUrls &&
                            activityItem.imageUrls.length > 0 && (
                              <div className="mt-2 flex gap-2">
                                {activityItem.imageUrls.map(
                                  (imageUrl, index) => (
                                    <Link
                                      key={index}
                                      to={imageUrl}
                                      target="_blank"
                                      download
                                      className="relative group"
                                    >
                                      <img
                                        src={imageUrl}
                                        alt={`Attachment ${index + 1}`}
                                        className="h-16 w-16 rounded-lg object-cover ring-1 ring-gray-900/20"
                                      />
                                      <span className="absolute -top-1 -right-1 text-white bg-gray-900 rounded-full cursor-pointer">
                                        <EyeIcon className="w-auto h-4 p-0.5" />
                                      </span>
                                    </Link>
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                          {activityItem.action === 'paid' ? (
                            <CheckCircleIcon
                              className="h-6 w-6 text-indigo-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                          )}
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900">
                            {activityItem.user.name}
                          </span>{' '}
                          {activityItem.action} the {activityItem.actionDetail}
                        </p>
                        <time
                          dateTime={activityItem.created_at}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {FormatDateTime(activityItem.created_at)}
                        </time>
                      </>
                    )}
                  </li>
                ))}
              </ul>
              {activityLog?.totalItems > limit && (
                <div>
                  <CardPagination
                    currentPage={currentPage}
                    totalPages={activityLog.totalPages}
                    totalItems={activityLog.totalItems}
                    limit={limit}
                    onPageChange={handlePageChange}
                    loading={loading}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default ActivityLog;
