// React components
import React, { useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

// Shared components
import Card from '../../../../../../shared/components/UIElements/Card';
import Button from '../../../../../../shared/components/UIElements/Button';

// Page components
import VariantOption from './VariantOptions/VariantOption';
import VariantOptionList from './VariantOptions/VariantOptionList';
import VariantTable from './VariantTable/VariantTable';

const ProductVariants = ({ fields, handleChange, committed }) => {
  const [optionsEditing, setOptionsEditing] = useState(false);

  const handleEdit = (e) => {
    e.preventDefault();
    setOptionsEditing(true);
  };

  const handleOptionSave = (e) => {
    e.preventDefault();
    setOptionsEditing(false);
  };

  const handleOptionCancel = (e) => {
    e.preventDefault();
    setOptionsEditing(false);
  };

  // Full option categories

  // Add an option
  const handleAddOption = () => {
    if (!optionsEditing) setOptionsEditing(true);

    const newOption = {
      name: '',
      values: [],
    };

    const updatedOptions = [...fields.options, newOption];
    if (updatedOptions.length <= 3) {
      handleChange({
        target: {
          name: 'options',
          value: updatedOptions,
        },
      });
    }
  };

  // Update option title
  const handleOptionNameChange = (optionIndex, newName) => {
    const newOptions = fields.options.map((option, idx) => {
      if (idx === optionIndex) {
        return { ...option, name: newName };
      }
      return option;
    });

    handleChange({
      target: {
        name: 'options',
        value: newOptions,
      },
    });
  };

  // Delete Option
  const handleOptionDelete = (optionIndex) => {
    const newOptions = fields.options.filter(
      (_, index) => index !== optionIndex
    );

    handleChange({
      target: {
        name: 'options',
        value: newOptions,
      },
    });

    const updatedVariants = fields.variants.map((variant) => {
      for (let i = optionIndex; i < 3; i++) {
        variant[`option${i + 1}`] = variant[`option${i + 2}`] || null;
      }
      variant.title = [variant.option1, variant.option2, variant.option3]
        .filter(Boolean)
        .join(' / ');

      return variant;
    });

    const filteredVariants = updatedVariants.filter(
      (variant) => variant.option1
    );

    const uniqueVariants = Array.from(
      new Map(
        filteredVariants.map((variant) => [variant.title, variant])
      ).values()
    );

    handleChange({
      target: {
        name: 'variants',
        value: uniqueVariants,
      },
    });
  };

  // Single option values
  const getAllOptionCombinations = (options) => {
    let result = [[]];

    options.forEach((option) => {
      let temp = [];
      option.values.forEach((value) => {
        result.forEach((existing) => {
          temp.push([...existing, value]);
        });
      });
      result = temp;
    });

    return result;
  };

  const updateVariants = (newOptions) => {
    const optionCombinations = getAllOptionCombinations(newOptions);

    // Create a map of existing variants based on their current option values
    const existingVariantMap = new Map();
    fields.variants.forEach((variant) => {
      const key = `${variant.option1 || ''}_${variant.option2 || ''}_${variant.option3 || ''}`;
      existingVariantMap.set(key, variant);
    });

    const updatedVariants = optionCombinations.map((combination) => {
      const key = `${combination[0] || ''}_${combination[1] || ''}_${combination[2] || ''}`;

      if (existingVariantMap.has(key)) {
        // If an existing variant matches this combination, use it
        return existingVariantMap.get(key);
      } else {
        // Adjust an existing variant if possible, otherwise create a new one
        const variantToAdjust = Array.from(existingVariantMap.values()).find(
          (variant) => {
            return (
              (!variant.option1 || variant.option1 === combination[0]) &&
              (!variant.option2 || variant.option2 === combination[1]) &&
              (!variant.option3 || variant.option3 === combination[2])
            );
          }
        );

        if (variantToAdjust) {
          // Adjust the found variant to match the new combination
          return {
            ...variantToAdjust,
            option1: combination[0] || variantToAdjust.option1,
            option2: combination[1] || variantToAdjust.option2,
            option3: combination[2] || variantToAdjust.option3,
            title: combination.filter(Boolean).join(' / '),
          };
        } else {
          // Create a new variant if no existing one can be adjusted
          return {
            id: null,
            title: combination.filter(Boolean).join(' / '),
            price: '0.00',
            sku: '',
            inventory_quantity: 0,
            inventory_item_id: null,
            inventory_management: '',
            product_id: null,
            compare_at_price: '0.00',
            image_id: null,
            barcode: '',
            option1: combination[0] || '',
            option2: combination[1] || '',
            option3: combination[2] || '',
            requires_shipping: true,
            width: 0,
            height: 0,
            length: 0,
            weight: 0,
            weight_unit: 'lbs',
            internal_data: {
              pid: fields._id,
              pre_order: 0,
              pre_order_date: 0,
              store_inventory: 0,
            },
          };
        }
      }
    });

    // Merge and deduplicate variants
    const uniqueVariants = Array.from(
      new Map(
        updatedVariants.map((variant) => [
          `${variant.option1}_${variant.option2}_${variant.option3}`,
          variant,
        ])
      ).values()
    );

    handleChange({
      target: {
        name: 'variants',
        value: uniqueVariants,
      },
    });
  };

  // Add option value
  const handleOptionValueChange = (optionIndex, valueIndex, newValue) => {
    const oldOptions = fields.options.map((option) => ({ ...option })); // Clone to preserve the original state
    const oldValue = oldOptions[optionIndex].values[valueIndex];

    const newOptions = oldOptions.map((option, idx) => {
      if (idx === optionIndex) {
        const updatedValues = [...option.values];
        if (valueIndex >= updatedValues.length) {
          updatedValues.push(newValue);
        } else {
          updatedValues[valueIndex] = newValue;
        }
        return { ...option, values: updatedValues };
      }
      return option;
    });

    handleChange({
      target: {
        name: 'options',
        value: newOptions,
      },
    });

    // Use the existing updateVariants function but with adjustments to existing variants
    updateVariantsWithOldValues(oldValue, newValue, newOptions, optionIndex);
  };

  const updateVariantsWithOldValues = (
    oldValue,
    newValue,
    newOptions,
    optionIndex
  ) => {
    const updatedVariants = fields.variants.map((variant) => {
      if (variant[`option${optionIndex + 1}`] === oldValue) {
        return {
          ...variant,
          [`option${optionIndex + 1}`]: newValue,
          title: newOptions
            .map((opt, idx) => variant[`option${idx + 1}`])
            .filter(Boolean)
            .join(' / '),
        };
      }
      return variant;
    });

    handleChange({
      target: {
        name: 'variants',
        value: updatedVariants,
      },
    });
    updateVariants(newOptions);
  };

  const handleOptionValueDelete = (optionIndex, valueIndex) => {
    const newOptions = JSON.parse(JSON.stringify(fields.options));
    const deletedValue = newOptions[optionIndex].values.splice(
      valueIndex,
      1
    )[0];

    handleChange({
      target: {
        name: 'options',
        value: newOptions,
      },
    });

    if (newOptions[optionIndex].values.length === 0) {
      newOptions.splice(optionIndex, 1);

      const updatedVariants = fields.variants
        .map((variant) => {
          for (let i = optionIndex + 1; i <= newOptions.length + 1; i++) {
            variant[`option${i}`] = variant[`option${i + 1}`] || null;
          }
          delete variant[`option${newOptions.length + 1}`];
          return variant;
        })
        .filter((variant) =>
          Object.values(variant).some((val) => val !== null)
        );

      handleChange({
        target: {
          name: 'variants',
          value: updatedVariants,
        },
      });
    } else {
      const updatedVariants = fields.variants.filter(
        (variant) => variant[`option${optionIndex + 1}`] !== deletedValue
      );

      handleChange({
        target: {
          name: 'variants',
          value: updatedVariants,
        },
      });
    }
  };

  return (
    <Card className="mt-4 overflow-x-hidden">
      <div>
        <div className="flex justify-between">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Variants
          </h2>
          {optionsEditing && fields?.options?.length > 0 ? (
            <div className="flex gap-x-2">
              <button
                onClick={handleOptionCancel}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Discard
              </button>
              <Button onClick={handleOptionSave}>Save</Button>
            </div>
          ) : (
            fields?.options?.length > 0 && (
              <Button onClick={handleEdit}>Edit</Button>
            )
          )}
        </div>
        <div>
          {fields?.options?.length <= 0 ? (
            <div
              className="mt-4 text-[.8125rem] leading-5 text-indigo-600"
              onClick={handleAddOption}
            >
              <span className="flex items-center gap-x-2 hover:underline">
                <PlusCircleIcon className="w-5 h-5 fill-current" />
                <p>Add options like size or color</p>
              </span>
            </div>
          ) : (
            <div className="ring-1 ring-gray-200 w-full mt-4 rounded-md">
              {fields?.options?.map((option, idx) =>
                optionsEditing ? (
                  <div key={idx} className="p-6 border-b border-gray-200">
                    <VariantOption
                      idx={idx}
                      options={fields?.options}
                      name={option.name}
                      values={option.values}
                      onOptionNameChange={handleOptionNameChange}
                      onOptionDelete={handleOptionDelete}
                      onOptionValueChange={handleOptionValueChange}
                      onOptionValueDelete={handleOptionValueDelete}
                    />
                  </div>
                ) : (
                  <div key={idx} className="p-6 border-b border-gray-200">
                    <VariantOptionList
                      indx={idx}
                      options={fields?.options}
                      name={option.name}
                      values={option.values}
                    />
                  </div>
                )
              )}
              {fields?.options?.length < 3 && (
                <div
                  className="p-6 flex items-center gap-x-2 text-[.8125rem] leading-5 text-indigo-600"
                  onClick={handleAddOption}
                >
                  <PlusCircleIcon className="w-5 h-5 fill-current" />
                  <p>
                    <span className="hover:underline">Add another option</span>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        {fields?.options?.length > 0 &&
          fields?.options?.[0]?.values?.[0] !== '' && (
            <div className="-mr-4 -ml-4 mt-4">
              <VariantTable
                fields={fields}
                committed={committed}
                handleChange={handleChange}
              />
            </div>
          )}
      </div>
    </Card>
  );
};

export default ProductVariants;
