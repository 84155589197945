const PaymentCardFooter = ({ total }) => {
  return (
    <div className="border-t border-gray-900/20 px-4 py-2">
      <dl className="">
        <div className="px-2 grid grid-cols-2 sm:gap-4 sm:px-0">
          <dt className="text-sm leading-6 sm:col-span-1 text-gray-900">
            Paid
          </dt>
          <dd className="text-sm leading-6 text-gray-700 sm:col-span-1 mt-0 flex justify-end">
            ${total.toFixed(2)}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default PaymentCardFooter;
