import Input from '../../../../../shared/components/UIElements/Inputs/Input';
import Card from '../../../../../shared/components/UIElements/Card';
import Divider from '../../../../../shared/components/UIElements/Divider';
import PriceInput from '../../../../../shared/components/UIElements/Inputs/PriceInput';

const VariantInformation = ({ fields, handleChange }) => {
  return (
    <Card className="mt-4">
      <div className="space-y-6 px-4 py-6 sm:p-6">
        <div>
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Variant Information
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Enter variant details.
          </p>
        </div>

        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-4">
            <Input
              label="Title"
              type="text"
              name={`title`}
              id="title"
              autoComplete="variant-title"
              value={fields?.title || ''}
              onChange={handleChange}
              disabled={true}
              className={`mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5 bg-gray-200 opacity-50 cursor-not-allowed`}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <Input
              label="Variant SKU"
              type="text"
              name={`sku`}
              id="sku"
              autoComplete="variant-sku"
              value={fields?.sku || ''}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <Input
              label="Barcode"
              type="text"
              name={`barcode`}
              id="barcode"
              autoComplete="barcode"
              value={fields?.barcode || ''}
              onChange={handleChange}
              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            />
          </div>
        </div>
        <Divider />
        <div>
          <h3 className="text-base font-semibold leading-5 text-gray-900">
            Pricing
          </h3>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Enter variant pricing.
          </p>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-4">
            <PriceInput
              label="Price"
              type="text"
              name={`price`}
              id="price"
              autoComplete="price"
              value={fields?.price || 0.0}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-12 sm:col-span-4">
            <PriceInput
              label="Compare at price"
              type="text"
              name={`compare_at_price`}
              id="compare_at_price"
              autoComplete="compare_at_price"
              value={fields?.compare_at_price || 0.0}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default VariantInformation;
