import React, { useState, useCallback } from 'react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';

import { onUploadImage } from '../../../utils/Api/Content/Upload';

import { usePopoutNotification } from './Notifications/Popout/PopoutNotificationProvider';

const ImageUpload = ({ images, onUploadSuccess }) => {
  const { notify } = usePopoutNotification();

  const [isDragOver, setIsDragOver] = useState(false);

  const handleFilesUpload = async (files) => {
    const imageFiles = Array.from(files).filter((file) =>
      ['image/png', 'image/jpeg', 'image/gif'].includes(file.type)
    );
    const updatedImages = [];

    for (const file of imageFiles) {
      const tempId = uuidv4(); // Generate tempId for new uploads
      try {
        const response = await onUploadImage(file);
        if (response.success) {
          // Correctly construct the object with tempId for new uploads
          updatedImages.push({
            _id: tempId, // Use a consistent key (e.g., itemId) to pass identifier
            cloudId: response.data.cloudId,
            cloudUrl: response.data.cloudUrl,
            isNew: true, // Flag to indicate a new upload
          });
        } else {
          console.error('Failed to upload image to Cloudinary.');
          notify('Error!', response.data.message, 'error');
        }
      } catch (error) {
        console.error('An error occurred during the image upload:', error);
        notify('Error!', 'An error occurred during the image upload.', 'error');
      }
    }

    console.log(
      '[ImageUpload.js] - [UPDATED IMAGES]',
      JSON.stringify(updatedImages, null, 2)
    );

    if (updatedImages.length > 0) {
      onUploadSuccess(updatedImages);
    }
  };

  // Drag and drop
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setIsDragOver(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setIsDragOver(false);
    handleFilesUpload(event.dataTransfer.files);
  }, []);

  const handleFileChange = useCallback((event) => {
    handleFilesUpload(event.target.files);
  }, []);

  return (
    <>
      <div
        className={`h-full items-center flex justify-center rounded-lg border-2 ${isDragOver ? 'border-indigo-600' : 'border-dashed border-gray-900/25'} px-6 py-10 ${!images || images.length === 0 ? '' : 'sm:py-0'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 inline-block text-[.8125rem] leading-5 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <span>Upload an image</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={handleFileChange}
                multiple
                accept="image/png, image/jpeg, image/gif"
              />
            </label>
            <p className="pl-1 hidden sm:block">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600 hidden sm:block">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
