// import TableFilters from './TableFilters';
import TableSearch from './TableSearch';

const TableHeaderActions = ({ onSearch }) => {
  return (
    <div className="py-4 px-4 w-full flex border-b">
      <TableSearch onSearch={onSearch} />
      {/* <TableFilters /> */}
    </div>
  );
};

export default TableHeaderActions;
