import React from 'react';

const PageFooter = ({ children }) => {
  return (
    <div className="sm:flex sm:items-center mt-4 w-full">
      {children && children}
    </div>
  );
};

export default PageFooter;
