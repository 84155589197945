export const FormatDateTime = (datetime) => {
  if (!datetime) return 'Invalid date'; // Check for falsy values

  const date = new Date(datetime);
  if (isNaN(date.getTime())) return 'Invalid date'; // Check if date is valid

  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  let hours = date.getHours();
  const minutes = ('0' + date.getMinutes()).slice(-2); // Ensure minutes have leading zero
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 0 hours to 12 for 12 AM/PM

  const timeString = `${hours}:${minutes} ${ampm}`;

  if (isToday) {
    return `Today @ ${timeString}`;
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year} @ ${timeString}`;
  }
};

export const FormatDateIntl = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};
