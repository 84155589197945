import { Fragment, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import { getUser } from '../../../utils/Api/Users/Get';

import SettingsNavigation from '../components/SettingsNavigation';
import UserForm from '../../users/components/UserForm';
import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';

const Account = () => {
  const userCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_NAME}`);
  const userData = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;

  const id = userData.uid;
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Define an async function inside the effect to fetch users
    const fetchData = async () => {
      try {
        const data = await getUser(id); // Wait for getUsers to resolve
        setUser(data.data); // Update the state with the fetched data
      } catch (error) {
        console.error('Failed to fetch users:', error);
        // Handle error here, e.g., set error state or show a notification
      }
    };

    fetchData(); // Call the async function
  }, [id]);

  return !user ? (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-xl">
          <h1 className="sr-only">Account Settings</h1>

          <div className="mt-4 flex flex-col">
            <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
          </div>
        </div>
      </Container>
    </Fragment>
  ) : (
    <Fragment>
      <SettingsNavigation />
      <Container>
        <div className="mx-auto max-w-screen-md">
          <h1 className="sr-only">Account Settings</h1>

          <div className="mt-4 lg:grid lg:grid-cols-1 lg:gap-x-5">
            <UserForm
              firstName={user?.firstName}
              lastName={user?.lastName}
              email={user?.email}
              status={user?.status}
              image={user?.image}
              userId={id}
              enableDelete={false}
            />
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Account;
