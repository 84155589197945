import { useState, useCallback, useEffect } from 'react';
import Cookie from 'js-cookie';
let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [userName, setUserName] = useState(false);
  const [userLastName, setUserLastName] = useState(false);
  const [image, setImage] = useState(false);

  const login = useCallback(
    (uid, token, userFirstName, userLast, userImage, expirationDate) => {
      setToken(token);
      setUserId(uid);
      setUserName(userFirstName);
      setImage(userImage);
      setUserLastName(userLast);
      // const tokenExpirationDate =
      //   expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60); // One Hour Timer
      const tokenExpirationDate = new Date(expirationDate);
      setTokenExpirationDate(tokenExpirationDate);
      Cookie.set(
        'daileyOMS.auth',
        JSON.stringify(
          {
            uid: uid,
            token: token,
            userName: userFirstName,
            userLastName: userLast,
            image: userImage,
            expiration: tokenExpirationDate.toISOString(),
          },
          { expires: new Date(expirationDate) }
        )
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUserName(null);
    setUserLastName(null);
    setImage(null);
    Cookie.remove('daileyOMS.auth');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    try {
      const rawData = Cookie.get('daileyOMS.auth');
      if (rawData) {
        const storedData = JSON.parse(rawData);
        if (
          storedData &&
          storedData.token &&
          new Date(storedData.expiration) > new Date()
        ) {
          login(
            storedData.uid,
            storedData.token,
            storedData.userName,
            storedData.userLastName,
            storedData.image,
            new Date(storedData.expiration)
          );
        }
      }
    } catch (e) {
      console.error('Failed to parse authentication data:', e);
    }
  }, [login]);

  return { userId, token, login, logout, userName, userLastName, image };
};
