// import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import Badge from '../../../../../../../shared/components/UIElements/Badge';

const PaymentCardHeader = ({ paymentStatus }) => {
  return (
    <Badge
      className={`inline-flex items-center gap-x-1.5 rounded-lg ring-1 ring-gray-700/20 bg-gray-100 !text-gray-500 px-2 py-1 text-[.8125rem] font-medium capitalize`}
    >
      <CreditCardIcon className="w-5 h-auto" />
      {paymentStatus}
    </Badge>
  );
};

export default PaymentCardHeader;
