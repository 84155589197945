import React from 'react';
import { Link } from 'react-router-dom';

import CustomerShipping from './Customer/CustomerShipping';
import CustomerContact from './Customer/CustomerContact';
import Card from '../../../../../shared/components/UIElements/Card';

const Customer = ({
  contactEmail,
  customer,
  shippingAddress,
  handleSaveField,
  className,
}) => {
  return (
    <Card className={className}>
      <div className="text-[.8125rem]">
        <div className="flex items-center space-between">
          <span className="flex-1 font-semibold text-gray-900 mr-2">
            Customer
          </span>
        </div>
        <Link to={`/customers/${customer._id}`}>
          <p className="text-indigo-500 mt-2">
            {customer?.first_name} {customer?.last_name}
          </p>
        </Link>
      </div>
      <CustomerContact
        contactEmail={contactEmail}
        handleSaveField={handleSaveField}
      />
      <CustomerShipping
        shippingAddress={shippingAddress}
        handleSaveField={handleSaveField}
      />
    </Card>
  );
};

export default Customer;
