import Container from '../../../shared/components/UIElements/Container';
import PageHeader from '../../../shared/components/UIElements/PageHeader';
import UserForm from '../components/UserForm';

const createUser = () => {
  return (
    <Container>
      <PageHeader pageTitle="Create User" />
      <div className="mt-4 lg:grid lg:grid-cols-1 lg:gap-x-5">
        <UserForm />
      </div>
    </Container>
  );
};

export default createUser;
