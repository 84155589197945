import React, { useState, useEffect } from 'react';
import { usePopoutNotification } from '../../../../../shared/components/UIElements/Notifications/Popout/PopoutNotificationProvider';
import ItemCardFooter from './LineItem/ItemCardFooter';
import ItemCardHeader from './LineItem/ItemCardHeader';
import LineItem from './LineItem';
import SelectAllItems from './Shared/SelectAllItems';
import ReasonForm from './LineItem/LineItemForms/ReasonForm';
import { processItems } from './helpers/ProcessLineItems';
import Card from '../../../../../shared/components/UIElements/Card';
import StatusForm from './LineItem/LineItemForms/StatusForm';
import MergeForm from './LineItem/LineItemForms/MergeForm/MergeForm';
import AddItem from './LineItem/EditItem/AddItem';
import FulfillmentForm from './LineItem/LineItemForms/FulfillmentForm/FulfillmentForm';

const getStatusStyles = (status) => {
  switch (status) {
    case 'Received':
      return 'ring-indigo-700/20 bg-indigo-100 text-indigo-800 fill-indigo-500';
    case 'Shipped':
    case 'Refunded':
      return 'ring-gray-700/20 bg-gray-100 text-gray-800 fill-gray-500';
    case 'On Hold':
      return 'ring-yellow-700/20 bg-yellow-100 text-yellow-800 fill-yellow-500';
    case 'Risk Hold':
    case 'Error':
      return 'ring-red-700/20 bg-red-100 text-red-800 fill-red-500';
    default:
      return 'ring-gray-700/20 bg-gray-100 text-gray-800 fill-gray-500'; // Default case for unknown status
  }
};

const HoldReasons = [
  { name: 'Inventory out of stock' },
  { name: 'Incorrect address' },
  { name: 'High risk of fraud' },
  { name: 'Awaiting payment' },
  { name: 'Awaiting return items' },
  { name: 'Other' },
];

const LineItemGroup = ({
  items,
  allLineItems,
  handleSaveField,
  orderId,
  status,
  fulfillmentOrder,
  splitHeaders = false,
}) => {
  const [itemsEditing, setItemsEditing] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [holdReason, setHoldReason] = useState(HoldReasons[0]);
  const [optionalMessage, setOptionalMessage] = useState('');
  const [newStatus, setNewStatus] = useState({});
  const [mergeItemId, setMergeItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const { notify } = usePopoutNotification();

  const flatItems = Object.values(items).flat();
  const allItemIds = flatItems.map((item) => item._id);
  const isAllSelected = allItemIds.every((id) => selectedItems[id]?.selected);
  const isSomeSelected = allItemIds.some((id) => selectedItems[id]?.selected);

  useEffect(() => {
    setNewStatus({ name: status });
  }, [status]);

  useEffect(() => {
    if (selectedOption === 'Edit items') {
      const initialSelectedItems = Object.fromEntries(
        items.flat().map((item) => {
          const quantity = item.hold_data
            ? item.hold_data.quantity
            : item.fulfillable_quantity;
          return [item._id, { selected: true, quantity, itemId: item.id }];
        })
      );
      setSelectedItems(initialSelectedItems);
    } else {
      setSelectedItems({});
    }
  }, [items, selectedOption]);

  const handleSelectAll = () => {
    if (isAllSelected || isSomeSelected) {
      setSelectedItems({});
    } else {
      const newSelections = allItemIds.reduce((acc, id) => {
        const item = items.find((item) => item._id === id);
        const quantity =
          selectedItems[id]?.quantity ||
          (item?.hold_data
            ? item.hold_data.quantity
            : item?.fulfillable_quantity) ||
          1;
        return {
          ...acc,
          [id]: {
            selected: true,
            quantity,
            itemId: item?.id,
            fulfillable_quantity: item?.fulfillable_quantity,
          },
        };
      }, {});
      setSelectedItems(newSelections);
    }
  };

  const handleCheckboxChange = (id, isChecked, fulfillableQuantity, itemId) => {
    setSelectedItems((prev) => {
      if (isChecked) {
        return {
          ...prev,
          [id]: {
            selected: true,
            quantity: prev[id]?.quantity || fulfillableQuantity,
            fulfillable_quantity: fulfillableQuantity,
            itemId: itemId,
          },
        };
      } else {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      }
    });
  };

  const handleQuantityChange = (id, quantity) => {
    setSelectedItems((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        quantity,
        fulfillable_quantity: quantity,
        internal_status: quantity === 0 ? 'Removed' : prev[id].internal_status,
      },
    }));
  };

  const handleStatusChange = (newItemStatus) => {
    setNewStatus(newItemStatus);
  };

  const handleHoldReasonChange = (newReason) => {
    setHoldReason(newReason);
  };

  const handleOptionalMessageChange = (message) => {
    setOptionalMessage(message);
  };

  const clearStates = () => {
    setItemsEditing(false);
    setSelectedOption('');
  };

  const handleActionWithLoading = async (action) => {
    setIsLoading(true);
    await action();
    setIsLoading(false);
    clearStates();
  };

  const handleHoldItems = () =>
    handleActionWithLoading(async () => {
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'hold',
        {
          holdReason,
          optionalMessage,
        }
      );
    });

  const handleReleaseHoldItems = () =>
    handleActionWithLoading(async () => {
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'release'
      );
    });

  const handleSplitItems = () =>
    handleActionWithLoading(async () => {
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'split'
      );
    });

  const handleItemStatus = () =>
    handleActionWithLoading(async () => {
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'changeStatus',
        { newStatus }
      );
    });

  const handleMergeItems = () =>
    handleActionWithLoading(async () => {
      if (!mergeItemId) {
        notify('Error!', 'No merge item selected.', 'error');
        return;
      }
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'merge',
        { mergeItemId }
      );
      setMergeItemId(null);
    });

  const handleUpdateItems = () =>
    handleActionWithLoading(async () => {
      await processItems(
        selectedItems,
        setSelectedItems,
        allLineItems,
        handleSaveField,
        notify,
        'update'
      );
    });

  return (
    <Card className="mb-4">
      <ItemCardHeader
        status={status}
        getStatusStyles={getStatusStyles}
        itemsEditing={itemsEditing}
        onSelectOption={(option) => {
          setSelectedOption(option);
          setItemsEditing(true);
        }}
        splitHeaders={splitHeaders}
        fulfillmentOrder={fulfillmentOrder}
      />

      <div className="mt-2 grid grid-cols-1 w-full rounded-lg ring-1 ring-gray-900/20">
        {itemsEditing && selectedOption !== 'Edit items' && (
          <SelectAllItems
            isAllSelected={isAllSelected}
            isSomeSelected={isSomeSelected}
            handleSelectAll={handleSelectAll}
          />
        )}
        {items.map((item, idx) => (
          <div
            key={idx}
            className={`
                ${itemsEditing && selectedOption !== 'Edit items' && 'cursor-pointer hover:bg-gray-900/5'} 
                ${idx !== items.length - 1 ? 'border-b-[1px] border-gray-900/20' : 'rounded-b-lg'}
              `}
          >
            <LineItem
              lineItem={item}
              getStatusStyles={getStatusStyles}
              itemsEditing={itemsEditing}
              handleCheckboxChange={handleCheckboxChange}
              selectedItems={selectedItems}
              onQuantityChange={handleQuantityChange}
              selectedOption={selectedOption}
              setSelectedItems={setSelectedItems}
            />
          </div>
        ))}
        {selectedOption === 'Edit items' && <AddItem />}
      </div>

      {itemsEditing && selectedOption === 'Hold items' && (
        <ReasonForm
          reasons={HoldReasons}
          selectedReason={holdReason}
          onReasonChange={handleHoldReasonChange}
          message={optionalMessage}
          onMessageChange={handleOptionalMessageChange}
        />
      )}

      {itemsEditing && selectedOption === 'Change status' && (
        <StatusForm
          selectedStatus={newStatus}
          onStatusChange={handleStatusChange}
        />
      )}

      {itemsEditing && selectedOption === 'Merge items' && (
        <MergeForm
          selectedItem={items}
          allItems={allLineItems}
          mergeItemId={mergeItemId} // Pass the merge item ID to the MergeForm
          setMergeItemId={setMergeItemId} // Pass the setter for merge item ID to the MergeForm
        />
      )}

      {itemsEditing && selectedOption === 'Ship items' && (
        <FulfillmentForm selectedItem={items} allItems={allLineItems} />
      )}

      <ItemCardFooter
        status={status}
        itemsEditing={itemsEditing}
        setItemsEditing={setItemsEditing}
        onCancel={clearStates}
        onSelectOption={(option) => {
          setSelectedOption(option);
          setItemsEditing(true);
        }}
        selectedOption={selectedOption}
        orderId={orderId}
        handleHoldItems={handleHoldItems}
        handleReleaseHoldItems={handleReleaseHoldItems}
        handleSplitItems={handleSplitItems}
        handleItemStatus={handleItemStatus}
        handleMergeItems={handleMergeItems}
        handleUpdateItems={handleUpdateItems}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default LineItemGroup;
