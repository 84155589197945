import { Link } from 'react-router-dom';

import { FormatDateTime } from '../../../../../../shared/utils/FormatDateTime';

import Badge from '../../../../../../shared/components/UIElements/Badge';

const getStyle = (status) => {
  switch (status) {
    case 'delivered':
      return 'ring-green-700/20 bg-green-100 text-green-800 fill-green-500';
    case 'attempted_delivery':
    case 'in_transit':
      return 'ring-yellow-700/20 bg-yellow-100 text-yellow-800 fill-yellow-500';
    case 'confirmed':
      return 'ring-indigo-700/20 bg-indigo-100 text-indigo-800 fill-indigo-500';
    case 'failure':
      return 'ring-red-700/20 bg-red-100 text-red-800 fill-red-500';
    default:
      return 'ring-gray-700/20 bg-gray-100 text-gray-800 fill-gray-500'; // Default case for unknown status
  }
};

const FulfillmentInformation = ({ fulfillment }) => {
  return (
    <div className="border-b-[1px] border-gray-900/20">
      <div className="flex flex-col p-4 pb-1 gap-1 text-[.8125rem]">
        <div className="text-gray-600">Fulfilled</div>
        <div className="text-gray-900">
          {FormatDateTime(fulfillment?.created_at)}
        </div>
      </div>
      <div className="flex flex-col p-4 pt-1 gap-1 text-[.8125rem]">
        <div className="text-gray-600">{fulfillment?.tracking_company}</div>
        <div className="text-gray-900">
          {fulfillment?.tracking_numbers.map((tracking, idx) => (
            <span
              key={idx}
              className="mr-2 text-indigo-500 underline underline-offset-2"
            >
              <Link to={fulfillment?.tracking_urls[idx]} target="_blank">
                {tracking}
              </Link>
            </span>
          ))}
          {fulfillment?.shipment_status && (
            <Badge
              className={`capitalize ${getStyle(fulfillment?.shipment_status)}`}
            >
              {fulfillment?.shipment_status.replace(/_/g, ' ')}
            </Badge>
          )}
        </div>
      </div>
    </div>
  );
};

export default FulfillmentInformation;
