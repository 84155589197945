import React, { createContext, useContext, useState, useCallback } from 'react';
import { getSpecificActivity } from '../../utils/Api/Activity/Get';
import { handleUpdateActivity } from '../../utils/ActivityLogger';

const ActivityLogContext = createContext();

export const ActivityLogProvider = ({ children }) => {
  const [activityLog, setActivityLog] = useState({
    items: [],
    totalItems: 0,
    currentPage: 1,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(false);

  const updateActivityLog = (newLogEntry) => {
    setActivityLog((prevLog) => ({
      ...prevLog,
      items: [newLogEntry, ...prevLog.items],
    }));
  };

  const updateLog = async (
    referenceType,
    action,
    actionDetail,
    referenceId
  ) => {
    const updateActivity = await handleUpdateActivity(
      referenceType,
      action,
      actionDetail,
      referenceId
    );
    updateActivityLog(updateActivity.data.activity);
  };

  const fetchActivityLog = useCallback(
    async (referenceId, referenceType, currentPage, limit) => {
      setLoading(true);
      try {
        const params = { page: currentPage, limit, referenceId, referenceType };
        const response = await getSpecificActivity(params);
        setActivityLog({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: response.data.currentPage,
          totalPages: response.data.totalPages,
        });
      } catch (error) {
        console.error('Failed to fetch activity data:', error);
      }
      setLoading(false);
    },
    []
  ); // Include actual dependencies if there are any

  return (
    <ActivityLogContext.Provider
      value={{
        activityLog,
        updateActivityLog,
        fetchActivityLog,
        loading,
        setLoading,
        updateLog,
      }}
    >
      {children}
    </ActivityLogContext.Provider>
  );
};

// Custom hook to use activity log context
export const useActivityLog = () => useContext(ActivityLogContext);
