import React, { Fragment } from 'react';
import EditIcon from '../../../../../shared/components/UIElements/EditIcon';

import useEditableField from '../../../../../shared/hooks/dialog-form-hook';

import EditNote from './Dialogs/EditNote';
import Card from '../../../../../shared/components/UIElements/Card';

const Note = ({ note, noteAttributes, handleSaveField }) => {
  const { editField, handleEditField } = useEditableField(
    EditNote,
    { fieldName: 'note', value: note, dialogTitle: 'Order Notes' },
    handleSaveField
  );

  return (
    <Card>
      <div>
        <h2 className="text-[.8125rem] font-semibold leading-7 text-gray-900 mb-4">
          Notes
        </h2>
        <div className="flex items-center space-between">
          <div className="flex-1">
            <p className="block text-[.8125rem] font-medium leading-5 text-gray-900">
              Order Notes
            </p>
          </div>
          <div>
            <EditIcon onClick={handleEditField} active={editField} />
          </div>
        </div>
        <div className="text-gray-700 text-[.8125rem]">
          {note ? (
            note.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))
          ) : (
            <span className="text-gray-500 italic">No customer notes</span>
          )}
        </div>
      </div>
      {noteAttributes.length > 0 && (
        <Fragment>
          <p className="font-semibold text-[.8125rem] mt-4">Additional notes</p>
          {noteAttributes.map((note, index) => (
            <div key={index} className="mt-1 text-[.8125rem] flex flex-col">
              <span className="font-medium">{note.name}</span>
              <span>{note.value}</span>
            </div>
          ))}
        </Fragment>
      )}
    </Card>
  );
};

export default Note;
