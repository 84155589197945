import React, { useEffect, useState } from 'react';
import ContentAlert from '../../../../../../../shared/components/UIElements/Notifications/ContentAlert/ContentAlert';

const alert = {
  header: 'There are no images to select.',
  message: 'Upload product images to get started.',
  type: 'info',
};

const SelectVariantImage = ({ images, selectedImage, onSelectImage }) => {
  const [imageSelected, setImageSelected] = useState(selectedImage);

  useEffect(() => {
    // Initialization logic here, if necessary.
    // For example, you might want to ensure `selectedImage` is correctly highlighted when the dialog opens.
    setImageSelected(selectedImage);
  }, [selectedImage, images]);

  const handleSelectImage = (image) => {
    setImageSelected(image); // Update local state
    onSelectImage(image); // Update parent component's state
  };

  if (images.length > 0) {
    return (
      <ul className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-6">
        {images.map((image) => (
          <li key={image._id} className="relative">
            <div
              className={` ring-1 ring-gray-200 rounded-xl aspect-w-10 aspect-h-7 w-full overflow-hidden bg-gray-100 cursor-pointer ${imageSelected && imageSelected._id === image._id ? 'ring-2 ring-indigo-500 hover:ring-indigo-800 opacity-75 hover:opacity-100' : 'hover:ring-gray-400 hover:opacity-75'}`}
              onClick={() => handleSelectImage(image)}
            >
              <img
                src={image.src ? image.src : image.cloudUrl}
                alt=""
                className="object-cover w-full h-full"
              />
              {/* The radio behavior is simulated, so no input element is necessary here. */}
            </div>
          </li>
        ))}
      </ul>
    );
  } else {
    return (
      <>
        <ContentAlert contentAlert={alert} />
      </>
    );
  }
};

export default SelectVariantImage;
