import Card from '../../../../../../shared/components/UIElements/Card';
import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

import ProductTags from './ProductTags';

const ProductOrganization = ({ handleChange, fields }) => {
  return (
    <Card className="mt-4 overflow-visible">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          Product organization
        </h2>
        <div>
          <Input
            label="Product Type"
            type="text"
            name="product_type"
            id="product_type"
            autoComplete="product_type"
            className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            placeholder="Short sleeve"
            value={fields?.product_type}
            onChange={handleChange}
          />
        </div>
        <div className="mt-4">
          <Input
            label="Vendor"
            type="text"
            name="vendor"
            id="vendor"
            autoComplete="vendor"
            className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
            placeholder="Dailey Project"
            value={fields?.vendor}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mt-4">
        <ProductTags tags={fields?.tags} handleChange={handleChange} />
      </div>
    </Card>
  );
};

export default ProductOrganization;
