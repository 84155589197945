import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const ProductTags = ({ tags: initialTags, handleChange }) => {
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (initialTags) {
      setTags(
        initialTags
          .split(',')
          .map((tag) => tag.trim())
          .filter((tag) => tag !== '')
      );
    }
  }, [initialTags]);

  const handleNewTagInput = (e) => {
    setNewTag(e.target.value);
  };

  const handleKeyPressOnTagInput = (e) => {
    if (e.key === 'Enter' || e.target.value.includes(',')) {
      e.preventDefault(); // Prevent form submission for Enter
      const tagsToAdd = newTag
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag !== '');
      tagsToAdd.forEach((tag) => onAddTag(tag));
      setNewTag('');
    }
  };

  // Product tag handlers
  const onAddTag = (newTag) => {
    const currentTagsArray = tags;
    if (!currentTagsArray.includes(newTag)) {
      const updatedTagsArray = [...currentTagsArray, newTag];
      setTags(updatedTagsArray);
      handleChange({
        target: {
          name: 'tags',
          value: updatedTagsArray.join(', '), // Convert array back to a comma-separated string
        },
      });
    }
  };

  const onRemoveTag = (indexToRemove) => {
    const updatedTagsArray = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTagsArray);
    handleChange({
      target: {
        name: 'tags',
        value: updatedTagsArray.join(', '), // Convert array back to a comma-separated string
      },
    });
  };

  return (
    <>
      <Input
        label="Tags"
        type="text"
        name="tags"
        id="tags"
        autoComplete="tags"
        className="block w-full min-w-0 flex-grow rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-[.8125rem] sm:leading-5"
        placeholder="New, Sale, Limited Edition, Best Seller"
        value={newTag}
        onChange={handleNewTagInput}
        onKeyPress={handleKeyPressOnTagInput}
      />
      <div className="mt-2 flex flex-wrap">
        {tags?.map((tag, index) => (
          <span
            key={index}
            className="flex items-center bg-gray-200 rounded-full text-xs font-normal text-gray-700 mr-2 mb-2"
          >
            <span className="pl-3 pr-1 py-1">{tag}</span>
            <span
              onClick={() => onRemoveTag(index)}
              className="p-1 hover:cursor-pointer hover:bg-gray-300 hover:text-gray-900 rounded-full"
            >
              <XMarkIcon className="w-3 h-3" />
            </span>
          </span>
        ))}
      </div>
    </>
  );
};

export default ProductTags;
