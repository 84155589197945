import React from 'react';
import { Link } from 'react-router-dom';

import { FormatDateTime } from '../../../shared/utils/FormatDateTime';

const UserItem = ({ item }) => {
  const getInitials = (firstName, lastName) => {
    return `${firstName ? firstName[0].toUpperCase() : ''}${lastName ? lastName[0].toUpperCase() : ''}`;
  };

  return (
    <tr className="py-2">
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-[.8125rem]">
        {' '}
        {/*  sm:pl-0" */}
        <div className="flex items-center">
          <div className="h-11 w-11 flex-shrink-0 ring-1 ring-gray-700/20 rounded-full bg-gray-100">
            {item?.image ? (
              <img
                className="h-11 w-11 rounded-full"
                src={item?.image}
                alt={`${item?.firstName} ${item?.lastName}`}
              />
            ) : (
              <div className="h-11 w-11 rounded-full flex items-center justify-center bg-indigo-900 text-white">
                {getInitials(item?.firstName, item?.lastName)}
              </div>
            )}
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">
              {item?.firstName} {item?.lastName}
            </div>
            <div className="hidden mt-1 text-gray-500 sm:block">
              {item?.email}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-[.8125rem] text-gray-500">
        <div className="text-gray-900">{item?.role}</div>
        <div className="hidden mt-1 text-gray-500 lg:block">
          {FormatDateTime(item?.createdAt)}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-[.8125rem] text-gray-500">
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset
            ${
              item?.status === 'Inactive'
                ? 'bg-gray-50 text-gray-700 ring-gray-600/20'
                : 'bg-green-50 text-green-700 ring-green-600/20'
            }`}
        >
          {item?.status}
        </span>
      </td>
      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-[.8125rem] font-medium sm:pr-4">
        <Link
          to={`/users/edit/${item?._id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Edit
          <span className="sr-only">
            , {item?.firstName} {item?.lastName}
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default UserItem;
