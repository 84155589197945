import { ApiRequest } from '../BaseApi';

export const updateIntegration = async (id, data) => {
  return ApiRequest({
    method: 'patch',
    endpoint: `/integrations/${id}`,
    data: { data },
  });
};

export const updateIntegrationOption = async (id, data, optionId) => {
  return ApiRequest({
    method: 'patch',
    endpoint: `/integrations/${id}/options/${optionId}`,
    data: { data },
  });
};

// dailey-project.myshopify.com

// key - 9a346f20f5465a055c847c0429bb48bc
// secret - 5b3f1d5852c7bb06ec7ce3fb27b151f8
// token - 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
