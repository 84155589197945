import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import { useFormContext } from '../../../../shared/context/form-context';

import Customer from './components/Customer';
import Note from './components/Note';
import Tags from './components/Tags';
import LineItems from './components/LineItems';
import ItemFulfillments from './components/Fulfillments/ItemFulfillments';
import ActivityLog from '../../../../shared/components/UIElements/ActivityLog/ActivityLog';
import ContentHeader from '../../../../shared/components/UIElements/ContentHeader';
import OrderIntegrations from './components/Integrations/OrderIntegrations';
import PaymentCardContent from './components/PaymentDetails/PaymentCardContent';
import { useMediaQuery } from '../../../../shared/hooks/useMediaQuery';

const groupLineItems = (lineItems) => {
  const groupedItems = {};
  lineItems.forEach((item) => {
    const status = item.internal_status;
    const fulfillmentOrderId = item.fulfillment_order.id;

    if (!groupedItems[status]) {
      groupedItems[status] = {};
    }
    if (!groupedItems[status][fulfillmentOrderId]) {
      groupedItems[status][fulfillmentOrderId] = [];
    }
    groupedItems[status][fulfillmentOrderId].push(item);
  });

  return groupedItems;
};

const OrderForm = (
  { orderId, onFormSubmit, handleSaveField, riskData },
  ref
) => {
  const { fields } = useFormContext();
  const [groupedLineItems, setGroupedLineItems] = useState({});
  const [updatedData, setUpdatedData] = useState(fields);
  const isMobile = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    if (fields?.line_items) {
      setGroupedLineItems(groupLineItems(fields.line_items));
    }
    setUpdatedData(fields);
  }, [fields]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      onFormSubmit(fields);
    },
  }));

  return (
    <form
      ref={ref}
      className="grid grid-cols-1 md:flex md:flex-1 md:gap-x-4 mt-6"
    >
      <div className="w-full md:w-3/4">
        {updatedData?.internal_status !== 'Shipped' && (
          <LineItems
            lineItems={groupedLineItems}
            allItems={fields?.line_items}
            handleSaveField={handleSaveField}
            orderId={orderId}
          />
        )}
        {updatedData?.fulfillments?.length > 0 && (
          <div className="mt-4">
            <ContentHeader>Shipments</ContentHeader>
            <div className="mt-4">
              <ItemFulfillments
                fulfillments={updatedData?.fulfillments}
                allItems={fields?.line_items}
                handleSaveField={handleSaveField}
                orderId={orderId}
              />
            </div>
          </div>
        )}
        <div className="mt-4">
          <PaymentCardContent order={updatedData} />
        </div>

        {!isMobile && (
          <div className="mt-3">
            <ContentHeader>Order Timeline</ContentHeader>
            <ActivityLog
              referenceId={orderId}
              referenceType="Order"
              allowComments={true}
              className="mt-2"
            />
          </div>
        )}
      </div>
      <aside className="w-full mt-4 md:mt-0 md:w-1/4">
        <Note
          note={updatedData?.note}
          noteAttributes={updatedData?.note_attributes}
          handleSaveField={handleSaveField}
        />
        <Customer
          contactEmail={updatedData?.email}
          customer={updatedData?.customer}
          shippingAddress={updatedData?.shipping_address}
          handleSaveField={handleSaveField}
          className="mt-4"
        />
        <Tags
          tags={updatedData?.tags}
          handleSaveField={handleSaveField}
          className="mt-4"
        />
        <OrderIntegrations
          order={updatedData}
          riskData={riskData}
          className="mt-4"
        />
      </aside>
      {isMobile && (
        <div className="mt-3 w-full">
          <ContentHeader>Order Timeline</ContentHeader>
          <ActivityLog
            referenceId={orderId}
            referenceType="Order"
            allowComments={true}
            className="mt-2"
          />
        </div>
      )}
    </form>
  );
};

export default forwardRef(OrderForm);
