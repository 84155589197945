import React, { useState } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Cookies from 'js-cookie';

import Button from '../Button';
import UserIcon from '../UserIcon';
import BtnLoadingSpinner from '../BtnLoadingSpinner';

import { createComment } from '../../../../utils/Api/Activity/Create';
import { onUploadImage } from '../../../../utils/Api/Content/Upload';

const CommentForm = ({ referenceId, referenceType, onCommentSubmit }) => {
  const [comment, setComment] = useState('');
  const [commentPosting, setCommentPosting] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); // State for image previews
  const userCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_NAME}`);
  const user = userCookie ? JSON.parse(decodeURIComponent(userCookie)) : null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCommentPosting(true);

    if (!comment.trim() && selectedImages.length === 0) {
      setCommentPosting(false);
      return;
    }

    const imageUrls = [];
    for (const image of selectedImages) {
      try {
        const uploadResponse = await onUploadImage(image);
        if (uploadResponse.success) {
          imageUrls.push(uploadResponse.data.cloudUrl);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setCommentPosting(false);
        return;
      }
    }

    const commentData = {
      created_at: Date.now(),
      user: {
        id: user ? user.uid : '',
        name: user ? `${user.userName} ${user.userLastName}` : '',
        image: user ? user.image : '',
      },
      referenceId,
      referenceType,
      action: 'commented',
      actionDetail: 'Added a comment.',
      comment,
      imageUrls, // Add imageUrls array to comment data
    };

    try {
      const response = await createComment(commentData);
      setComment(''); // Reset comment input after successful submission
      setSelectedImages([]); // Reset selected images
      setImagePreviews([]); // Reset image previews
      onCommentSubmit(commentData); // Notify parent component about the new comment
    } catch (error) {
      console.error('Error creating comment:', error);
    } finally {
      setCommentPosting(false);
    }
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedImages.length > 5) {
      alert('You can upload up to 5 images.');
      return;
    }

    setSelectedImages((prevImages) => [...prevImages, ...files]);

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const removeImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="flex gap-x-3">
      <UserIcon user={user} />
      <div className="relative flex-auto">
        <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="comment" className="sr-only">
            Leave a comment...
          </label>
          <textarea
            rows={2}
            name="comment"
            id="comment"
            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-[.8125rem] sm:leading-5"
            placeholder="Leave a comment..."
            value={comment}
            onChange={handleChange}
            disabled={commentPosting}
          />
        </div>

        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
          <div className="flex items-center space-x-5">
            <div className="flex items-center">
              <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={handleImageChange}
                multiple
              />
              <label
                htmlFor="file-upload"
                className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Attach a file</span>
              </label>
            </div>
            {imagePreviews.map((previewUrl, index) => (
              <div key={index} className="relative">
                <img
                  src={previewUrl}
                  alt={`Preview ${index}`}
                  className="h-10 w-10 rounded-lg object-cover ring-1 ring-gray-900/30"
                />
                <span
                  className="absolute -top-1 -right-1 text-white bg-gray-900 rounded-full cursor-pointer"
                  onClick={() => removeImage(index)}
                >
                  <XMarkIcon className="w-auto h-4 p-0.5" />
                </span>
              </div>
            ))}
          </div>
          <Button
            type="button"
            className="rounded-md px-2.5 py-1.5 text-xs font-semibold ring-1 ring-gray-900/20 !bg-gray-200/50 !text-gray-900 hover:!bg-gray-300"
            onClick={handleSubmit}
          >
            {commentPosting && (
              <BtnLoadingSpinner className="w-4 h-4 mr-2 text-gray-900" />
            )}{' '}
            Comment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommentForm;
