import React, { useState } from 'react';

const Popover = ({ content, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!content) return null;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative"
    >
      {children}

      {isHovered && (
        <div className="absolute shadow-lg z-10 max-w-56 w-56 min-w-xs p-2 text-[.8125rem] font-normal text-gray-900 bg-white ring-1 ring-gray-900/20 rounded-lg -left-3 top-6 popover-tail">
          <p className="text-wrap">{content}</p>
        </div>
      )}
    </div>
  );
};

export default Popover;
