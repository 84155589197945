import { ApiRequest } from '../BaseApi';

export const getProducts = async (page = 1, limit = 20, searchQuery = '') => {
  return ApiRequest({
    method: 'get',
    endpoint: '/products',
    params: { page, limit, searchQuery },
  });
};

export const getProduct = async (id) => {
  return ApiRequest({
    method: 'get',
    endpoint: `/products/${id}`,
  });
};

// Grab product images
export const getVariantImages = async () => {
  return ApiRequest({
    method: 'get',
    endpoint: '/products/images',
  });
};
