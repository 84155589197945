import Cookies from 'js-cookie';

import { createComment } from './Api/Activity/Create';

const ActivityLogger = async (params) => {
  const { referenceId, referenceType, action, details } = params;
  const userCookie = Cookies.get(`${process.env.REACT_APP_COOKIE_NAME}`);
  const user = userCookie ? JSON.parse(decodeURIComponent(userCookie)) : null;

  const activityData = {
    user: {
      id: user ? user.uid : '',
      name: user ? user.userName + ' ' + user.userLastName : '',
      image: user ? user.image : '',
    },
    referenceId,
    referenceType,
    action,
    actionDetail: details,
    comment: '', // This field seems unused for non-comment actions, consider removing if unnecessary
  };

  try {
    const response = await createComment(activityData);
    return response;
  } catch (error) {
    console.error('Failed to log activity:', error);
  }
};

export const handleUpdateActivity = async (
  referenceType,
  referenceAction,
  referenceDetails,
  referenceId
) => {
  try {
    const logger = await ActivityLogger({
      referenceId: referenceId,
      referenceType: `${referenceType}`,
      action: referenceAction,
      details: referenceDetails,
    });
    return logger;
  } catch (error) {
    console.error(`Failed to update ${referenceType} activity log:`, error);
  }
};
