import { useState, useRef } from 'react';

import { onUploadImage } from '../../../../utils/Api/Content/Upload';

import BtnLoadingSpinner from '../../../../shared/components/UIElements/BtnLoadingSpinner';
import { updateProduct } from '../../../../utils/Api/Products/Update';

const VariantEditImage = ({
  fields,
  notify,
  onImageChange,
  product,
  setProduct,
}) => {
  const fileInputRef = useRef(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [variantImage, setVariantImage] = useState(fields.image_id || null);

  const handleUpdateProduct = async (updateProductData) => {
    try {
      const updatedProduct = await updateProduct(
        updateProductData,
        updateProductData._id
      );
      console.log('UPDATE RESPONSE:', updatedProduct); // Add this line
      if (updatedProduct.success) {
        const lastImageId =
          updatedProduct.data.updatedProduct.images[
            updatedProduct.data.updatedProduct.images.length - 1
          ]._id;
        console.log('LAST IMAGE ID:', lastImageId); // Add this line
        setProduct(updatedProduct.data.updatedProduct);
        return { status: 'success', image_id: lastImageId };
      }
      return { status: 'error', message: updatedProduct.data.message };
    } catch (error) {
      notify('Error', 'Failed to upload image to product', 'error');
      console.error('ERROR IN HANDLE UPDATE PRODUCT:', error); // Add this line
      return { status: 'error', message: error };
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setImageUploading(true);
    try {
      const response = await onUploadImage(file);
      console.log('IMAGE UPLOAD', response.success);

      if (response.success) {
        const productImageData = {
          id: null,
          position: product.images.length + 1,
          src: response.data.cloudUrl,
          cloudId: response.data.cloudId,
          cloudUrl: response.data.cloudUrl,
          alt: null,
          variant_ids: [fields._id],
          internal_data: {
            variant_ids: [fields._id],
          },
        };

        const updateProductData = {
          ...product,
          images: [...product.images, productImageData],
        };
        const productUpdateResponse =
          await handleUpdateProduct(updateProductData);
        console.log(productUpdateResponse);
        if (productUpdateResponse.status === 'success') {
          const updatedVariantImage = {
            target: {
              name: `image_id`,
              value: productUpdateResponse.image_id, // The new image ID
            },
          };
          console.log('UPDATED IMAGE', updatedVariantImage);
          onImageChange(updatedVariantImage); // Pass the entire variant data
          setVariantImage(productUpdateResponse.image_id);
          notify('Success', 'Image uploaded successfully', 'success');
        }
      } else {
        notify('Error', response.message, 'error');
      }
    } catch (error) {
      console.log('[ERROR]', error);
      notify('Error', 'Failed to upload image', 'error');
    } finally {
      setImageUploading(false);
    }
  };

  return (
    <>
      <span
        className={`h-24 w-24 overflow-hidden ring-2 rounded-lg bg-gray-100 ring-gray-200 ${imageUploading ? 'flex justify-center items-center' : 'inline-block'}`}
      >
        {imageUploading ? (
          <BtnLoadingSpinner className="w-10 h-10 text-indigo-800" />
        ) : variantImage ? (
          <img
            src={(() => {
              const image = product.images.find(
                (img) =>
                  String(img._id) === String(variantImage) ||
                  String(img.id) === String(variantImage)
              );
              return image ? image.cloudUrl || image.src : undefined;
            })()}
            alt="Product"
            className="h-full w-full object-cover"
          />
        ) : (
          <svg
            className="h-24 w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        )}
      </span>
      <input
        type="file"
        accept="image/*"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <button
        type="button"
        className="ml-5 rounded-md bg-white px-2.5 py-1.5 text-[.8125rem] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={() => fileInputRef.current.click()}
      >
        Change
      </button>
    </>
  );
};

export default VariantEditImage;
