import React, { Fragment, useEffect, useState, useMemo } from 'react';
import ItemTitle from './LineItem/ItemTitle';
import Checkbox from '../../../../../shared/components/UIElements/Checkbox';
import LineItemQuantityInput from './Shared/LineItemQuantityInput';
import { TrashIcon } from '@heroicons/react/24/outline';
import AddItemProperty from './LineItem/EditItem/AddItemProperty';
import ItemActionReason from './LineItem/ItemActionReason';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

import ItemProperties from './LineItem/ItemPropertyList';
import ItemDataGrid from './LineItem/ItemDataGrid';
import ImageThumbnail from '../../../../../shared/components/UIElements/ImageThumbnail';

const calculateDiscountedPrice = (lineItem) => {
  const price = lineItem?.price ?? 0;
  let total = parseFloat(price);

  if (
    lineItem?.discount_allocations &&
    lineItem?.discount_allocations.length > 0 &&
    lineItem?.quantity > 0
  ) {
    lineItem?.discount_allocations.forEach((discount) => {
      const discountAmount = parseFloat(discount?.amount) ?? 0;
      total -= discountAmount / lineItem?.quantity;
    });
  }

  if (!isNaN(total)) {
    return total.toFixed(2);
  } else {
    return '0.00';
  }
};

const LineItem = ({
  lineItem,
  itemsEditing,
  handleCheckboxChange,
  selectedItems = {},
  onQuantityChange,
  selectedOption,
  setSelectedItems,
}) => {
  const itemUrl = useMemo(
    () => (lineItem?.sku ? lineItem?.sku : null),
    [lineItem?.sku]
  );
  const [itemsSelected, setItemsSelected] = useState(selectedItems);
  const [holdData, setHoldData] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    setItemsSelected(selectedItems);
    if (lineItem?.hold_data) {
      setHoldData(lineItem?.hold_data);
    }
  }, [selectedItems, lineItem]);

  const totalOrDiscountedPrice = calculateDiscountedPrice(lineItem);
  const isChecked = !!itemsSelected[lineItem?._id]?.selected;

  const handleLineItemClick = (e) => {
    if (
      !isInputFocused &&
      itemsEditing &&
      handleCheckboxChange &&
      e.target.type !== 'checkbox' &&
      selectedOption !== 'Edit items'
    ) {
      handleCheckboxChange(
        lineItem?._id,
        !isChecked,
        lineItem?.hold_data
          ? lineItem?.hold_data.quantity
          : lineItem?.fulfillable_quantity,
        lineItem.id
      );
    }
  };

  const handleRemoveItem = () => {
    setSelectedItems((prev) => ({
      ...prev,
      [lineItem?._id]: {
        ...prev[lineItem?._id],
        edits: {
          ...prev[lineItem?._id].edits,
          internal_status: 'Removed',
          removing: true,
        },
      },
    }));
  };

  const handleUndoRemoveItem = () => {
    setSelectedItems((prev) => ({
      ...prev,
      [lineItem?._id]: {
        ...prev[lineItem?._id],
        edits: {
          ...prev[lineItem?._id].edits,
          internal_status: 'Received',
          removing: false,
        },
      },
    }));
  };

  return (
    <div
      className={`flex flex-col text-[.8125rem] ${selectedItems[lineItem?._id]?.edits?.removing ? 'bg-gray-200 cursor-not-allowed' : ''}`}
      onClick={handleLineItemClick}
    >
      <div className="flex p-4 gap-4 text-[.8125rem] relative">
        {itemsEditing && (
          <div className={`${selectedOption === 'Edit items' && 'hidden'}`}>
            <Checkbox
              checked={isChecked}
              onChange={() =>
                handleCheckboxChange(
                  lineItem?._id,
                  !isChecked,
                  lineItem?.fulfillable_quantity
                )
              }
              id={lineItem?._id}
              value={lineItem?._id}
            />
          </div>
        )}
        <div className={`flex flex-col items-center gap-y-2`}>
          {selectedOption === 'Edit items' &&
          !selectedItems[lineItem?._id]?.edits?.removing ? (
            <div
              className={`flex justify-center sm:hidden p-2 w-full rounded-lg bg-red-500/20 ring-red-500/40 ring-1 hover:bg-red-500/30 hover:text-red-900 cursor-pointer ${selectedItems[lineItem?._id]?.edits?.removing ? 'opacity-50' : ''}`}
              onClick={handleRemoveItem}
            >
              <TrashIcon className="w-auto h-3.5 text-red-600" />
            </div>
          ) : (
            <Fragment>
              {selectedItems[lineItem?._id]?.edits?.removing && (
                <div
                  className="block sm:hidden p-2 bg-indigo-900 text-white rounded-lg cursor-pointer w-full"
                  onClick={handleUndoRemoveItem}
                >
                  <ArrowUturnLeftIcon className="w-auto h-3.5 m-auto" />
                </div>
              )}
            </Fragment>
          )}
          <ImageThumbnail image={lineItem.image_url} alt={lineItem?.title} />
        </div>
        <div className="flex-1 flex flex-col gap-y-1">
          <div className="flex flex-col sm:grid sm:grid-cols-12">
            <div
              className={`${itemsEditing ? 'sm:col-span-11' : 'sm:col-span-6'} ${selectedItems[lineItem?._id]?.edits?.removing ? 'opacity-50' : ''}`}
            >
              <ItemTitle
                title={lineItem?.title}
                url={
                  selectedItems[lineItem?._id]?.edits?.removing ? null : itemUrl
                }
              />
            </div>
            {!itemsEditing ? (
              <div className="flex justify- sm:justify-between items-center sm:col-span-6">
                <div className="flex-1">
                  <div className="text-left sm:text-right">
                    {lineItem?.discount_allocations?.length === 0 ? (
                      '$' + lineItem?.price
                    ) : (
                      <Fragment>
                        <span className="line-through text-gray-500">
                          ${lineItem?.price}
                        </span>
                        <span className="ml-2">${totalOrDiscountedPrice}</span>
                      </Fragment>
                    )}
                    <span className="px-1 xl:px-2">x</span>
                    {lineItem?.fulfillment_status === 'fulfilled' &&
                    lineItem?.fulfillable_quantity === 0
                      ? lineItem?.quantity
                      : lineItem?.hold_data?.quantity ||
                        lineItem?.fulfillable_quantity ||
                        0}
                  </div>
                </div>
                <div className="ml-6">
                  $
                  {(
                    totalOrDiscountedPrice *
                    (lineItem?.fulfillment_status === 'fulfilled' &&
                    lineItem?.fulfillable_quantity === 0
                      ? lineItem?.quantity
                      : lineItem?.hold_data?.quantity ||
                        lineItem?.fulfillable_quantity ||
                        0)
                  ).toFixed(2)}
                </div>
              </div>
            ) : (
              <Fragment>
                {!isChecked ? (
                  <div className="flex justify- sm:justify-end items-center sm:col-span-1">
                    x{' '}
                    {lineItem?.hold_data?.quantity ||
                      lineItem?.fulfillable_quantity ||
                      0}
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="sm:col-span-1 flex sm:justify-end flex-row gap-x-2">
                      <LineItemQuantityInput
                        itemId={lineItem?._id}
                        quantity={lineItem?.quantity}
                        fulfillableQuantity={lineItem?.fulfillable_quantity}
                        onQuantityChange={onQuantityChange}
                        selectedOption={selectedOption}
                        disabled={
                          selectedItems[lineItem?._id]?.edits?.removing ||
                          lineItem?.hold_data
                        }
                        hold_data={lineItem.hold_data}
                        setIsInputFocused={setIsInputFocused}
                      />
                      {selectedOption === 'Edit items' &&
                      !selectedItems[lineItem?._id]?.edits?.removing ? (
                        <div
                          className="hidden sm:block p-2 rounded-lg hover:bg-red-500/20 hover:text-red-900 cursor-pointer"
                          onClick={handleRemoveItem}
                        >
                          <TrashIcon className="w-auto h-3.5 text-red-600" />
                        </div>
                      ) : (
                        <Fragment>
                          {selectedItems[lineItem?._id]?.edits?.removing && (
                            <div
                              className="hidden sm:block p-2 bg-indigo-900 text-white rounded-lg cursor-pointer"
                              onClick={handleUndoRemoveItem}
                            >
                              <ArrowUturnLeftIcon className="w-auto h-3.5" />
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div
            className={`${selectedItems[lineItem?._id]?.edits?.removing ? 'opacity-50' : ''}`}
          >
            {lineItem?.variant_title && (
              <div className="text-[.8125rem] rounded-md bg-gray-200 py-0.5 px-2 max-w-fit mt-1">
                {lineItem?.variant_title}
              </div>
            )}
            {lineItem?.sku ? (
              <div className="text-[.8125rem] text-gray-600 mt-1">
                SKU: {lineItem?.sku}
              </div>
            ) : (
              <div className="text-[.8125rem] italic text-gray-400 mt-1">
                No product information provided
              </div>
            )}
            <ItemProperties
              itemProperties={lineItem?.properties}
              lineItem={lineItem}
              selectedOption={selectedOption}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
            <div className="text-[.8125]">{lineItem._id}</div>
          </div>
        </div>
      </div>
      {lineItem.fulfillment_order && (
        <ItemDataGrid fulfillmentData={lineItem.fulfillment_order} />
      )}
      {holdData && (
        <ItemActionReason reason={holdData.hold_reason} title="Hold reason" />
      )}
    </div>
  );
};

export default LineItem;
