import React, { useEffect, useState } from 'react';

import Input from '../../../../../../shared/components/UIElements/Inputs/Input';

const EditContact = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="mt-2 text-[.8125] leading-5">
      <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder="customer@email.com"
        className="w-full rounded-lg ring-1 ring-gray-900/20 border-0 leading-5 text-[.8125rem]"
        type="email"
      />
    </div>
  );
};

export default EditContact;
