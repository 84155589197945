import {
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

const alertTypes = {
  error: {
    classes: {
      background: 'bg-red-50',
      header: 'text-red-800',
      content: 'text-red-700',
    },
    icon: <XCircleIcon className="h-5 w-5 text-red-400" />,
  },
  success: {
    classes: {
      background: 'bg-green-50',
      header: 'text-green-800',
      content: 'text-green-700',
    },
    icon: <CheckCircleIcon className="h-5 w-5 text-green-400" />,
  },
  warn: {
    classes: {
      background: 'bg-yellow-50',
      header: 'text-yellow-800',
      content: 'text-yellow-700',
    },
    icon: <InformationCircleIcon className="h-5 w-5 text-yellow-400" />,
  },
  info: {
    classes: {
      background: 'bg-indigo-50',
      header: 'text-indigo-800',
      content: 'text-indigo-700',
    },
    icon: <ExclamationTriangleIcon className="h-5 w-5 text-indigo-400" />,
  },
};

const InfoAlert = ({ type, header, children }) => {
  return (
    <div
      className={`rounded-md ${children ? 'p-4' : 'p-2 mt-2'} ${alertTypes[type].classes.background}`}
    >
      <div className="flex">
        <div className="flex-shrink-0">{alertTypes[type].icon}</div>
        <div className="ml-3">
          <h3
            className={`text-[.8125rem] font-medium ${alertTypes[type].classes.header}`}
          >
            {header}
          </h3>
          {children && (
            <div
              className={`mt-2 text-[.8125rem] ${alertTypes[type].classes.content}`}
            >
              {children}
              {/* <ul className="list-disc space-y-1 pl-5">
                <li>Your password must be at least 8 characters</li>
                <li>Your password must include at least one pro wrestling finishing move</li>
              </ul> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoAlert;
