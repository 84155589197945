const PaymentCardDiscounts = ({ discounts }) => {
  return (
    <>
      {/* Loop through the discount_codes array */}
      {discounts.length > 0 &&
        discounts.map((discount, index) => (
          <div
            className="px-2 py-2 grid grid-cols-6 sm:gap-4 sm:px-0"
            key={index}
          >
            <dt className="text-sm text-gray-900">Discount</dt>
            <dd className="text-sm leading-6 text-gray-700 mt-0 col-span-4">
              {discount.code}
            </dd>
            <dd className="text-sm leading-6 text-gray-700 mt-0 flex justify-end">
              -${parseFloat(discount.amount).toFixed(2)}
            </dd>
          </div>
        ))}
      {/* Discount loop end */}
    </>
  );
};

export default PaymentCardDiscounts;
