import { Fragment, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import Input from '../../../../../../../shared/components/UIElements/Inputs/Input';

const AddItemProperty = ({ propertyList, onPropertyChange }) => {
  const maxProperties = 5;

  const [newProperties, setNewProperties] = useState([]);

  const handleAddProperty = () => {
    if (propertyList.length + newProperties.length < maxProperties) {
      const newProps = [...newProperties, { name: '', value: '' }];
      setNewProperties(newProps);
      onPropertyChange(newProps);
    }
  };

  const handleRemoveProperty = (index) => {
    const removeProp = newProperties.filter((_, i) => i !== index);
    setNewProperties(removeProp);
    onPropertyChange(removeProp);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedProperties = [...newProperties];
    updatedProperties[index][name] = value;
    setNewProperties(updatedProperties);
    onPropertyChange(updatedProperties);
  };

  return (
    <div className="flex flex-col gap-x-4 w-full">
      {newProperties.map((property, index) => (
        <div key={index} className="flex flex-row gap-x-2 items-center mb-2">
          <Input
            className="w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 h-8"
            label="Property name"
            labelsr="true"
            name="name"
            value={property.name}
            onChange={(event) => handleChange(index, event)}
            placeholder="name"
          />
          <div>:</div>
          <Input
            className="w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 h-8"
            label="Property value"
            labelsr="true"
            name="value"
            value={property.value}
            onChange={(event) => handleChange(index, event)}
            placeholder="value"
          />
          <div className="w-auto h-4 sm:h-4">
            <TrashIcon
              className="text-gray-600 h-4 sm:h-4 hover:text-gray-900 cursor-pointer"
              onClick={() => handleRemoveProperty(index)}
            />
          </div>
        </div>
      ))}
      <div
        className={`mt-2 flex flex-row gap-x-1 items-center w-full text-indigo-500`}
      >
        {propertyList.length + newProperties.length >= maxProperties ? (
          ''
        ) : (
          <Fragment>
            <div
              className="flex flex-row gap-x-1 cursor-pointer hover:underline hover:underline-offset-1 items-center"
              onClick={handleAddProperty}
            >
              <PlusCircleIcon className="h-5 w-auto" />
              <p>Add a new property</p>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AddItemProperty;
