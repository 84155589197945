const Card = ({ children, ...props }) => {
  return (
    <div className={`shadow rounded-xl ${props.className}`}>
      <div className="space-y-6 rounded-2xl bg-white p-4">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
