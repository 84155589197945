const ContentHeader = ({ children, props }) => {
  return (
    <div
      className={`pt-1.5 px-4 text-md font-bold text-gray-500 ${props?.className}`}
    >
      {children}
    </div>
  );
};

export default ContentHeader;
