import { Fragment } from 'react';
import SettingsNavigation from '../components/SettingsNavigation';

const Settings = () => {
  return (
    <Fragment>
      <h1 className="sr-only">Account Settings</h1>

      <SettingsNavigation />
    </Fragment>
  );
};

export default Settings;
