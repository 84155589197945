import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getUsers } from '../../../utils/Api/Users/Get';

import UserList from '../components/UserList';

import PageHeader from '../../../shared/components/UIElements/PageHeader';
import Container from '../../../shared/components/UIElements/Container';
import PageLoadingSpinner from '../../../shared/components/UIElements/PageLoadingSpinner';
import Button from '../../../shared/components/UIElements/Button';
import Placeholder from '../../../shared/components/UIElements/EmptyPlaceholder/Placeholder';
import TablePagination from '../../../shared/components/UIElements/Pagination/TablePagination';

const Users = () => {
  const [items, setItems] = useState(null);
  const [limit, setLimit] = useState(20); // If you want to allow changing the number of items per page
  const [totalPages, setTotalPages] = useState(0); // Add state for total pages
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Add currentPage state
  const [loading, setLoading] = useState(false);

  // const [committed, setCommitted] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUsers(currentPage, limit);
        setItems(data.data.items);
        setTotalItems(data.data.totalItems);
        const calculatedTotalPages = Math.ceil(data.data.totalItems / limit);
        setTotalPages(calculatedTotalPages);
      } catch (error) {
        console.error('Failed to fetch items:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, limit]);

  const handlePageChange = (newPage) => {
    setLoading(true); // Optionally set loading here or rely on useEffect
    setCurrentPage(newPage);
  };

  return !items ? (
    <PageLoadingSpinner className="w-10 h-10 text-indigo-700" />
  ) : items.length === 0 ? (
    <Fragment>
      <Container>
        <PageHeader pageTitle="Users" />
      </Container>
      <Container>
        <Placeholder
          title="No products found"
          message="Get started by creating a product."
          buttonText="New User"
          to="/users/new"
        />
      </Container>
    </Fragment>
  ) : (
    <Container>
      <PageHeader pageTitle="Users">
        <Link to="/users/create">
          <Button>New User</Button>
        </Link>
      </PageHeader>
      <div className="mt-4 flow-root">
        <UserList items={items} />
        <div>
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            limit={limit}
            onPageChange={handlePageChange}
            loading={loading}
            className="border-t-0"
          />
        </div>
      </div>
    </Container>
  );
};

export default Users;
