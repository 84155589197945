import React from 'react';

const Container = ({ children, className }) => {
  return (
    <div className={`px-4 py-5 sm:px-6 lg:px-6 lg:py-6 ${className}`}>
      {children}
    </div>
  );
};

export default Container;
