import Button from '../Button';
import BtnLoadingSpinner from '../BtnLoadingSpinner';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';

const CardPagination = ({
  totalPages,
  currentPage,
  onPageChange,
  loading,
  className,
}) => {
  const nextPage = () => {
    onPageChange((current) => Math.min(current + 1, totalPages)); // Use passed callback
  };

  const prevPage = () => {
    onPageChange((current) => Math.max(current - 1, 1)); // Use passed callback
  };

  return (
    <nav
      className={`mt-4 flex items-center justify-between ${className}`}
      aria-label="Pagination"
    >
      <div className="flex flex-1 justify-center items-center sm:gap-x-0 gap-x-8">
        {loading && (
          <BtnLoadingSpinner className="w-4 h-4 mr-3 text-indigo-700" />
        )}
        <Button
          onClick={prevPage}
          className={`relative inline-flex items-center !px-1 !py-1 ${currentPage <= 1 ? '!bg-gray-300 cursor-not-allowed' : '!bg-gray-700 hover:!bg-gray-500'}`}
          disabled={currentPage <= 1}
        >
          <ChevronLeftIcon className="w-6 h-6 text-white" />
        </Button>
        <Button
          onClick={nextPage}
          className={`relative ml-3 inline-flex !px-1 !py-1 ${currentPage >= totalPages ? '!bg-gray-300 cursor-not-allowed' : '!bg-gray-700 hover:!bg-gray-500'}`}
          disabled={currentPage >= totalPages}
        >
          <ChevronRightIcon className="w-6 h-6 text-white" />
        </Button>
      </div>
    </nav>
  );
};

export default CardPagination;
