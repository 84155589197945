import ContentAlert from '../../../../../../shared/components/UIElements/Notifications/ContentAlert/ContentAlert';
import TextArea from '../../../../../../shared/components/UIElements/TextArea/TextArea';

const alert = {
  message:
    'To comment on an order or mention a staff member, use Timeline instead.',
  type: 'info',
};

const EditNote = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="mt-2">
      <TextArea
        value={value}
        onChange={handleChange}
        placeholder="Edit the note here..."
        className="w-full"
        rows="8"
      />
      {/* <div className="pointer-events-none inset-x-0 bottom-0 sm:flex sm:justify-center mt-2 w-full">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5 w-full">
          <p className="text-[.8125rem] leading-5 text-gray-900 w-full">
            To comment on an order or mention a staff member, use Timeline instead.
          </p>
        </div>
      </div> */}
      <ContentAlert contentAlert={alert} />
    </div>
  );
};

export default EditNote;
