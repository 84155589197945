import React from 'react';
import { Dialog } from '@headlessui/react';
import DialogClose from './DialogClose';

const DialogHeader = ({ title, handleClose }) => {
  return (
    <div className="flex items-center py-3 bg-gray-100 px-4">
      <Dialog.Title
        as="h3"
        className="text-[.875rem] font-semibold text-gray-900 flex-1"
      >
        {title}
      </Dialog.Title>
      <DialogClose setOpen={handleClose} />
    </div>
  );
};

export default DialogHeader;
