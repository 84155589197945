import React from 'react';

import SelectTable from '../../../../shared/components/UIElements/Tables/SelectTable';
import ProductItem from './ProductItem';

const ProductList = ({ items, committed, handleSearch }) => {
  const tableHeaders = () => (
    <>
      <th
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-[.8125rem] font-semibold text-gray-900 sm:pl-0"
      >
        Product
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 lg:table-cell"
      >
        Status
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:tabel-cell"
      >
        Inventory
      </th>
      <th
        scope="col"
        className="hidden px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900 sm:table-cell"
      >
        Tags
      </th>
      <th
        scope="col"
        className="px-3 py-3.5 text-left text-[.8125rem] font-semibold text-gray-900"
      >
        Category
      </th>
      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
        <span className="sr-only">Edit</span>
      </th>
    </>
  );

  return (
    <SelectTable
      items={items}
      tableHeaders={tableHeaders}
      RowComponent={ProductItem}
      handleSearch={handleSearch}
    />
  );
};

export default ProductList;
