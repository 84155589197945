import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const ItemTitle = ({ title, url = null }) => {
  return (
    <Fragment>
      {url ? (
        <Link to="#">
          <span className="text-indigo-500">{title.split(' - ')[0]}</span>
        </Link>
      ) : (
        <span>{title.split(' - ')[0]}</span>
      )}
    </Fragment>
  );
};

export default ItemTitle;
