import { Link } from 'react-router-dom';

import EditShippingAddress from '../Dialogs/EditShippingAddress';

import EditIcon from '../../../../../../shared/components/UIElements/EditIcon';
import CopyIcon from '../../../../../../shared/components/UIElements/CopyIcon';

import useEditableField from '../../../../../../shared/hooks/dialog-form-hook';

const formatShippingAddress = (address) => {
  if (!address) return '';

  // Build address parts, excluding any undefined or empty fields
  const addressParts = [
    address.address1,
    address.address2,
    `${address.city}${address.province_code ? ', ' + address.province_code : ''} ${address.zip}`,
    address.country,
  ].filter((part) => part && part.trim() !== ''); // Remove empty parts

  return addressParts.join(', '); // Join parts with a comma separator
};

const CustomerShipping = ({ shippingAddress, handleSaveField }) => {
  const { editField, handleEditField, currentValue } = useEditableField(
    EditShippingAddress,
    {
      fieldName: 'shipping_address',
      value: shippingAddress,
      dialogTitle: 'Shipping Address',
    },
    handleSaveField
  );
  const shippingLocation = formatShippingAddress(currentValue);

  return (
    <div className="mt-4 w-full text-[.8125rem]">
      <div className="flex items-center space-between">
        <div className="flex-1 text-[.8125rem] font-semibold text-gray-900">
          Shipping Address
        </div>
        <div>
          <EditIcon onClick={handleEditField} active={editField} />
        </div>
      </div>
      <div className="flex space-between mt-2">
        <div className="flex-1 mr-2 break-word">
          <p>{currentValue?.name}</p>
          <p>{currentValue?.company}</p>
          <p>{currentValue?.address1}</p>
          <p>{currentValue?.address2}</p>
          <p>
            {currentValue?.city} {currentValue?.province_code}{' '}
            {currentValue?.zip}
          </p>
          <p>{currentValue?.country}</p>
          <p>{currentValue?.phone}</p>
        </div>
        <span className="relative top-0">
          <CopyIcon data={shippingLocation} />
        </span>
      </div>
      <div className="mt-2">
        <Link
          to={`https://maps.google.com/?q=${currentValue.latitude},${currentValue.longitude}`}
          target="_blank"
        >
          <span className="text-indigo-500">View map</span>
        </Link>
      </div>
    </div>
  );
};

export default CustomerShipping;
