import Badge from '../../../../../shared/components/UIElements/Badge';
import Card from '../../../../../shared/components/UIElements/Card';
import Dropdown from '../../../../../shared/components/UIElements/Dropdown';

const VariantOptions = ({ fields, product, handleChange }) => {
  return (
    <Card className="mt-4">
      <div className="space-y-6 px-4 py-6 sm:p-6">
        <div className="flex flex-col items-start">
          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-3">
            <h3 className="text-base font-semibold leading-5 text-gray-900">
              Variant Options
            </h3>
            <Badge>
              <svg
                viewBox="0 0 6 6"
                aria-hidden="true"
                className={`h-1.5 w-1.5 fill-gray-500`}
              >
                <circle r={3} cx={3} cy={3} />
              </svg>
              <span>Disabled</span>
            </Badge>
          </div>
          <p className="mt-1 text-[.8125rem] text-gray-500">
            Select variant options.
          </p>
        </div>
        <div className="grid grid-cols-12 gap-6">
          {product?.options?.map((option, idx) => (
            <div key={idx} className="col-span-6 lg:col-span-3">
              <Dropdown
                key={idx}
                label={option.name}
                options={option.values}
                disabled={true}
                baseSelection={fields[`option${idx + 1}`]} // This line is changed
                onChange={(selectedValue) =>
                  handleChange({
                    target: {
                      name: `option${idx + 1}`,
                      value: selectedValue,
                    },
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default VariantOptions;
