import { useMemo } from 'react';

// Helper functions
const determineOrderStatus = (statuses, totalItems) => {
  if (statuses.shipped === totalItems) return 'Shipped';
  if (statuses.cancelled === totalItems) return 'Cancelled';
  if (statuses.onHold === totalItems) return 'On Hold';
  if (statuses.unshipped === totalItems) return 'Unshipped';
  if (statuses.shipped > 0 && statuses.unshipped > 0)
    return 'Partially Shipped';
  if (statuses.onHold > 0 && statuses.onHold < totalItems)
    return 'Partial Hold';
  if (statuses.shipped > 0 && statuses.onHold > 0)
    return 'Partially Shipped with Partial Hold';

  return 'Unshipped'; // Default fallback status
};

const useOrderData = (lineItems) => {
  return useMemo(() => {
    if (!Array.isArray(lineItems)) {
      return {
        itemCount: 0,
        itemsByFulfillmentOrder: {},
        orderStatus: 'Unshipped',
      };
    }

    let totalCount = 0;
    const itemsByFulfillmentOrder = {};
    const statuses = {
      unshipped: 0,
      shipped: 0,
      onHold: 0,
      cancelled: 0,
    };

    lineItems.forEach((item) => {
      if (item.internal_status !== 'Removed') {
        const itemQuantity = item.quantity || 0;
        totalCount += itemQuantity;

        const fulfillmentOrderId =
          item.fulfillment_order?.id || 'No Fulfillment Order';

        const itemData = {
          quantity: itemQuantity,
          status: item.internal_status,
          image: item.image_url,
          sku: item.sku,
          fulfillment: item.fulfillment_order || null,
          holdReason: item.hold_data?.hold_reason?.reason || null,
        };

        if (!itemsByFulfillmentOrder[fulfillmentOrderId]) {
          itemsByFulfillmentOrder[fulfillmentOrderId] = [];
        }

        itemsByFulfillmentOrder[fulfillmentOrderId].push(itemData);

        // Track statuses
        if (item.internal_status === 'Shipped') statuses.shipped++;
        else if (item.internal_status === 'On Hold') statuses.onHold++;
        else if (item.internal_status === 'Cancelled') statuses.cancelled++;
        else statuses.unshipped++;
      }
    });

    const orderStatus = determineOrderStatus(statuses, lineItems.length);

    return {
      itemCount: totalCount,
      itemsByFulfillmentOrder,
      orderStatus,
    };
  }, [lineItems]);
};

export default useOrderData;
